import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ToastService } from 'app/common/toast-alert/toast.service';
import { GlobalsService } from 'app/globals-services/globals.service';
import { Workbook } from 'exceljs';
import moment from 'moment';
import * as fs from 'file-saver';
import { CubejsService } from 'app/shared/cubejs/cubejs.service';
import { MessageDialogComponent } from 'app/shared/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { VocCalculationsService } from 'app/shared/services/voc-calculations.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationType } from 'app/globals-classes/OrganizationType';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-voc-calculator',
  templateUrl: './voc-calculator.component.html',
  styleUrls: ['./voc-calculator.component.scss']
})
export class VocCalculatorComponent implements OnInit {


  newVoc: boolean = false;
  homePage: boolean = true;
  alertMsgs = [];
  document: any;
  startDate = new FormControl(moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'));
  endDate = new FormControl(moment().endOf('month').format('YYYY-MM-DD'));
  startDateString: string;
  endDateString: string;
  loggedInOrgName: string;
  filters: Array<Object> = [];
  inventoryData: any;
  inventoryDataInScope: any;
  inventoryDataOutScope: any;
  cliIncheckData: any;
  isLoading = false;
  errorList = [];
  isCubejsConnected: boolean = true;
  monthsInRange = [];
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  startMonthName: string;
  endMonthName: string;
  startyear: any;
  endyear: any;
  startData: any;
  endData: any;
  VocExcelList: any;
  baseHref = environment.baseHref;
  uploadComplete: boolean = false;
  excelData: any;
  organization_id: any;
  excelFileName: string;
  totalVOCpte: number= 0;
  totalVOCconsumption: number= 0;
  totalConsumption: number= 0;
  totalVOCpteTon: number = 0;
  level: string;
  dateRange: string;
  percTotalVocConsump: number = 0;
  totalConsumptionTon: number = 0;
  totalVOCconsumptionTon: number = 0;
  vocCalculations = [];
  updatedBlob: any;
  displayedData= [];
  displayedColumns = [];
  isChecked: boolean = false;
  paginatedData: any[] = []; // Data for current page
  currentPage: number = 1;
  pageSize: number = 100;
  totalPages: number = 0;
  pages: number[] = [];
  path: string = '';
  viewResult: boolean = false;
  std: any;
  edd: any;
  supplierId: any;
  factoryFilter: any[];
  factoryControl = new FormControl();
  allFactories: any[];
  selectedfactory = [];
  allVocCalculations: any[] = [];
  @Input("supplierIdInput") supplierIdInput: any;


  selectedFactory:string;
  selectedTimeFrame: string;


  constructor(private http: HttpClient, private toastService: ToastService, private globalsService: GlobalsService, private cubejsService: CubejsService, public dialog: MatDialog, private vocService: VocCalculationsService, private translateService: TranslateService, private activatedRoute: ActivatedRoute,) { }

  get isBrand(): boolean { //is user role or organization type

    return this.globalsService.profile["organization_type"] == OrganizationType.BRAND
  }
  
  ngOnInit(): void {
    if (this.supplierIdInput)
      this.supplierId = this.supplierIdInput
    this.activatedRoute.queryParams.subscribe(
      (params) => {
        if (params['supplierId'] != null) {
          this.supplierId = params['supplierId']
        }
      }
    );
    this.isLoading = true;
    this.loggedInOrgName = this.globalsService['profile']['name'];
    this.organization_id = this.globalsService.profile['id'];
    this.fetchTranslations();
    this.getVocOverallTable();

  }

  onInputChange(event: any, type: string) {
    const searchInput = event.target.value.toLowerCase();
        if (searchInput == "") {
          this.factoryControl.setValue(this.selectedfactory, { emitEvent: false });
          this.factoryFilter = this.allFactories;
        }
        else {
          this.factoryFilter = this.allFactories.filter( name  => {
            const prov = name.toLowerCase();
            return prov.includes(searchInput);
          });
        }
    this.filterVocCalculations();
  }
  onOpenChange(searchInput: any, type: string) {
    searchInput.value = "";
        this.factoryFilter = this.allFactories;
    this.factoryControl.setValue(this.selectedfactory, { emitEvent: false });    
    this.filterVocCalculations();
  }
  selection(event, type: string) {
        if (event.isUserInput) {
          if (event.source._selected) {
            this.selectedfactory.push(event.source.value);
          }
          else {
            this.selectedfactory = this.selectedfactory.filter(el => { return el != event.source.value })
          }
        }
    this.filterVocCalculations();
  }
  filterVocCalculations() {
    this.vocCalculations = [...this.allVocCalculations]
    if (this.selectedfactory.length > 0) {
      this.vocCalculations = this.vocCalculations.filter(item =>
        this.selectedfactory.includes(item.factory_name)
      );
    } else {
      this.vocCalculations = [...this.allVocCalculations]; // Reset to all records if no selection
    }
  }
  

  fetchTranslations() {
    this.translateService
      .get('VOC')
      .subscribe((response) => {
        this.alertMsgs = response;
      });
  }

  newCalculation(voc: boolean) {
    this.displayedColumns = [];
    this.displayedData = [];
    this.newVoc = voc;
    this.homePage = !voc;
    this.uploadComplete = false;
    this.isChecked = false;
    this.viewResult = false;
    this.totalVOCpte = 0;
    this.totalVOCconsumption = 0;
    this.totalConsumption = 0;
    this.totalVOCpteTon = 0;
    this.percTotalVocConsump = 0;
    this.totalConsumptionTon = 0;
    this.totalVOCconsumptionTon = 0;
    this.startDate = new FormControl(moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'));
    this.endDate = new FormControl(moment().endOf('month').format('YYYY-MM-DD'))
    this.std = new Date(this.startDate.value);
    this.edd = new Date(this.endDate.value);
    this.startMonthName = this.monthNames[this.std.getMonth()];
    this.endMonthName = this.monthNames[this.edd.getMonth()];
    this.endyear = this.edd.getFullYear();
    this.startyear = this.std.getFullYear();
    this.startData = this.std.getDate();
    this.endData = this.edd.getDate();
    this.document = null;
    this.fileName = "";
    
    
  }
  calculateVoc(voc: boolean) {
    this.vocService.getVocList().subscribe((res: any) => {
      this.VocExcelList = res.vocList;
      this.readExcelFile(this.excelData);
    })
    
  }

  async getChemicalInventory() {
    
    let query = {
      "dimensions": [
        "VocCalculations.chemical_supplier",
        "VocCalculations.chemical_product",
        "VocCalculations.casnr",
        "VocCalculations.substance_name",
        "VocCalculations.percentage",
        "VocCalculations.consumption",
        "VocCalculations.januaryCIL",
        "VocCalculations.februaryCIL",
        "VocCalculations.marchCIL",
        "VocCalculations.aprilCIL",
        "VocCalculations.juneCIL",
        "VocCalculations.julyCIL",
        "VocCalculations.augustCIL",
        "VocCalculations.septemberCIL",
        "VocCalculations.octoberCIL",
        "VocCalculations.novemberCIL",
        "VocCalculations.decemberCIL",
        "VocCalculations.januaryInCheck",
        "VocCalculations.februaryInCheck",
        "VocCalculations.marchInCheck",
        "VocCalculations.aprilInCheck",
        "VocCalculations.mayInCheck",
        "VocCalculations.juneInCheck",
        "VocCalculations.julyInCheck",
        "VocCalculations.augustInCheck",
        "VocCalculations.septemberInCheck",
        "VocCalculations.octoberInCheck",
        "VocCalculations.novemberInCheck",
        "VocCalculations.decemberInCheck",
        "VocCalculations.inscope",
        "VocCalculations.factory_usage",
      ],
      "timeDimensions": [
      ],
      "order": [
      ],
      "filters": this.filters,
      "measures": [
      ]
    };
    try {
      const data = await this.cubejsService.getCubeJSData(query, null, null, true, false)
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0) {
          tempData = this.changeKey("VocCalculations.chemical_supplier", "chemical_supplier", tempData);
          tempData = this.changeKey("VocCalculations.chemical_product", "chemical_product", tempData);
          tempData = this.changeKey("VocCalculations.casnr", "casnr", tempData);
          tempData = this.changeKey("VocCalculations.substance_name", "substance_name", tempData);
          tempData = this.changeKey("VocCalculations.percentage", "percentage", tempData);
          tempData = this.changeKey("VocCalculations.consumption", "consumption", tempData);
          tempData = this.changeKey("VocCalculations.januaryCIL", "januaryCIL", tempData);
          tempData = this.changeKey("VocCalculations.februaryCIL", "februaryCIL", tempData);
          tempData = this.changeKey("VocCalculations.marchCIL", "marchCIL", tempData);
          tempData = this.changeKey("VocCalculations.aprilCIL", "aprilCIL", tempData);
          tempData = this.changeKey("VocCalculations.juneCIL", "juneCIL", tempData);
          tempData = this.changeKey("VocCalculations.julyCIL", "julyCIL", tempData);
          tempData = this.changeKey("VocCalculations.augustCIL", "augustCIL", tempData);
          tempData = this.changeKey("VocCalculations.septemberCIL", "septemberCIL", tempData);
          tempData = this.changeKey("VocCalculations.octoberCIL", "octoberCIL", tempData);
          tempData = this.changeKey("VocCalculations.novemberCIL", "novemberCIL", tempData);
          tempData = this.changeKey("VocCalculations.decemberCIL", "decemberCIL", tempData);
          tempData = this.changeKey("VocCalculations.januaryInCheck", "januaryInCheck", tempData);
          tempData = this.changeKey("VocCalculations.februaryInCheck", "februaryInCheck", tempData);
          tempData = this.changeKey("VocCalculations.marchInCheck", "marchInCheck", tempData);
          tempData = this.changeKey("VocCalculations.aprilInCheck", "aprilInCheck", tempData);
          tempData = this.changeKey("VocCalculations.mayInCheck", "mayInCheck", tempData);
          tempData = this.changeKey("VocCalculations.juneInCheck", "juneInCheck", tempData);
          tempData = this.changeKey("VocCalculations.julyInCheck", "julyInCheck", tempData);
          tempData = this.changeKey("VocCalculations.augustInCheck", "augustInCheck", tempData);
          tempData = this.changeKey("VocCalculations.septemberInCheck", "septemberInCheck", tempData);
          tempData = this.changeKey("VocCalculations.octoberInCheck", "octoberInCheck", tempData);
          tempData = this.changeKey("VocCalculations.novemberInCheck", "novemberInCheck", tempData);
          tempData = this.changeKey("VocCalculations.decemberInCheck", "decemberInCheck", tempData);
          tempData = this.changeKey("VocCalculations.inscope", "inscope", tempData);
          tempData = this.changeKey("VocCalculations.factory_usage", "factory_usage", tempData);
          
        }
      tempData = tempData.map((obj, index) => {
        return {
          //'no': String(index + 1), 
          'chemical_supplier': obj.chemical_supplier,
          'chemical_product': obj.chemical_product,
          'casnr': (obj.casnr != '' && obj.casnr != null) ? obj.casnr.replace(/['"]+/g, '') : obj.casnr,
          'substance_name': obj.substance_name,
          'percentage': obj.percentage,
          'consumption': obj.consumption != 'NA' ? Number(obj.consumption) : obj.consumption,
          'januaryCIL': obj.januaryCIL,
          'februaryCIL': obj.februaryCIL,
          'marchCIL': obj.marchCIL,
          'aprilCIL': obj.aprilCIL,
          'juneCIL': obj.juneCIL,
          'julyCIL': obj.julyCIL,
          'augustCIL': obj.augustCIL,
          'septemberCIL': obj.septemberCIL,
          'octoberCIL': obj.octoberCIL,
          'novemberCIL': obj.novemberCIL,
          'decemberCIL': obj.decemberCIL,
          'januaryInCheck': obj.januaryInCheck,
          'februaryInCheck': obj.februaryInCheck,
          'marchInCheck': obj.marchInCheck,
          'aprilInCheck': obj.aprilInCheck,
          'mayInCheck': obj.mayInCheck,
          'juneInCheck': obj.juneInCheck,
          'julyInCheck': obj.julyInCheck,
          'augustInCheck': obj.augustInCheck,
          'septemberInCheck': obj.septemberInCheck,
          'octoberInCheck': obj.octoberInCheck,
          'novemberInCheck': obj.novemberInCheck,
          'decemberInCheck': obj.decemberInCheck,
          'inscope': obj.inscope,
          'factory_usage': obj.factory_usage,

        }
      })
      
      this.inventoryData = tempData;
    }
      catch(error) {
        this.ShowErrorMessage(error.message);
      }
  }

  async exportData() {
    this.toastService.info(
      `Great things take time - we’re currently processing the template.
      Please do not exit or refresh this page.`
    );
    this.filters = [];
    this.monthsInRange = [];
   
    this.excelFileName = this.loggedInOrgName + ` (${this.startMonthName.substring(0,3)} - ${this.endMonthName.substring(0,3)} ${this.endyear}) VOC`
    // Format the start and end dates to 'YYYY-MM-DD HH:MM:SS' format
    this.startDateString = new Date(this.std.getFullYear(), this.std.getMonth(), this.std.getDate() + 1).toISOString().slice(0, 10);
    this.endDateString = new Date(this.edd.getFullYear(), this.edd.getMonth(), this.edd.getDate() + 1).toISOString().slice(0, 10);   
    if (this.std > this.edd) {
      [this.std, this.edd] = [this.edd, this.std]; // Swap if startDate is greater than endDate
    }

   
    let currentDate = new Date(this.std);

    // Loop through the months in the range
    while (currentDate <= this.edd) {
      let month = currentDate.getMonth() + 1; // Adding 1 to get 1-based month (Jan = 1)
      this.monthsInRange.push(month);
      currentDate.setMonth(currentDate.getMonth() + 1); // Increment month
    }

    this.filters.push({
      "member": "VocCalculations.report_month",
      "operator": "inDateRange",
      "values": [
        this.startDateString,
        this.endDateString
      ]
    });

    try {
      await this.getChemicalInventory();
    } catch (error) {
      console.error('An error occurred in getChemicalInvnetory:', error);
    }
    try {
      await this.downloadExcelWorkspace(
        this.inventoryData
      );
      console.log('All functions completed successfully.');
    } catch (error) {
      console.error('An error occurred during the final export:', error);
    }
  }

  changeKey(originalKey: string, newKey: string, arr: Array<Object>) {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      var obj = arr[i];
      obj[newKey] = obj[originalKey];
      delete (obj[originalKey]);
      newArr.push(obj);
    }
    return newArr;
  }

  downloadExcelWorkspace(inventoryData: any): void {
    // Split data into two arrays based on the inscope value
    this.inventoryDataInScope = inventoryData
      .filter(item => item.inscope === 1)
      .map((item, index) => {
        return {
          no: String(index + 1),  
          ...item               
        };
      });
    this.inventoryDataOutScope = inventoryData
      .filter(item => item.inscope === 0)
      .map((item, index) => {
        return {
          no: String(index + 1),  
          ...item               
        };
      });
    const processedDataInScope = this.inventoryDataInScope.map(row => ({
      no: row.no,
      manufacturer: row.chemical_supplier,
      chemicalName: row.chemical_product,
      casnr: row.casnr,
      substanceName: row.substance_name,
      percentage: row.percentage,
      consumption: row.consumption,   
    }));
    const processedDataOutScope = this.inventoryDataOutScope.map(row => ({
      no: row.no,
      chemicalName: row.chemical_product,
      manufacturer: row.chemical_supplier,
      casnr: row.casnr,
      substanceName: row.substance_name,
      percentage: row.percentage,
      consumption: row.consumption,
      factory_usage: row.factory_usage
    }));
    let workbook = new Workbook();
    let worksheet1 = workbook.addWorksheet(this.loggedInOrgName);
   
    worksheet1.mergeCells('B1', 'H1');
    let mainTitle = worksheet1.getCell('B1');
    mainTitle.value = "VOC Calculator"
    mainTitle.font = {
      name: 'Calibri',
      size: 18,
      bold: true,
    };
    mainTitle.alignment = { vertical: 'middle', horizontal: 'center' };
    
    worksheet1.mergeCells('B2', 'B4');
    let cellb = worksheet1.getCell('B2');
    cellb.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' }
    };
    worksheet1.mergeCells('C2', 'H2');
    let disclaimer_text = worksheet1.getCell('C2');
    disclaimer_text.value = `DISCLAIMER:
You are responsible for the data quality - therefore please thoroughly check your data and make sure to closely follow the instructions listed below.`;
    disclaimer_text.font = {
      name: 'Calibri',
      size: 12,
      bold: false,
      color: { argb: 'FF0000' }  // Red color
    };
    disclaimer_text.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' }
    };
    disclaimer_text.alignment = { wrapText: true };
    worksheet1.getRow(1).height = 30;
    worksheet1.getRow(2).height = 40;


    worksheet1.mergeCells('C3', 'H3');
    let information2 = worksheet1.getCell('C3');
    information2.value = `PLEASE NOTE:
In the below list you will find all information you previously uploaded in your BHive CIL, during the time frame you filtered for.
If a chemical was added several times, the consumption will be summed up.`;

    information2.font = {
          name: 'Calibri',
          size: 12,
          bold: false,
        };
    information2.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFF00' }
    };
    information2.alignment = { wrapText: true };

    worksheet1.getRow(3).height = 60;
    worksheet1.mergeCells('C4', 'H4');
    let information3 = worksheet1.getCell('C4');
    information3.value = `INSTRUCTIONS:
    1) Please fill out all missing information in the yellow cells in the table below.
    2) Please thoroughly check:
     - Is the data correct and complete?
     - Are there any duplicates that the system was not able to detect?
       --> The system provides colour coding for the same chemical product names. If you detect a duplicate, please remove it and add up the consumption yourself.
     - Are any chemicals missing? If so, please add them in a new row at the bottom of the table.
     - Are there any substances missing? If so, please add them for the respective chemical product.
     - Please also review the second sheet "Out-of-Scope Chemicals".
       --> If any chemicals were falsely classified as out-of-scope (e.g. Commodity Chemical), please add them to the bottom of this sheet's table, including the substances and consumption information.
  `;

    information3.font = {
          name: 'Calibri',
          size: 12,
          bold: false,
        };
    information3.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFF00' }
    };
    information3.alignment = { wrapText: true };
    worksheet1.getRow(4).height = 150;

    worksheet1.mergeCells('B6', 'H6');
    let title = worksheet1.getCell('B6')
    title.value = `Chemical Inventory List (${this.startData} ${this.startMonthName} ${this.startyear} - ${this.endData} ${this.endMonthName} ${this.endyear})`;
    title.font = {
      name: 'Calibri',
      size: 18,
      bold: true,
    };
    

    worksheet1.mergeCells('B8', 'B9');
    let no = worksheet1.getCell('B8')
    no.value = 'NO'
    this.setCellStyle(no);

    worksheet1.mergeCells('C8', 'C9');
    let manufacturer = worksheet1.getCell('C8')
    manufacturer.value = 'Manufacturer Name'
    this.setCellStyle(manufacturer);

    worksheet1.mergeCells('D8', 'D9');
    let chemical = worksheet1.getCell('D8')
    chemical.value = 'Chemical Name'
    this.setCellStyle(chemical);

    worksheet1.mergeCells('E8', 'G8');
    let ingredient = worksheet1.getCell('E8')
    ingredient.value = `Ingredient Information
    ( As given in SDS Section 3: Composition / Information on Ingredients)`
    this.setCellStyle(ingredient);
    ingredient.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };


    let cas = worksheet1.getCell('E9')
    cas.value = 'CAS Number'
    this.setCellStyle(cas);

    let substance = worksheet1.getCell('F9')
    substance.value = 'Official Substance Name'
    this.setCellStyle(substance);

    let concentration = worksheet1.getCell('G9')
    concentration.value = 'Maximum Concentration (%)'
    this.setCellStyle(concentration);

    worksheet1.mergeCells('H8', 'H9');
    let summary = worksheet1.getCell('H8')
    summary.value = this.startMonthName.substring(0, 3) + '-' + this.endMonthName.substring(0, 3) + ' consumption/Usage Amount (kg)'
    this.setCellStyle(summary);
    const duplicates = new Set<string>();
    const seen = new Set<string>();

    processedDataInScope.forEach(row => {
      const key = `${row.manufacturer}-${row.chemicalName}`;
      if (seen.has(key)) {
        duplicates.add(key); // Mark as duplicate
      } else {
        seen.add(key);
      }
    });

    processedDataInScope.forEach((d: any) => {
      let row = worksheet1.addRow(['', ...Object.values(d).map((value) => (value === null ? '' : value))]);

      row.eachCell((cell, colNumber) => {
        if ((cell.value === '' || cell.value === undefined) && colNumber !== 1) {
          // Yellow fill for null values
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFF00' },
            bgColor: { argb: '' }
          };
        
        }
      });

      // Apply red fill if the row is a duplicate
      const key = `${d.manufacturer}-${d.chemicalName}`;
      if (duplicates.has(key)) {
        row.eachCell((cell, colNumber) => {
          if (colNumber === 4) { 

            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FEB7C2' }, // Red color
              bgColor: { argb: '' }

            };
            // cell.font.color = { argb: 'red' }
          };
          });
        
      }
    });

    const columnWidth = 30;
    worksheet1.columns.forEach(column => {
      column.width = columnWidth;
    });
    worksheet1.getColumn('A').width = 4;
    worksheet1.getColumn('B').width = 8;
    worksheet1.getColumn('H').width = 40;



    const startColumnIndex = 9; // 'I' is the 9th column

    this.monthsInRange.forEach((month, index) => {
      const colIndex = startColumnIndex + index * 2; // Each month takes two columns

      // Merge cells for the month header (CIL and InCheck)
      worksheet1.mergeCells(8, colIndex, 8, colIndex + 1); // Merges two cells horizontally
      const monthCell = worksheet1.getCell(8, colIndex);
      monthCell.value = this.monthNames[month - 1]; // Convert month number to month name
      this.setCellStyle(monthCell);
      // Set "CIL" and "InCheck" headers below the month name
      const cliCell = worksheet1.getCell(9, colIndex);
      cliCell.value = 'CIL';
      this.setCellStyle(cliCell); // Apply custom style


      const inCheckCell = worksheet1.getCell(9, colIndex + 1);
      inCheckCell.value = 'InCheck';
      this.setCellStyle(inCheckCell); 
    });

    const monthKeys = [
      { cil: 'januaryCIL', inCheck: 'januaryInCheck' },
      { cil: 'februaryCIL', inCheck: 'februaryInCheck' },
      { cil: 'marchCIL', inCheck: 'marchInCheck' },
      { cil: 'aprilCIL', inCheck: 'aprilInCheck' },
      { cil: 'mayCIL', inCheck: 'mayInCheck' },
      { cil: 'juneCIL', inCheck: 'juneInCheck' },
      { cil: 'julyCIL', inCheck: 'julyInCheck' },
      { cil: 'augustCIL', inCheck: 'augustInCheck' },
      { cil: 'septemberCIL', inCheck: 'septemberInCheck' },
      { cil: 'octoberCIL', inCheck: 'octoberInCheck' },
      { cil: 'novemberCIL', inCheck: 'novemberInCheck' },
      { cil: 'decemberCIL', inCheck: 'decemberInCheck' }
    ];
    worksheet1.getRow(8).height = 40;
    // Add data for each month starting from row 17
    this.inventoryDataInScope.forEach((rowData, rowIndex) => {
      this.monthsInRange.forEach((month, index) => {
        const colIndex = startColumnIndex + index * 2;
        const row = 10 + rowIndex; // Starting row for data

        const { cil, inCheck } = monthKeys[month -1]; // Get the correct month-specific keys

        const cilCell = worksheet1.getCell(row, colIndex);
        const inCheckCell = worksheet1.getCell(row, colIndex + 1);

        // Set values for CIL and InCheck
        cilCell.value = rowData[cil];
        inCheckCell.value = rowData[inCheck];

        // Apply grey background to both cells
        cilCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D9D9D9' } // Light grey color
        };

        inCheckCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D9D9D9' } // Light grey color
        };
      });
    });

    // worksheet1.protect('', {
    //   selectUnlockedCells: true,  // Allow selection of unlocked cells
    //   insertColumns: true,
    //   insertRows: true,
    //   insertHyperlinks: true,
    //   deleteColumns: true,
    //   deleteRows: true,
    //   sort: true,
    //   autoFilter: true,
    //   pivotTables: true,
    //   formatCells: true,
    //   formatColumns: true,
    //   formatRows: true
    // });
    // worksheet1.eachRow((row) => {
    //   row.eachCell((cell) => {
    //     cell.protection = { locked: false }; // Unlock each cell
    //   });
    // });
    // title.protection = {
    //   locked: true
    // };
    title.dataValidation = {
      type: 'custom',
      formulae: ['FALSE()'], // Use 'formulae' as an array
      showErrorMessage: true,
      errorTitle: 'Restricted Cell',
      error: 'This cell cannot be edited.',
    };

    let worksheet2 = workbook.addWorksheet('Excluded Chemicals');
    worksheet2.mergeCells('A1', 'G1');
    let title2 = worksheet2.getCell('A1')
    title2.value = 'EXCLUDED CHEMICALS - PLEASE DOUBLE CHECK ';
    title2.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    title2.alignment = { vertical: 'middle', horizontal: 'center' };

    let empty = worksheet2.getCell('A2')
    empty.value = ''
    empty.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    let numb = worksheet2.getCell('A2')
    numb.value = 'No'
    this.setCellStyle(numb);

    let name = worksheet2.getCell('B2')
    name.value = 'Name'
    this.setCellStyle(name);

    let manufacturer2 = worksheet2.getCell('C2')
    manufacturer2.value = 'Manufacturer'
    this.setCellStyle(manufacturer2);

    let cas2 = worksheet2.getCell('D2')
    cas2.value = 'CAS Number'
    this.setCellStyle(cas2);
  
    let substance2 = worksheet2.getCell('E2')
    substance2.value = 'Official Substance Name'
    this.setCellStyle(substance2);

    let concentration2 = worksheet2.getCell('F2')
    concentration2.value = 'Maximum Concentration (%)'
    this.setCellStyle(concentration2);

    let summary2 = worksheet2.getCell('G2')
    summary2.value = this.startMonthName.substring(0, 3) + '-' + this.endMonthName.substring(0, 3) + ' consumption/Usage Amount (kg)'
    this.setCellStyle(summary2);

    let usage = worksheet2.getCell('H2')
    usage.value = 'General Usage in Factory'
    this.setCellStyle(usage);
    usage.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' },
      bgColor: { argb: '' }
    };
    let row = worksheet2.getRow(3);

    // Loop through columns A to H (1 to 8)
    for (let colNum = 1; colNum <= 8; colNum++) {
      let cell = row.getCell(colNum);  // Get the cell in row 3

      // Apply border to the cell
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    }
    processedDataOutScope.forEach((d: any) => {
      let row = worksheet2.addRow(Object.values(d).map((value) => value === null ? '' : value));

      row.eachCell((cell, colNumber) => {
        if (cell.value === '' || cell.value === undefined) {
          // Yellow fill for null values
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFF00' },
            bgColor: { argb: '' }
          };

        }
      });
    })


    worksheet2.columns.forEach(column => {
      column.width = columnWidth;
    });
    




    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, this.excelFileName + '.xlsx' );
      this.toastService.clear();
      this.toastService.info(
        `Excel downloaded successfully`
      );
      setTimeout(() => {
        this.toastService.clear();
      }, 3000);
    })


  }

  setCellStyle(cell) {
    cell.font = {
      name: 'Calibri',
      size: 11,
      bold: true,
    };
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFCC' },
      bgColor: { argb: '' }
    };
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  }
 
  setCellStyle3(cell) {
    cell.font = {
      name: 'Calibri',
      size: 11,
      bold: true,
    };
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '92D050' },
      bgColor: { argb: '' }
    };
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  }
  fileName: string = '';
  addFile(files: FileList) {
    // Ensure the input is reset if the same file is re-uploaded
    const fileInput = document.getElementById('file') as HTMLInputElement;

    // Check if files are selected
    if (files.length === 0) return;

    // Process the first file
    let file: File = files[0];
    this.fileName = file.name;
    this.excelData = file;

    // Allowed upload types
    let uploadTypes = ['xlsx', 'csv'];
    let nameArray = file.name.split('.');
    let type = nameArray[nameArray.length - 1].toLowerCase();

    // Clear the file input value after processing
    fileInput.value = ''; // Reset the input value

    if (uploadTypes.includes(type)) {
      // If the file type is valid, proceed
      this.document = file;
    } else {
      // If the file type is invalid, show an error
      this.toastService.error(this.alertMsgs['INVALID_FILE_FORMAT']);
    }
  }


  
  readExcelFile(file: File): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      this.excelFileName = file.name;
      this.totalVOCpte = 0;
      reader.onload = async (e: any) => {
        const arrayBuffer = e.target.result;

        try {
          // Load the workbook using ExcelJS
          const workbook = new Workbook();
          await workbook.xlsx.load(arrayBuffer);

          // Assuming you're reading the first worksheet
          const worksheet1 = workbook.worksheets[0];

          const startColumnIndex = 9;  // Column "I" is the 9th column
          const rowIndex = 8;         

          // Get the row to determine the last column with data
          const row = worksheet1.getRow(rowIndex);
          const lastColumnWithData = row.cellCount;  // Get the last column with data in row 8

          // Iterate over the cells from column H (8) to the last column with data
          for (let colIndex = startColumnIndex; colIndex <= lastColumnWithData; colIndex++) {
            const cell = row.getCell(colIndex);

            // Unmerge the cell if it is merged
            if (cell.isMerged) {
              worksheet1.unMergeCells(rowIndex, colIndex, rowIndex, colIndex);
            }
          }

          worksheet1.eachRow((row, rowIndex) => {
            if (rowIndex > 7) { // Adjust based on your row condition
              const startColumnIndex = 9; // Column "I"
              let lastColumnWithData = row.cellCount; // Get the last column with data

              // Loop backward from the last column to the start column (H)
              for (let colIndex = lastColumnWithData; colIndex >= startColumnIndex; colIndex--) {
                if (rowIndex == 8) {
                  const currentCell = row.getCell(colIndex);
                  const targetCell = row.getCell(colIndex + 2); // Shift two columns to the right
                  if (currentCell.style) {
                    targetCell.style = JSON.parse(JSON.stringify(currentCell.style));
                  }
                  targetCell.value = currentCell.value;
                  currentCell.value = null;
                  currentCell.style = {};
                  if (!row.getCell(colIndex + 1).isMerged && !row.getCell(colIndex + 2).isMerged)
                    worksheet1.mergeCells(rowIndex, colIndex + 1, rowIndex, colIndex + 2);
                } 
                else {
                  const currentCell = row.getCell(colIndex);
                  const targetCell = row.getCell(colIndex + 2); // Shift two columns to the right

                  // Copy value
                  targetCell.value = currentCell.value;

                  // Copy style (fill, font, alignment, etc.)
                  if (currentCell.style) {
                    targetCell.style = JSON.parse(JSON.stringify(currentCell.style));
                  }

                  // Clear the original cell's value and style
                  currentCell.value = null;
                  currentCell.style = {};
              }
              }
              if (rowIndex === 8) { // Ensure this logic runs only for the 8th row
                for (let colIndex = lastColumnWithData + 2; colIndex >= startColumnIndex + 2; colIndex--) {
                  row.getCell(colIndex).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                  };
                }
              }
            }
          });
          worksheet1.mergeCells('I8', 'I9');
          let vocCubstance = worksheet1.getCell('I8')
          vocCubstance.value = 'VOC Substance'
          this.setCellStyle3(vocCubstance);
          worksheet1.mergeCells('J8', 'J9');
          let emissions = worksheet1.getCell('J8')
          emissions.value = 'VOC emissions (PTE of VOCs) (kg)'
          this.setCellStyle3(emissions);
          worksheet1.getColumn('I').width = 30;
          worksheet1.getColumn('J').width = 40;
          const lastRow = worksheet1.lastRow.number;
          const startRow = 10; 
          const columnsToCheck = ['C', 'D', 'E', 'F', 'G', 'H'];
          for (let rowNum = startRow; rowNum <= lastRow; rowNum++) {
            for (let col of columnsToCheck) {
              const cellValue = worksheet1.getCell(`${col}${rowNum}`).value;
              if (cellValue != null && cellValue != undefined && cellValue.toString().trim() != '') {
                worksheet1.getCell(`${col}${rowNum}`).fill = null;
              }
            }
            const row = worksheet1.getRow(rowNum);

            const caseNumber = row.getCell(5).value; // Column D (5th column)
            let columnF = row.getCell(7).value; // Column F (7th column)
            let columnG = row.getCell(8).value; // Column G (8th column)
            const columnH = row.getCell(9); // Column H (9th column)
            const columnI = row.getCell(10); // Column I (10th column)
            if (columnG != 'NA')
              this.totalConsumption = this.totalConsumption + Number(columnG);
            // Check if the case number exists in vocexcellList
            if (caseNumber) {
              let matchFound = false;
              for (const item of this.VocExcelList) {
                if (item.cas_no === caseNumber) {
                  matchFound = true;
                  break;
                }
              }
              // If match found, set 'Yes' in column H and the product of F * G in column I
              if (matchFound) {
                columnH.value = 'Yes';
                if ((columnF != null && columnF != undefined && columnF != '') && (columnG != null && columnG != undefined && columnG != 'NA') )
                  columnI.value = (Number(columnF) / 100) * (Number(columnG));
                if (columnI.value != '')
                  this.totalVOCpte = this.totalVOCpte + Number(columnI.value);
                if (columnG != null && columnG != undefined && columnG != 'NA')
                this.totalVOCconsumption = this.totalVOCconsumption + Number(columnG);
                
              } else {
                columnH.value = '';
                columnI.value = '';
              }
            }
          }
          workbook.xlsx.writeBuffer().then((data) => {
            this.toastService.info(
              `VOC is being calculated`
            );
            this.updatedBlob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            this.vocService.uploadVocCalculations(this.updatedBlob, this.excelFileName, this.organization_id)?.subscribe(
              (res: any) => {
                console.log('File uploaded successfully', res);
                this.path = res;
                
            this.totalVOCpteTon = this.totalVOCpte / 1000;
            this.totalConsumptionTon = this.totalConsumption / 1000;
            this.totalVOCconsumptionTon = this.totalVOCconsumption / 1000
            if (this.totalConsumptionTon != 0)
              this.percTotalVocConsump = this.totalVOCconsumptionTon / this.totalConsumptionTon
            else this.percTotalVocConsump = 0
            if (this.totalVOCpteTon <= 5) {
              this.level = 'Aspirational Level ';
            } else if (this.totalVOCpteTon <= 15) {
              this.level = 'Progressive Level';
            } else if (this.totalVOCpteTon <= 25) {
              this.level = 'Foundational Level';
            } else {
              this.level = 'Not Passed';
            }
            this.vocService.addVocCalculations(
              this.startDateString,
              this.endDateString,
              this.totalVOCpteTon,
              this.level,
              this.totalConsumptionTon,
              this.totalVOCconsumptionTon,
              this.percTotalVocConsump,
              this.excelFileName,
              this.path
            )?.subscribe(
              (res: any) => {
                // Handle successful response
                console.log('Response:', res);
                const firstRow = worksheet1.getRow(9); 

                // Populate the column names (A, B, C, etc.)
                this.displayedColumns = [];
                firstRow.eachCell((cell, colNumber) => {
                  if (colNumber <= 10 && colNumber!=1) {
                    this.displayedColumns.push(cell.value); // Add cell value to the header columns array
                  }
                });

                // Now, store the remaining data (from row 17 onward)
                this.displayedData = [];
                for (let rowNum = 10; rowNum <= worksheet1.lastRow.number; rowNum++) {
                  const row = worksheet1.getRow(rowNum);
                  const rowData = {};

                  // Create an object for each row where keys are column names and values are cell values
                  this.displayedColumns.forEach((col, index) => {
                    if (index + 1 <= 10) {
                      rowData[col] = row.getCell(index + 2).value; // Adjusting for zero-indexed columns
                    }
                  });

                  this.displayedData.push(rowData);
                }
                this.calculatePagination();
                this.getVocOverallTable();
              },
              (error: any) => {
                // Handle error
                console.error('Error:', error);
              })
              }),
              (error: any) => {
                console.error('Error uploading file', error);
              }
          }).catch((error) => {
            console.error('Error updating Excel file', error);
          });


        } catch (error) {
          console.error('Error reading Excel file:', error);
          reject('Error reading Excel file');
        }
      };

      reader.onerror = () => {
        reject('Error reading file');
      };

      reader.readAsArrayBuffer(file); // Read the file as array buffer
    });
  }


  upload() {

    if (this.document == null) {

      this.toastService.warn(this.alertMsgs['EMPTY_FILE_UPLOAD_ALERT'])

    } else {
      this.validateExcelFile(this.document)
      

      
    }
  }

  validateExcelFile(file: File): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      this.excelFileName = file.name;
      reader.onload = async (e: any) => {
        const arrayBuffer = e.target.result;

        try {
          // Load the workbook using ExcelJS
          const workbook = new Workbook();
          await workbook.xlsx.load(arrayBuffer);

          // Assuming you're reading the first worksheet
          const worksheet1 = workbook.worksheets[0];
          let titleDate = worksheet1.getCell('B6').value as string;
          const regex = /\((\d{1,2}) (\w+) (\d{4}) - (\d{1,2}) (\w+) (\d{4})\)/;
          const match = titleDate.match(regex);
          let startDate;
          let startMonth;
          let startYear;
          let endDate;
          let endMonth;
          let endYear;

          if (match) {
            startDate = match[1];
            startMonth = match[2];
            startYear = match[3];
            endDate = match[4];
            endMonth = match[5];
            endYear = match[6];
          }

          if (
            worksheet1.getCell('C8').value === 'Manufacturer Name' &&
            worksheet1.getCell('D8').value === 'Chemical Name' &&
            worksheet1.getCell('E9').value === 'CAS Number' &&
            worksheet1.getCell('F9').value === 'Official Substance Name' &&
            worksheet1.getCell('G9').value === 'Maximum Concentration (%)' &&
            worksheet1.getCell('H8').value === startMonth.substring(0, 3) + '-' + endMonth.substring(0, 3) + ' consumption/Usage Amount (kg)'
          ) {
            let isDataValid = true;

            const startRow = 10;
            const endRow = worksheet1.rowCount;
            const columnsToCheck = ['C', 'D', 'E', 'F', 'G', 'H'];
            if (startRow <= endRow) {
              for (let row = startRow; row <= endRow; row++) {
                for (let col of columnsToCheck) {
                  const cellValue = worksheet1.getCell(`${col}${row}`).value;
                  if (cellValue === null || cellValue === undefined || cellValue.toString().trim() === '') {
                    isDataValid = false;
                    break;
                  }
                  }
                if (!isDataValid) break;
              }

              if (isDataValid) {
                if (
                  startDate + '' + startMonth + '' + startYear === this.startData + '' + this.startMonthName + '' + this.startyear &&
                  endDate + '' + endMonth + '' + endYear === this.endData + '' + this.endMonthName + '' + this.endyear
                ) {
                  console.log("All validations passed.");
                  this.toastService.clear();
                  this.uploadComplete = true;

                } else {
                  this.uploadComplete = false;
                  this.toastService.error(this.alertMsgs['MATCH_DATE']);
                }
              } else {
                this.uploadComplete = false;
                this.toastService.error(this.alertMsgs['MISSING_DATA']);
              }
            }
            else {
              this.uploadComplete = false;
              this.toastService.error(this.alertMsgs['NO_DATA_EXCEL'])
            }
          }
          
          else {
            this.uploadComplete = false;
            this.toastService.error(this.alertMsgs['VALID_EXCEL']);
          }

        } catch (error) {
          console.error('Error reading Excel file:', error);
          reject('Error reading Excel file');
        }
      };

      reader.onerror = () => {
        reject('Error reading file');
      };

      reader.readAsArrayBuffer(file); // Read the file as array buffer
    });
  }
  changeEndDate(event: MatDatepickerInputEvent<Date>, type: string) {
    if (event.value !== null) {
      this.std = new Date(this.startDate.value);
      this.edd = new Date(this.endDate.value);
      this.startMonthName = this.monthNames[this.std.getMonth()];
      this.endMonthName = this.monthNames[this.edd.getMonth()];
      this.endyear = this.edd.getFullYear();
      this.startyear = this.std.getFullYear();
      this.startData = this.std.getDate();
      this.endData = this.edd.getDate();
      this.startDateString = new Date(this.std.getFullYear(), this.std.getMonth(), this.std.getDate() + 1).toISOString().slice(0, 10);
      this.endDateString = new Date(this.edd.getFullYear(), this.edd.getMonth(), this.edd.getDate() + 1).toISOString().slice(0, 10);     
    }
  }

  ShowErrorMessage(msg) {
    this.isLoading = false;
    let customMSg = false;
    if (msg.includes("No id found in Security Context")) {
      msg = 'No factories are connected to this account, so no data can be displayed.\n Ask your factories to connect to you to see their data.',
        customMSg = true;
    }
    if (msg.includes("Network request failed")) {
      this.isCubejsConnected = false;
      throw new Error("CubeJS is not reachable!")
      // return false;
    }
    if (!this.errorList.includes(msg)) {
      this.errorList.push(msg);
      this.dialog.open(MessageDialogComponent, {
        hasBackdrop: true,
        width: "32rem",
        disableClose: true,
        data: {
          message: msg,
          hasLink: customMSg ? true : false,
          goToLink: customMSg ? "How to share inventories" : "",
          URL: customMSg ? "https://cloud.goblu.net/s/4LXyFHrE86Tzx2A" : ""
        },
      });
      // alert(msg);
    }
  }
  getVocOverallTable() {
    if(this.isBrand){
      this.vocService.getAllVocCalculations().subscribe((res: any) => {
        this.vocCalculations = res.vocCalculations;
        this.allVocCalculations = [...this.vocCalculations];
        if (this.supplierId) {
          this.vocCalculations = res.vocCalculations.filter(item => item.organization_id === Number(this.supplierId));
        }
        else {
          const factoryNames = this.vocCalculations
            .map(item => item.factory_name)
            .filter((value, index, self) => self.indexOf(value) === index);
          this.allFactories = factoryNames;
          this.factoryFilter = [...factoryNames];
        }
        this.isLoading = false;
      })
      

    }
    else {
      this.vocService.getVocCalculations().subscribe((res: any) => {
        this.vocCalculations = res.vocCalculations;
        this.isLoading = false;
      })

    }
  }

  deleteVoc(id: number) {
    this.isLoading = true;
    this.vocService.deleteVocCalculation(id).subscribe((res: any) => {
      this.getVocOverallTable();
    })
  }

  downloadResults() {
    if (this.updatedBlob) {
      const url = window.URL.createObjectURL(this.updatedBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.excelFileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } else {
      this.toastService.error('No file available to download.');
    }
  }

  calculatePagination(): void {
    this.totalPages = Math.ceil(this.displayedData.length / this.pageSize);
    this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    this.updatePaginatedData();
  }

  updatePaginatedData(): void {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedData = this.displayedData.slice(startIndex, endIndex);
  }

  changePage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.updatePaginatedData();
    }
  }


  download(result) {
    this.http.get(this.baseHref + '/downloadVocCalculation/' + result.id, { responseType: 'blob' }).subscribe(
      (res) => {
        console.info(res)
        let blob = new Blob([res], { type: 'application/application/octet-stream' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = result.document_name
        link.click();
      },
      (error) => {
        if (error['status'] == '500' || error['status'] == '401' || error['status'] == '404') {

        }
      }
    );
  }

  view(result) { 
    this.currentPage = 1;
    this.viewResult = true;
    this.homePage = false;
    this.selectedFactory = this.isBrand ? result.factory_name : null;
    this.selectedTimeFrame = this.isBrand ? result.date_from + ' - ' + result.date_to : null;
    this.http.get(this.baseHref + '/downloadVocCalculation/' + result.id, { responseType: 'blob' }).subscribe(
      (res) => {
        this.totalVOCpteTon = result.voc_pte;
        this.level = result.level;
        console.info(res)
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          this.excelFileName = result.document_name;
          reader.onload = async (e: any) => {
            const arrayBuffer = e.target.result;

            try {
              // Load the workbook using ExcelJS
              const workbook = new Workbook();
              await workbook.xlsx.load(arrayBuffer);

              // Assuming you're reading the first worksheet
              const worksheet1 = workbook.worksheets[0];

              // Extract data for display (columns A to I, rows starting from 16)
              const firstRow = worksheet1.getRow(9); // Assuming row 16 is your header row

              // Populate the column names (A, B, C, etc.)
              this.displayedColumns = [];
              firstRow.eachCell((cell, colNumber) => {
                if (colNumber <= 10 && colNumber != 1) {
                  this.displayedColumns.push(cell.value); // Add cell value to the header columns array
                }
              });

              // Now, store the remaining data (from row 17 onward)
              this.displayedData = [];
              for (let rowNum = 10; rowNum <= worksheet1.lastRow.number; rowNum++) {
                const row = worksheet1.getRow(rowNum);
                const rowData = {};

                // Create an object for each row where keys are column names and values are cell values
                this.displayedColumns.forEach((col, index) => {
                  if (index + 1 <= 10) {
                    rowData[col] = row.getCell(index + 2).value; // Adjusting for zero-indexed columns
                  }
                });

                this.displayedData.push(rowData);
                this.calculatePagination();

                workbook.xlsx.writeBuffer().then((data) => {
                  this.updatedBlob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                })
              }


            } catch (error) {
              console.error('Error reading Excel file:', error);
              reject('Error reading Excel file');
            }
          };

          reader.onerror = () => {
            reject('Error reading file');
          };

          reader.readAsArrayBuffer(res); // Read the file as array buffer
        });
      },
      (error) => {
        if (error['status'] == '500' || error['status'] == '401' || error['status'] == '404') {

        }
      }
    );

  }

}
