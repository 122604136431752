import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSlider, MatSliderChange } from '@angular/material/slider';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalsService } from '../globals-services/globals.service';
import { LanguageService } from '../language/language.service';
import { allCountries, companyTypesEnglish, factoryTypesEnglish } from './signupOptions';

declare const grecaptcha: any
@Component({
    selector: 'app-user-signup',
    templateUrl: './user-signup.component.html',
    styleUrls: ['./user-signup.component.css']
})
export class UserSignupComponent implements OnInit {

    @ViewChild('customerInput') customerInput: ElementRef<HTMLInputElement>;
    @ViewChild('stepper') private myStepper: MatStepper;
    @ViewChild('slider') slider: MatSlider;

    factoryTypesEnglish = factoryTypesEnglish;
    companyTypesEnglish = companyTypesEnglish;

    factoryTypes = [];
    companyTypes = [];

    countries = allCountries;
    isFactory = false;

    personalInfoGroup: FormGroup;
    companyInfoGroup: FormGroup;
    hearAboutUsGroup: FormGroup;

    visible = true;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    customers = [];
    refid = '';
    successPage = false;
    isLoading = false;
    sliderColor = "warn";
    sliderValid: boolean = false;


    selectedLanguageCode;

    referalID = null;
    nonce = null;

    constructor(
        private _formBuilder: FormBuilder,
        private http: HttpClient,
        private globalsService: GlobalsService,
        private translateService: TranslateService,
        public languageService: LanguageService,
        private activatedRoute: ActivatedRoute
    ) {
        this.isLoading = true;

        this.fetchTranslations();
        this.translateService.onLangChange.subscribe(() => {
            // this.loading = true;
            this.fetchTranslations();
        });

        this.personalInfoGroup = this._formBuilder.group({
            nameControl: ['', Validators.required],
            emailControl: ['', [Validators.required, Validators.email]],
            phoneControl: ['', Validators.required],
            positionControl: ['', Validators.required]
        });
        this.companyInfoGroup = this._formBuilder.group({
            companyNameControl: ['', Validators.required],
            companyAddressControl: ['', [Validators.required]],
            companyTypeControl: ['', [Validators.required]],
            countryControl: ['', Validators.required],
            fccIDControl: ['', ''],
            walmartIDControl: ['', ''],
            oarIDControl: ['', ''],
            higgIDControl: ['', ''],
            factoryAddressControl: ['', null],
            factoryTypeControl: ['', null],
            subscriptionPlanControl: ['Full Bhive', null],
            topCustomer: [[], null]
        });
        this.hearAboutUsGroup = this._formBuilder.group({
            invitedByControl: ['', Validators.required],
        });

        this.activatedRoute.queryParams.subscribe(params => {
            this.referalID = params['ref'];
        });
    }


    ngOnInit() {
        this.selectedLanguageCode = this.languageService.defaultLanguage['languageCode'];
        this.languageService.getAvailableLanguages.subscribe((languages) => {
            this.languageService.availableLanguages = languages;
            // console.log(this.selectedLanguageCode);
            // console.log( this.languageService.availableLanguages.find( language => language["languageCode"] == this.selectedLanguageCode) );
            this.translateService.use(this.languageService.availableLanguages.find(language => language["languageCode"] == this.selectedLanguageCode)["languageCode"]);
            this.isLoading = false;
        });

        this.getNonce();

    }

    getNonce() {
        this.http.get<any>(this.globalsService.baseHref + '/userOnBoarding/ping').subscribe((request) => {
            this.nonce = request['nid'];
        })
    }




    getErrorMessage() {
        if (this.personalInfoGroup.controls['emailControl'].hasError('required')) {
            return 'You must enter a value';
        }

        return this.personalInfoGroup.controls['emailControl'].hasError('email') ? 'Not a valid email' : '';
    }

    addCustomer(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            this.customers.push(value.trim());
        }

        if (input) {
            input.value = '';
        }

        this.companyInfoGroup.controls['topCustomer'].setValue(null);
    }

    removeCustomer(customer: string): void {
        const index = this.customers.indexOf(customer);

        if (index >= 0) {
            this.customers.splice(index, 1);
        }
    }


    submitForm() {
        this.checkFactoryData()
        if (!this.personalInfoGroup.valid) {
            this.myStepper.selectedIndex = 0;
        } else if (!this.companyInfoGroup.valid) {
            this.myStepper.selectedIndex = 1;
        } else if (!this.hearAboutUsGroup.valid) {
            this.myStepper.selectedIndex = 2;
        } else if (!this.sliderValid) {
            this.slider.value = 0.5;
        } else {
            this.isLoading = true;

            grecaptcha.ready(() => {
                grecaptcha.execute('6LeQV70aAAAAANhM2KF6WyTMnv2v0MoXUbrDJgAV', { action: 'test' }).then((token) => {
                    const payload = {
                        name: this.personalInfoGroup.value['nameControl'],
                        email: this.personalInfoGroup.value['emailControl'].trim(),
                        phone: this.personalInfoGroup.value['phoneControl'].trim(),
                        position: this.personalInfoGroup.value['positionControl'],
                        companyName: this.companyInfoGroup.value['companyNameControl'],
                        companyAddress: this.companyInfoGroup.value['companyAddressControl'],
                        role: this.companyInfoGroup.value['companyTypeControl'],
                        country: this.companyInfoGroup.value['countryControl'],
                        higgID: this.companyInfoGroup.value['higgIDControl'],
                        oarID: this.companyInfoGroup.value['oarIDControl'],
                        fccID: this.companyInfoGroup.value['fccIDControl'],
                        walmartID: this.companyInfoGroup.value['walmartIDControl'],
                        factoryAddress: this.companyInfoGroup.value['factoryAddressControl'],
                        factoryType: this.companyInfoGroup.value['factoryTypeControl'],
                        subscriptionType: this.isFactory ? this.companyInfoGroup.value['subscriptionPlanControl'] : "Monitoring",
                        topCustomer: this.customers,
                        referal: this.hearAboutUsGroup.value['invitedByControl'],
                        referalId: this.referalID,
                        nid: this.nonce,
                        captchaToken: token
                    }


                    this.http.post(this.globalsService.baseHref + '/signup', payload).subscribe((res) => {
                        this.refid = res['id'];
                        this.isLoading = false;
                        this.successPage = true;
                    },
                        (err) => {
                            console.log(err);
                            this.isLoading = false;
                            alert('There was a fatal error. Try resubmitting the form');
                        });
                });
            });


        }
    }

    validateEmail() {
        if (this.personalInfoGroup.get('emailControl').valid) {
            this.http.post(this.globalsService.baseHref + '/checkMail', { mail: this.personalInfoGroup.value['emailControl'] }).subscribe((res) => {
                if (res['status'] !== 'SUCCESS') {
                    this.personalInfoGroup.controls['emailControl'].setErrors({
                        'emailTaken': true
                    })
                }
            });
        }

    }

    changeLanguage() {
        this.translateService.use(this.languageService.availableLanguages.find(language => language["languageCode"] == this.selectedLanguageCode)["languageCode"]);
    }

    isFactoryType() {
        if (this.companyInfoGroup.value['companyTypeControl']['role'] === 1) {
            this.isFactory = true;
        } else {
            this.isFactory = false;
        }
    }

    checkFactoryData() {
        this.isFactoryType()
        if (this.isFactory) {
            if (this.companyInfoGroup.value['factoryAddressControl'] === null || this.companyInfoGroup.value['factoryAddressControl'] === '') {
                this.companyInfoGroup.controls['factoryAddressControl'].setErrors({
                    'incomplete': true
                });
            }
            if (this.companyInfoGroup.value['factoryTypeControl'] === null || this.companyInfoGroup.value['factoryTypeControl'] === '') {
                this.companyInfoGroup.controls['factoryTypeControl'].setErrors({
                    'incomplete': true
                });
            }
        } else {
            //remove previously entered data if this is a brand or importer
            this.companyInfoGroup.value['factoryAddressControl'] = '';
            this.companyInfoGroup.value['factoryTypeControl'] = '';
            this.customers = [];

            //reset factor control
            this.companyInfoGroup.controls['factoryTypeControl'].setErrors(null);
            this.companyInfoGroup.controls['factoryAddressControl'].setErrors(null);
        }
    }

    fetchTranslations() {
        this.translateService.get("USER_SIGNUP_COMPONENT.FACTORY_TYPES").subscribe((factoryTypes) => {
            this.factoryTypes = [
                { english: this.factoryTypesEnglish["ACCESSORY_FACTORY"], translation: factoryTypes["ACCESSORY_FACTORY"] },
                { english: this.factoryTypesEnglish["DENIM_WASHING"], translation: factoryTypes["DENIM_WASHING"] },
                { english: this.factoryTypesEnglish["FABRIC_DYING_MILL"], translation: factoryTypes["FABRIC_DYING_MILL"] },
                { english: this.factoryTypesEnglish["FABRIC_PRINTING_MILL"], translation: factoryTypes["FABRIC_PRINTING_MILL"] },
                { english: this.factoryTypesEnglish["GARMENT_FACTORY"], translation: factoryTypes["GARMENT_FACTORY"] },
                { english: this.factoryTypesEnglish["SHOE_ASSEMBLY"], translation: factoryTypes["SHOE_ASSEMBLY"] },
                { english: this.factoryTypesEnglish["PIECE_PRINTING"], translation: factoryTypes["PIECE_PRINTING"] },
                { english: this.factoryTypesEnglish["WASHING_MILL"], translation: factoryTypes["WASHING_MILL"] },
                { english: this.factoryTypesEnglish["YARN_DYEING_MILL"], translation: factoryTypes["YARN_DYEING_MILL"] },
                { english: this.factoryTypesEnglish["OTHER"], translation: factoryTypes["OTHER"] }
            ];
        });
        // this.translateService.get("USER_SIGNUP_COMPONENT.COMPANY_TYPES").subscribe((companyTypes) => {
        //     this.companyTypes = [
        //         { english: this.companyTypesEnglish["BRAND"]["name"], translation: companyTypes["BRAND"], role: this.companyTypesEnglish["BRAND"]["role"] },
        //         { english: this.companyTypesEnglish["FACTORY"]["name"], translation: companyTypes["FACTORY"], role: this.companyTypesEnglish["FACTORY"]["role"] },
        //         { english: this.companyTypesEnglish["IMPORTER"]["name"], translation: companyTypes["IMPORTER"], role: this.companyTypesEnglish["IMPORTER"]["role"] }
        //     ];
        // });


        this.translateService.get("SIGN_UP_PAGE_NEW_LABEL.COMPANY_TYPES").subscribe((companyTypes) => {
            this.companyTypes = [
                { english: this.companyTypesEnglish["BRAND"]["name"], translation: companyTypes["BRAND"], role: this.companyTypesEnglish["BRAND"]["role"] },
                { english: this.companyTypesEnglish["FACTORY"]["name"], translation: companyTypes["FACTORY"], role: this.companyTypesEnglish["FACTORY"]["role"] },
                { english: this.companyTypesEnglish["IMPORTER"]["name"], translation: companyTypes["IMPORTER"], role: this.companyTypesEnglish["IMPORTER"]["role"] }
            ];
        });
    }

    formIsInValid() {
        this.checkFactoryData()
        return !(
            this.companyInfoGroup.valid &&
            this.personalInfoGroup.valid &&
            this.hearAboutUsGroup.valid &&
            this.sliderValid
        );
    }

    sliderControl(event: MatSliderChange) {
        // console.log(event);
        this.sliderColor = event.value == 50 ? "primary" : "warn";
        this.sliderValid = event.value == 50 ? true : false;

         
    }


    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }
    
     
}


function customerValidator(customers: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (customers.length == 0) {
            return { 'customers': true }
        }
        return null;
    };

}
