import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastService } from 'app/common/toast-alert/toast.service';
import { GlobalsService } from 'app/globals-services/globals.service';
import { InventoriesReportComponent } from 'app/inventories/inventories-report/inventories-report.component';
import { CubejsService } from 'app/shared/cubejs/cubejs.service';
import { MessageDialogComponent } from 'app/shared/message-dialog/message-dialog.component';
import { ExportDataService } from 'app/shared/services/export-data.service';
import { FormulaService } from 'app/shared/services/formula.service';

import moment from 'moment';


@Component({
  selector: 'app-factory-analytics-list',
  templateUrl: './factory-analytics-list.component.html',
  styleUrls: ['./factory-analytics-list.component.scss']
})
export class FactoryAnalyticsListComponent implements OnInit {

  @ViewChild(InventoriesReportComponent, { static: true }) child: InventoriesReportComponent;
  @ViewChild('factoryScanInfoMatPaginator') factoryScanInfoMatPaginator: MatPaginator;
  @ViewChild('factoryScanInfoMatSort') factoryScanInfoMatSort: MatSort;
  @ViewChild('factoryLastScanInfoMatPaginator') factoryLastScanInfoMatPaginator: MatPaginator;
  @ViewChild('factoryLastScanInfoMatSort') factoryLastScanInfoMatSort: MatSort;
  @ViewChild('factoryRatingInfoMatPaginator') factoryRatingInfoMatPaginator: MatPaginator;
  @ViewChild('factoryRatingInfoMatSort') factoryRatingInfoMatSort: MatSort;
  @ViewChild('topChemicalsMatPaginator') topChemicalsMatPaginator: MatPaginator;
  @ViewChild('topChemicalsMatSort') topChemicalsMatSort: MatSort;
  @ViewChild('topManufacturersMatPaginator') topManufacturersMatPaginator: MatPaginator;
  @ViewChild('topManufacturersMatSort') topManufacturersMatSort: MatSort;
  @ViewChild('topUnverifiedChemicalsMatPaginator') topUnverifiedChemicalsMatPaginator: MatPaginator;
  @ViewChild('topUnverifiedChemicalsMatSort') topUnverifiedChemicalsMatSort: MatSort;
  @ViewChild('topUnverifiedManufacturersMatPaginator') topUnverifiedManufacturersMatPaginator: MatPaginator;
  @ViewChild('topUnverifiedManufacturersMatSort') topUnverifiedManufacturersMatSort: MatSort;
  @ViewChild('topVerifiedChemicalsMatPaginator') topVerifiedChemicalsMatPaginator: MatPaginator;
  @ViewChild('topVerifiedChemicalsMatSort') topVerifiedChemicalsMatSort: MatSort;

  @ViewChild('chemicalInfoMatPaginator') chemicalInfoMatPaginator: MatPaginator;
  @ViewChild('chemicalInfoMatSort') chemicalInfoMatSort: MatSort;

  @ViewChild('activityInfoMatPaginator') activityInfoMatPaginator: MatPaginator;
  @ViewChild('activityInfoMatSort') activityInfoMatSort: MatSort;

  @ViewChild('rawMatPaginator') rawMatPaginator: MatPaginator;
  @ViewChild('rawInfoMatSort') rawInfoMatSort: MatSort;

  isFactoryZdhc = GlobalsService.zdhcGatewaySubscriptionStatus;



  errorList = [];

  // #region Properties
  startDateString: string;
  endDateString: string;
  startDate = new FormControl(moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'));
  endDate = new FormControl(moment().endOf('month').format('YYYY-MM-DD'));
  startMonth = moment().subtract(11, 'months').startOf('month').format('YYYY-MM-DD');

  startDateStringActivity: string;
  endDateStringActivity: string;
  startDateActivity = new FormControl(moment().startOf('month').format('YYYY-MM-DD'));
  endDateActivity = new FormControl(moment().endOf('month').format('YYYY-MM-DD'));

  

  //overall properties
  brandName: string;
  complianceName: string;
  organizationId: string; //must be a string
  isLoading = false;
  tabIndex: number = 0;
  view = [(window.innerWidth) - 200, (window.innerHeight * 16) / 40]
  lineView = [500, 320]
  pieView = [500, 320]
  filters: Array<Object> = [];
  productTypeFilter: [];
  // productTypeControl = new FormControl();
  usedForFilter: [];
  usedForControl = new FormControl();
  factoryUsage: [];
  factoryUsageControl = new FormControl();
  activityType: [];
  activityTypeControl = new FormControl();
  inditexCategoryList: Array<Object>;
  inditexCategoryControl = new FormControl();
  inditexLevelList: Array<Object>;
  inditexLevelControl = new FormControl();
  chemicalNameControl = new FormControl();
  commoditiesControl = new FormControl(false);
  commoditiesValue: boolean;
  hide_Title: true;
  hasNotData: boolean = false;
  rowDataFilters: Array<Object> = [];
  // #endregion

  isCubejsConnected: boolean = true;
  // #region Supply Chain
  isLoadingTimeSeriesCompliance = false;
  timeSeriesCompliance: Array<Object>;
  rawDataSeriesByCount: Array<Object>;
  rawDataSeriesByWeight: Array<Object>;
  timeSeriesConsumption: Array<Object>
  isLoadingTimeSeriesConsumption = false;

  organizationScope: any;
  BrandsScope: any = [];
  hasScopeCommodities: boolean = false;

  constructor(private toastService: ToastService,
    private exportService: ExportDataService,
    private cubejsService: CubejsService,
    private globalsService: GlobalsService,
    private http: HttpClient, public dialog: MatDialog) { }

  onResize(event) {
    this.view = [event.target.innerWidth, (event.target.innerHeight - (0.63 * event.target.innerHeight))];
    // window.dispatchEvent(new Event('resize'));
  }

  ngOnInit(): void {
    this.organizationId = this.globalsService.profile["id"].toString();
    // this.http.get<any>(this.globalsService.baseHref + '/complianceCriteria/getRules/' + this.organizationId).subscribe((response) => {
    //   if (response != null && response != undefined && response.length != 0) {
    //     let listOfRules = response[0].listOfRules;
    //     if (listOfRules.length != 0) {
    //       listOfRules.forEach(element => {
    //         if (element.members.some(i => i.memberValue.toLowerCase().includes('basic chemical'))) {
    //           this.commoditiesControl.setValue(true);
    //           this.commoditiesValue = true;
    //         }
    //       }
    //       );
    //     }
    //     else {
    //       this.commoditiesControl.setValue(false);
    //       this.commoditiesValue = false;
    //     }
    //   }
    //   else {
    //     this.commoditiesControl.setValue(false);
    //     this.commoditiesValue = false;
    //   }

    //   this.getOnInitFilters();
    // }
    // )
    this.http.get<any>(this.globalsService.baseHref + '/complianceCriteria/getBrandsWithScopeAndCompliance').subscribe((response) => {
      this.BrandsScope = response['brands'];
      this.organizationScope = this.isFactoryZdhc ? response['defaultZDHCMemberScopeValue'] : response['defaultNonZDHCMemberScopeValue'];
      this.hasScopeCommodities = this.organizationScope ? this.organizationScope.includes('Bulk / Commodities') : false;
      if (!this.hasScopeCommodities) {
        this.commoditiesControl.setValue(false);
      }
      this.getOnInitFilters();
    }
    )
  }


  getOnInitFilters() {
    this.getFilterData()
    this.inditexCategoryList = [{ name: "Above 3 years" }, { name: "Below 3 years" }];
    this.inditexLevelList = [{ name: "A" }, { name: "B" }, { name: "C" }];
    let std = new Date(this.startDate.value);
    let edd = new Date(this.endDate.value);
    this.startDateString = new Date(std.getFullYear(), std.getMonth(), std.getDate() + 1).toISOString().slice(0, 10);
    this.endDateString = new Date(edd.getFullYear(), edd.getMonth(), edd.getDate() + 1).toISOString().slice(0, 10);
    this.filters.push(
      {
        "member": "Formulas.scan_date",
        "operator": "inDateRange",
        "values": [
          this.startDateString,
          this.endDateString
        ]
      }

    )
    //if commodities filter is false then basic chemical are excluded from total number
    //else get all chemicals (basic and not-basic)
    if (!this.hasScopeCommodities && this.commoditiesControl.value != undefined) {
      let commodities_value = this.commoditiesControl.value == true ? ['0', '1'] : ['0'];
      this.filters.push({
        "member": 'Formulas.basic_chemical',
        "operator": 'equals',
        "values": commodities_value,

      });
    }
    this.onTabChanged()
  }

  getFilterData() {
    // const productQuery = {
    //   "measures": [
    //   ],
    //   "timeDimensions": [
    //   ],
    //   "order": {
    //   },
    //   "filters": [
    //     {
    //       "member": "OrganizationProductType.factory_id",
    //       "operator": "equals",
    //       "values": [this.organizationId.toString()],
    //     }
    //   ],
    //   "dimensions": [
    //     "OrganizationProductType.productType"
    //   ]
    // }
    // this.cubejsService.getCubeJSData(productQuery)
    //   .then(data => {
    //     let tempData = data['loadResponse']['results'][0]['data'];
    //     tempData = this.changeKey("OrganizationProductType.productType", "name", tempData)
    //     this.productTypeFilter = tempData;
    //   })
    const usedForQuery = {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
        {
          "member": "Formulas.organization_id",
          "operator": "equals",
          "values": [this.organizationId.toString()],
        }
      ],
      "dimensions": [
        "UsedFor.brand_name"
      ]
    }
    this.cubejsService.getCubeJSData(usedForQuery)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("UsedFor.brand_name", "name", tempData)
        this.usedForFilter = tempData.filter(elements => elements.name != null);;
      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })

    const factoryUsageQuery = {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
      ],
      "dimensions": [
        "Formulas.factory_usage"
      ]
    }
    this.cubejsService.getCubeJSData(factoryUsageQuery)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.factory_usage", "name", tempData)
        this.factoryUsage = tempData.filter(elements => elements.name != null);

      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })
    const activityTypeQuery = {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
      ],
      "dimensions": [
        "Factories.activity"
      ]
    }
    this.cubejsService.getCubeJSData(activityTypeQuery)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Factories.activity", "name", tempData)
        this.activityType = tempData.filter(elements => elements.name != null);

      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })
  }

  selectedTabChanged($event) {
    this.tabIndex = $event.index;
    this.onTabChanged()
  }

  onTabChanged() {
    this.isLoading = true;
    this.toastService.info(
      `Great things take time - we’re currently processing your data.
      Please do not exit or refresh this page.`
    );
    switch (this.tabIndex) {
      case 0: {
        break;
      }
      case 1: {
        this.getCompliancePerformance()
        this.getConsumptionPerformance()
        // this.getCategoryPerformanceCount()
        // this.getCategoryPerformanceWeight()
        this.getFormulasFirst()
        break;
      }
      case 2: {
        // this.getActivityMonitoring();
        this.getconnectedPartnersCount();
        this.getCompletedInventory();
        this.getLastScanDate();
        this.getWasteWater();
        this.getLastVerfHigg();
        this.getSelfHigg();
        this.getLastGeneratedIncheck();
        this.getTopChemicals()
        this.getTopManufacturers()
        this.getTopUnverifiedChemicals()
        this.getTopUnverifiedManufacturers()
        this.getTopVerifiedChemicals()
        this.getChemicalInformation()

        break;
      }
      case 3: {
        this.getRowData();
        break;
      }
      default: {

      }
    }
  }


  changeEndDate(event: MatDatepickerInputEvent<Date>, type) {
    if (event.value !== null) {
      if (type == 'Activity')  {
        let std = new Date(this.startDateActivity.value);
        let edd = new Date(this.endDateActivity.value);
        this.startDateStringActivity = new Date(std.getFullYear(), std.getMonth(), std.getDate() + 1).toISOString().slice(0, 10);
        this.endDateStringActivity = new Date(edd.getFullYear(), edd.getMonth(), edd.getDate() + 1).toISOString().slice(0, 10);
      }
      else {
        let std = new Date(this.startDate.value);
        let edd = new Date(this.endDate.value);
        this.startDateString = new Date(std.getFullYear(), std.getMonth(), std.getDate() + 1).toISOString().slice(0, 10);
        this.endDateString = new Date(edd.getFullYear(), edd.getMonth(), edd.getDate() + 1).toISOString().slice(0, 10);
        this.filters = this.filters.filter((el) => { return el['member'] !== "Formulas.scan_date" && el['member'] !== "Formulas.basic_chemical" })
        // this.filters = this.filters.filter((el) => { el['member'] !== "Formulas.basic_chemical" })
        this.filters.push(
          {
            "member": "Formulas.scan_date",
            "operator": "inDateRange",
            "values": [
              this.startDateString,
              this.endDateString
            ]
          }
        )
        if (!this.hasScopeCommodities && this.commoditiesControl.value != undefined) {
          let commodities_value = this.commoditiesControl.value == true ? ['0', '1'] : ['0'];
          this.filters.push({
            "member": 'Formulas.basic_chemical',
            "operator": 'equals',
            "values": commodities_value,

          });
        }
      }
      this.onTabChanged()
    }
  }

  getCompliancePerformance() {
    this.isLoadingTimeSeriesCompliance = true;
    let measure = "Formulas.brand_compliant_pct";
    if ((this.inditexCategoryControl.value && this.inditexCategoryControl.value.length !== 0)
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        measure = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_pct"
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        measure = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_pct"
      }
    }
    const query = {
      "measures": [
        measure
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date",
          "granularity": "month",
          "dateRange": [
            this.startDateString,
            this.endDateString
          ]
        }
      ],
      "order": {
      },
      "filters": this.filters,
      "dimensions": [
        "Formulas.year_month_scan_date"
      ]
    }
    this.cubejsService.getCubeJSData(query, null, null, true, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.year_month_scan_date", "name", tempData)
        tempData = this.changeKey(measure, "value", tempData)
        this.rawDataSeriesByCount = tempData.map(obj => {
          return { date: obj.name, compliance: obj.value };
        })
        this.timeSeriesCompliance = tempData;
        this.timeSeriesCompliance = [{ "name": "", "series": this.timeSeriesCompliance }];
        this.isLoadingTimeSeriesCompliance = false;
      })
      .catch(error => {
        this.isLoadingTimeSeriesCompliance = false;
        this.ShowErrorMessage(error.message);
      })
  }


  getConsumptionPerformance() {
    this.isLoadingTimeSeriesConsumption = true;
    let measure = "Formulas.brand_compliant_consumption_pct";
    if ((this.inditexCategoryControl.value && this.inditexCategoryControl.value.length !== 0)
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        measure = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption_pct"
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        measure = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption_pct"
      }
    }
    const query = {
      "measures": [
        measure
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date",
          "granularity": "month",
          "dateRange": [
            this.startDateString,
            this.endDateString
          ]
        }
      ],
      "order": {
      },
      "filters": this.filters,
      "dimensions": [
        "Formulas.year_month_scan_date"
      ]
    }
    this.cubejsService.getCubeJSData(query, null, null, true, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.year_month_scan_date", "name", tempData)
        tempData = this.changeKey(measure, "value", tempData)
        this.rawDataSeriesByWeight = tempData.map(obj => {
          return { date: obj.name, compliance: obj.value };
        })
        this.timeSeriesConsumption = tempData;
        this.timeSeriesConsumption = [{ "name": "", "series": this.timeSeriesConsumption }];
        this.isLoadingTimeSeriesConsumption = false;
      })
      .catch(error => {
        this.isLoadingTimeSeriesConsumption = false;
        this.ShowErrorMessage(error.message);
      })
  }

  // timeSeriesCategoryByCount: Array<Object> = [];
  // rawDataCategoryByCount: Array<Object>;
  // isLoadingTimeSeriesCategoryByCount = false;
  // getCategoryPerformanceCount() {
  //   this.isLoadingTimeSeriesCategoryByCount = true;
  //   let measure = "Formulas.factory_compliant_pct";
  //   if ((this.inditexCategoryControl.value && this.inditexCategoryControl.value.length !== 0)
  //     && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
  //     if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
  //       measure = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_pct"
  //     }
  //     if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
  //       measure = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_pct"
  //     }
  //   }
  //   const query = {
  //     "measures": [
  //       measure
  //     ],
  //     "timeDimensions": [
  //       {
  //         "dimension": "Formulas.scan_date",
  //         "granularity": "month",
  //         "dateRange": [
  //           this.startDateString,
  //           this.endDateString
  //         ]
  //       }
  //     ],
  //     "order": [
  //       [
  //         "OrganizationProductType.productType",
  //         "asc"
  //       ]
  //     ],
  //     "filters": this.filters,
  //     "dimensions": [
  //       "Formulas.year_month_scan_date",
  //       "OrganizationProductType.productType"
  //     ]
  //   }
  //   this.cubejsService.getCubeJSData(query, null, null, true, false)
  //     .then((data) => {
  //       let tempData = data['loadResponse']['results'][0]['data'];
  //       tempData = this.changeKey("Formulas.year_month_scan_date", "name", tempData)
  //       tempData = this.changeKey(measure, "value", tempData)
  //       tempData = this.changeKey("OrganizationProductType.productType", "productType", tempData)
  //       this.rawDataCategoryByCount = tempData.map(obj => {
  //         return { productType: obj.productType, date: obj.name, compliance: obj.value };
  //       })
  //       let test: Array<Object> = [];
  //       let uniqueObjArray = [
  //         ...new Map(tempData.map((item) => [item["productType"], item])).values(),
  //       ];
  //       uniqueObjArray.forEach((el) => {
  //         test.push({
  //           "name": el["productType"] === null || el["productType"] === "" ? "None specified" : el["productType"],
  //           "series": tempData.filter((s) => s["productType"] === el["productType"])
  //         })

  //       })
  //       this.timeSeriesCategoryByCount = test;
  //       this.isLoadingTimeSeriesCategoryByCount = false;
  //     })
  // }

  // timeSeriesCategoryByWeight: Array<Object> = [];
  // rawDataCategoryByWeight: Array<Object>;
  // isLoadingTimeSeriesCategoryByWeight = false;
  // getCategoryPerformanceWeight() {
  //   this.isLoadingTimeSeriesCategoryByWeight = true;
  //   let measure = "Formulas.brand_compliant_consumption_pct";
  //   if ((this.inditexCategoryControl.value && this.inditexCategoryControl.value.length !== 0)
  //     && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
  //     if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
  //       measure = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption_pct"
  //     }
  //     if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
  //       measure = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption_pct"
  //     }
  //   }
  //   const query = {
  //     "measures": [
  //       measure
  //     ],
  //     "timeDimensions": [
  //       {
  //         "dimension": "Formulas.scan_date",
  //         "granularity": "month",
  //         "dateRange": [
  //           this.startDateString,
  //           this.endDateString
  //         ]
  //       }
  //     ],
  //     "order": [
  //       [
  //         "OrganizationProductType.productType",
  //         "asc"
  //       ]
  //     ],
  //     "filters": this.filters,
  //     "dimensions": [
  //       "Formulas.year_month_scan_date",
  //       "OrganizationProductType.productType"
  //     ]
  //   }
  //   this.cubejsService.getCubeJSData(query, null, null, true, false)
  //     .then((data) => {
  //       let tempData = data['loadResponse']['results'][0]['data'];
  //       tempData = this.changeKey("Formulas.year_month_scan_date", "name", tempData)
  //       tempData = this.changeKey(measure, "value", tempData)
  //       tempData = this.changeKey("OrganizationProductType.productType", "productType", tempData)
  //       this.rawDataCategoryByWeight = tempData.map(obj => {
  //         return { productType: obj.productType, date: obj.name, compliance: obj.value };
  //       })
  //       let newSeries: Array<Object> = [];
  //       let uniqueObjArray = [
  //         ...new Map(tempData.map((item) => [item["productType"], item])).values(),
  //       ];
  //       uniqueObjArray.forEach((el) => {
  //         newSeries.push({
  //           "name": el["productType"] === null || el["productType"] === "" ? "None specified" : el["productType"],
  //           "series": tempData.filter((s) => s["productType"] === el["productType"])
  //         })

  //       })
  //       this.timeSeriesCategoryByWeight = newSeries;
  //       this.isLoadingTimeSeriesCategoryByWeight = false;
  //     })
  // }

  changeKey(originalKey: string, newKey: string, arr: Array<Object>) {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      var obj = arr[i];
      obj[newKey] = (newKey == "value" && obj[originalKey] === null) ? 0 : obj[originalKey];
      delete (obj[originalKey]);
      newArr.push(obj);
    }
    return newArr;
  }

  ShowErrorMessage(msg) {
    this.isLoading = false;
    this.isLoading = false;
    let customMSg = false;
    if (msg.includes("No id found in Security Context")) {
      msg = 'No factories are connected to this account, so no data can be displayed.\n Ask your factories to connect to you to see their data.',
        customMSg = true;
    }
    if (msg.includes("Network request failed")) {
      this.isCubejsConnected = false;
      throw new Error("CubeJS is not reachable!")
      // return false;
    }
    if (!this.errorList.includes(msg)) {
      this.errorList.push(msg);
      this.dialog.open(MessageDialogComponent, {
        hasBackdrop: true,
        width: "32rem",
        disableClose: true,
        data: {
          message: msg,
          hasLink: customMSg ? true : false,
          goToLink: customMSg ? "How to share inventories" : "",
          URL: customMSg ? "https://cloud.goblu.net/s/4LXyFHrE86Tzx2A" : ""
        },
      });
      // alert(msg);
    }
  }

  activityInfo = [
    "brand", "supplierID", "zdhc_AID", "contact", "connection_type", "chemicalCount", "complianceBrand"
  ]
  activityInfoDataList = new MatTableDataSource<any>()
  isLoadingActivityInfo = false;

  getActivityMonitoring() {
    this.isLoadingActivityInfo = true;
    const query = {
      "dimensions": [
        // "SupplierIdentification.supplier_identification",
        // "Brands.brand_name",
        "BrandSuppliers.connection_type",
        "Activity.contact_person",
        // "ActualConnections.compliance_name"

      ],
      "timeDimensions": [
      ],
      "measures": [
        "Activity.last_month_chemical_count",
        "SupplierIdentification.supplier_identification",
        "SupplierIdentification.zdhc_aid",

      ],
      "filters": [
        {
          "member": "SupplierIdentification.factory_id",
          "operator": "equals",
          "values": [
            this.organizationId.toString()
          ]
        }

      ],
      "order": [

      ]
    }
    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Activity.organization_name", "brand", tempData)
        tempData = this.changeKey("SupplierIdentification.supplier_identification", "supplierID", tempData)
        tempData = this.changeKey("SupplierIdentification.zdhc_aid", "zdhc_AID", tempData)
        tempData = this.changeKey("Activity.contact_person", "contact", tempData)
        tempData = this.changeKey("BrandSuppliers.connection_type", "connectionType", tempData)
        tempData = this.changeKey("Activity.last_month_chemical_count", "lastScanMonthChemCount", tempData)
        // var seen = {};//merge factories that are nomianted and connected at the same time
        // let dblData = tempData.filter(function (entry) {
        //   var previous;

        //   // Have we seen this label before?
        //   if (seen.hasOwnProperty(entry.factoryName)) {
        //     // Yes, grab it and add this data to it
        //     previous = seen[entry.factoryName];
        //     previous.connectionType = previous.connectionType.concat('/', entry.connectionType);

        //     // Don't keep this entry, we've merged it into the previous one
        //     return false;
        //   }

        //   // Remember that we've seen it
        //   seen[entry.factoryName] = entry;

        //   // Keep this one, we'll merge any others that match into it
        //   return true;
        // });
        // dblData = dblData.map(obj => {
        //   if (obj.gatewaySubscription == 2)
        //     obj.gatewaySubscription = 'Yes';
        //   else
        //     obj.gatewaySubscription = 'No';

        //   if (obj.completedScan == 1)
        //     obj.completedScan = 'Complete';
        //   else
        //     obj.completedScan = 'Incomplete';

        //   if (obj.organizationType == 2)
        //     obj.organizationType = 'Supplier';
        //   else if (obj.organizationType == 4)
        //     obj.organizationType = 'Association';
        //   else
        //     obj.organizationType = 'Monitoring';

        //   if (obj.wasteWaterResultHide == 1)
        //     obj.wasteWaterCount = "NA"
        //   else
        //     obj.wasteWaterCount = obj.wasteWaterCount == 0 ? "No" : "Yes";

        //   return obj;
        // })
        this.activityInfoDataList.data = tempData;
        this.activityInfoDataList.paginator = this.activityInfoMatPaginator;
        this.activityInfoDataList.sort = this.activityInfoMatSort;
        this.isLoadingActivityInfo = false;
      })

  }

  // #region Chemical Information
  topChemicals = ["chemicalName", "manufacturer", "usage"]
  topChemicalsDataList = new MatTableDataSource<any>()
  isLoadingTopChemicals = false;

  topVerifiedChemicals = ["chemicalName", "manufacturer", "usage"]
  topVerifiedChemicalsDataList = new MatTableDataSource<any>()
  isLoadingTopVerifiedChemicals = false;

  topManufacturers = ["manufacturer", "chemicalCount", "usage"]
  topManufacturersDataList = new MatTableDataSource<any>()
  isLoadingTopManufacturers = false;

  topUnverifiedChemicals = ["chemicalName", "manufacturer", "usage"]
  topUnverifiedChemicalsDataList = new MatTableDataSource<any>()
  isLoadingTopUnverifiedChemicals = false;

  topUnverifiedManufacturers = ["manufacturer", "usage"]
  topUnverifiedManufacturersDataList = new MatTableDataSource<any>()
  isLoadingTopUnverifiedManufacturers = false;

  chartDataByCount: Array<Object> = [];
  isLoadingChartDataByCount = false;
  chartDataByWeight: Array<Object> = [];
  isLoadingChartDataByWeight = false;

  latestFormulas = []
  getFormulasFirst() {
    let query = {
      "dimensions": [
        "Formulas.organization_id",
        "Formulas.chemical_identifier"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "order": [
        [
          "Formulas.chemical_identifier",
          "asc"
        ]
      ],
      "filters": [
        {
          "member": "Formulas.scan_date",
          "operator": "inDateRange",
          "values": [
            this.startDateString,
            this.endDateString
          ]
        }
      ],
      "measures": [
        "Formulas.latest_formula_in_range"
      ]
    };
    this.cubejsService.getCubeJSData(query, null, null, true, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.latest_formula_in_range", "latest_formula", tempData);

        this.latestFormulas = tempData.map(function (el) { return el.latest_formula; });
        this.getSCPerfomanceByCount()
        this.getSCPerfomanceByWeight()
      })
  }

  nonInditexFilter() {
    let inditexFilters: Array<Object> = [];
    if (this.inditexCategoryControl.value
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        inditexFilters.push(
          {
            "member": "Formulas.inditex_AboveThreeYears",
            "operator": "notContains",
            "values": this.inditexLevelControl.value.map(obj => obj.name)
          }
        )
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        inditexFilters.push(
          {
            "member": "Formulas.inditex_BelowThreeYears",
            "operator": "notContains",
            "values": this.inditexLevelControl.value.map(obj => obj.name)
          }
        )
      }
    }
    return inditexFilters;
  }

  getTopChemicals() {
    this.isLoadingTopChemicals = true;
    let formulaFilters = [];
    for (let obj of this.latestFormulas) {
      formulaFilters.push(obj.toString());
    }
    const query = {
      "dimensions": [
        "Formulas.chemical_identifier",
        "Formulas.chemical_name",
        "Formulas.chemical_manufacturer",
        // "Formulas.is_gateway_manufacturer"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "measures": [
        "Formulas.total_consumption",
        "Formulas.gateway_manufacture_count"
      ],
      "filters": [
        {
          "and": formulaFilters.length == 0 ? [
            ...this.filters,

          ] : [
            ...this.filters,
            {
              "member": 'Formulas.id',
              "operator": 'equals',
              "values": formulaFilters
            }


          ]
        }
      ],
      "order": [
        [
          "Formulas.total_consumption",
          "desc"
        ]
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.chemical_name", "chemicalName", tempData)
        tempData = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", tempData)
        tempData = this.changeKey("Formulas.total_consumption", "usage", tempData)
        tempData = this.changeKey("Formulas.gateway_manufacture_count", "isGateway", tempData)
        tempData = tempData.map(obj => {
          if (obj.isGateway > 0) {
            tempData.filter(el => el.manufacturer == obj.manufacturer).forEach(i => i.isGateway = 1)
          }
          else
            obj.isGateway = 0;

          return obj;
        });
        tempData = tempData.filter(obj => {
          delete obj["Formulas.chemical_identifier"];
          return true;
        })
        tempData = tempData.map(obj => {
          if (obj.isGateway > 0) {
            tempData.filter(el => el.manufacturer == obj.manufacturer).forEach(i => i.isGateway = 1)
          }
          else
            obj.isGateway = 0;

          return obj;
        });
        this.topChemicalsDataList.data = tempData;
        this.topChemicalsDataList.paginator = this.topChemicalsMatPaginator;
        this.topChemicalsDataList.sort = this.topChemicalsMatSort;
        this.isLoadingTopChemicals = false;
      })
  }

  getTopManufacturers() {
    this.isLoadingTopManufacturers = true;
    const query = {
      "dimensions": [
        "Formulas.chemical_manufacturer",
        // "Formulas.is_gateway_manufacturer"
        // "Formulas.zdhc_manufacturer_partner"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "measures": [
        "Formulas.formula_distinct_count",
        "Formulas.total_consumption",
        "Formulas.gateway_manufacture_count"
      ],
      "filters": [...this.filters,
        // ...this.inditexFilter()
      ],
      "order": [
        [
          "Formulas.formula_distinct_count",
          "desc"
        ]
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", tempData)
        // tempData = this.changeKey("Formulas.zdhc_manufacturer_partner", "partner", tempData)
        tempData = this.changeKey("Formulas.formula_distinct_count", "chemicalCount", tempData)
        tempData = this.changeKey("Formulas.total_consumption", "usage", tempData)
        tempData = this.changeKey("Formulas.gateway_manufacture_count", "isGateway", tempData)
        tempData = tempData.map(obj => {
          if (obj.isGateway > 0) {
            tempData.filter(el => el.manufacturer == obj.manufacturer).forEach(i => i.isGateway = 1)
          }
          else
            obj.isGateway = 0;

          return obj;
        });
        tempData = tempData.map(obj => {
          if (obj.isGateway > 0) {
            tempData.filter(el => el.manufacturer == obj.manufacturer).forEach(i => i.isGateway = 1)
          }
          else
            obj.isGateway = 0;

          return obj;
        });
        this.topManufacturersDataList.data = tempData;
        this.topManufacturersDataList.paginator = this.topManufacturersMatPaginator;
        this.topManufacturersDataList.sort = this.topManufacturersMatSort;
        this.isLoadingTopManufacturers = false;
      })
  }

  getTopUnverifiedChemicals() {
    this.isLoadingTopUnverifiedChemicals = true;
    if (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0 && this.inditexLevelControl.value.map(obj => obj.name).includes("A" || "B")) {
      this.topUnverifiedChemicalsDataList.data = [];
      this.topUnverifiedChemicalsDataList.paginator = this.topUnverifiedChemicalsMatPaginator;
      this.topUnverifiedChemicalsDataList.sort = this.topUnverifiedChemicalsMatSort;
      this.isLoadingTopUnverifiedChemicals = false;
    }
    else {
      let formulaFilters = [];
      for (let obj of this.latestFormulas) {
        formulaFilters.push(obj.toString());
      }
      let segment = [];
      let excludeChemicals = this.includeInditexFilter();
      if (excludeChemicals.length === 0)
        segment = ["Formulas.non_compliant_with_commodities_cr"];
      const query = {
        "dimensions": [
          "Formulas.chemical_identifier",
          "Formulas.chemical_name",
          "Formulas.chemical_manufacturer",
          // "Formulas.is_gateway_manufacturer"
        ],
        "timeDimensions": [
          {
            "dimension": "Formulas.scan_date"
          }
        ],
        "measures": [
          "Formulas.total_consumption",
          "Formulas.gateway_manufacture_count"
        ],
        "segments":
          segment
        ,
        "filters": [
          {
            "and": formulaFilters.length == 0 ? [
              ...this.filters,
              ...excludeChemicals

            ] : [
              ...this.filters,
              ...excludeChemicals,
              {
                "member": 'Formulas.id',
                "operator": 'equals',
                "values": formulaFilters
              }
            ]
          }
        ],
        "order": [
          [
            "Formulas.total_consumption",
            "desc"
          ]
        ]
      }

      this.cubejsService.getCubeJSData(query, null, null, true, false)
        .then(data => {
          let tempData = data['loadResponse']['results'][0]['data'];
          tempData = this.changeKey("Formulas.chemical_name", "chemicalName", tempData)
          tempData = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", tempData)
          tempData = this.changeKey("Formulas.total_consumption", "usage", tempData)
          tempData = this.changeKey("Formulas.gateway_manufacture_count", "isGateway", tempData)
          tempData = tempData.map(obj => {
            if (obj.isGateway > 0) {
              tempData.filter(el => el.manufacturer == obj.manufacturer).forEach(i => i.isGateway = 1)
            }
            else
              obj.isGateway = 0;

            return obj;
          });
          tempData = tempData.filter(obj => {
            delete obj["Formulas.chemical_identifier"];
            return true;
          })
          tempData = tempData.map(obj => {
            if (obj.isGateway > 0) {
              tempData.filter(el => el.manufacturer == obj.manufacturer).forEach(i => i.isGateway = 1)
            }
            else
              obj.isGateway = 0;

            return obj;
          });
          this.topUnverifiedChemicalsDataList.data = tempData;
          this.topUnverifiedChemicalsDataList.paginator = this.topUnverifiedChemicalsMatPaginator;
          this.topUnverifiedChemicalsDataList.sort = this.topUnverifiedChemicalsMatSort;
          this.isLoadingTopUnverifiedChemicals = false;
        })
    }
  }

  getTopUnverifiedManufacturers() {
    this.isLoadingTopUnverifiedManufacturers = true;
    let segment = [];
    if (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0 && this.inditexLevelControl.value.map(obj => obj.name).includes("A" || "B")) {
      this.topUnverifiedManufacturersDataList.data = [];
      this.topUnverifiedManufacturersDataList.paginator = this.topUnverifiedManufacturersMatPaginator;
      this.topUnverifiedManufacturersDataList.sort = this.topUnverifiedManufacturersMatSort;
      this.isLoadingTopUnverifiedManufacturers = false;
    }
    else {
      let includeChemicals = this.includeInditexFilter();
      if (includeChemicals.length === 0)
        segment = ["Formulas.non_compliant_with_commodities_cr"];
      const query = {
        "dimensions": [
          "Formulas.chemical_manufacturer",
          // "Formulas.zdhc_manufacturer_partner",
          "Formulas.manufacturer_identifier",
          // "Formulas.is_gateway_manufacturer"
        ],
        "timeDimensions": [
          {
            "dimension": "Formulas.scan_date"
          }
        ],
        "measures": [
          "Formulas.total_consumption",
          "Formulas.gateway_manufacture_count"
        ],
        "segments":
          segment
        ,
        "filters": [...this.filters,
        ...includeChemicals],
        "order": [
          [
            "Formulas.total_consumption",
            "desc"
          ]
        ]
      }

      this.cubejsService.getCubeJSData(query, null, null, true, false)
        .then(data => {
          let tempData = data['loadResponse']['results'][0]['data'];
          tempData = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", tempData)
          // tempData = this.changeKey("Formulas.zdhc_manufacturer_partner", "partner", tempData)
          tempData = this.changeKey("Formulas.total_consumption", "usage", tempData)
          tempData = this.changeKey("Formulas.gateway_manufacture_count", "isGateway", tempData)
          tempData = tempData.map(obj => {
            if (obj.isGateway > 0) {
              tempData.filter(el => el.manufacturer == obj.manufacturer).forEach(i => i.isGateway = 1)
            }
            else
              obj.isGateway = 0;

            return obj;
          });
          this.topUnverifiedManufacturersDataList.data = tempData;
          this.topUnverifiedManufacturersDataList.paginator = this.topUnverifiedManufacturersMatPaginator;
          this.topUnverifiedManufacturersDataList.sort = this.topUnverifiedManufacturersMatSort;
          this.isLoadingTopUnverifiedManufacturers = false;
        })
    }
  }

  // #region Factory Performance
  chemicalInfo = ["chemicalName", "manufacturer", "consumption", "complianceStatus", "factoryUsage"]
  chemicalInfoDataList = new MatTableDataSource<any>()
  isLoadingChemicalInfo = false;
  includeInditexFilter() {
    let inditexFilters: Array<Object> = [];
    if (this.inditexCategoryControl.value
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        inditexFilters.push(
          {
            "member": "Formulas.inditex_AboveThreeYears",
            "operator": "contains",
            "values": this.inditexLevelControl.value.map(obj => obj.name)
          }
        )
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        inditexFilters.push(
          {
            "member": "Formulas.inditex_BelowThreeYears",
            "operator": "contains",
            "values": this.inditexLevelControl.value.map(obj => obj.name)
          }
        )
      }
    }
    return inditexFilters;
  }

  getTopVerifiedChemicals() {
    this.isLoadingTopVerifiedChemicals = true;
    if (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0 && this.inditexLevelControl.value.map(obj => obj.name).includes("C")) {
      this.topVerifiedChemicalsDataList.data = [];
      this.topVerifiedChemicalsDataList.paginator = this.topVerifiedChemicalsMatPaginator;
      this.topVerifiedChemicalsDataList.sort = this.topVerifiedChemicalsMatSort;
      this.isLoadingTopVerifiedChemicals = false;
    }
    else {
      let formulaFilters = [];
      for (let obj of this.latestFormulas) {
        formulaFilters.push(obj.toString());
      }
      let segment = [];
      let excludeChemicals = this.includeInditexFilter();
      if (excludeChemicals.length === 0)
        segment = ["Formulas.compliant_cr"];
      const query = {
        "dimensions": [
          "Formulas.chemical_identifier",
          "Formulas.chemical_name",
          "Formulas.chemical_manufacturer",
          // "Formulas.is_gateway_manufacturer"
        ],
        "timeDimensions": [
          {
            "dimension": "Formulas.scan_date"
          }
        ],
        "measures": [
          "Formulas.total_consumption",
          "Formulas.gateway_manufacture_count"
        ],
        "segments":
          segment
        ,
        "filters": [
          {
            "and": formulaFilters.length == 0 ? [
              ...this.filters,
              ...excludeChemicals

            ] : [
              ...this.filters,
              ...excludeChemicals,
              {
                "member": 'Formulas.id',
                "operator": 'equals',
                "values": formulaFilters
              }
            ]
          }
        ],
        "order": [
          [
            "Formulas.total_consumption",
            "desc"
          ]
        ]
      }

      this.cubejsService.getCubeJSData(query, null, null, true, false)
        .then(data => {
          let tempData = data['loadResponse']['results'][0]['data'];
          tempData = this.changeKey("Formulas.chemical_name", "chemicalName", tempData)
          tempData = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", tempData)
          tempData = this.changeKey("Formulas.total_consumption", "usage", tempData)
          tempData = this.changeKey("Formulas.gateway_manufacture_count", "isGateway", tempData)
          tempData = tempData.map(obj => {
            if (obj.isGateway > 0) {
              tempData.filter(el => el.manufacturer == obj.manufacturer).forEach(i => i.isGateway = 1)
            }
            else
              obj.isGateway = 0;

            return obj;
          });
          tempData = tempData.filter(obj => {
            delete obj["Formulas.chemical_identifier"];
            return true;
          })
          tempData = tempData.map(obj => {
            if (obj.isGateway > 0) {
              tempData.filter(el => el.manufacturer == obj.manufacturer).forEach(i => i.isGateway = 1)
            }
            else
              obj.isGateway = 0;

            return obj;
          });
          this.topVerifiedChemicalsDataList.data = tempData;
          this.topVerifiedChemicalsDataList.paginator = this.topVerifiedChemicalsMatPaginator;
          this.topVerifiedChemicalsDataList.sort = this.topVerifiedChemicalsMatSort;
          this.isLoadingTopVerifiedChemicals = false;
        })
    }
  }

  getSCPerfomanceByCount() {
    this.isLoadingChartDataByCount = true;
    let formulaFilters = [];
    for (let obj of this.latestFormulas) {
      formulaFilters.push(obj.toString());
    }
    let measureComplianceCount = "Formulas.factory_compliance_count";
    if (this.inditexCategoryControl.value
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        measureComplianceCount = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_total_cnt"
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        measureComplianceCount = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_total_cnt"
      }
    }
    const query = {
      "measures": [
        "Formulas.formula_distinct_count",
        measureComplianceCount
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "filters": [
        {
          "and": formulaFilters.length == 0 ? [
            ...this.filters,

          ] : [
            ...this.filters,
            {
              "member": 'Formulas.id',
              "operator": 'equals',
              "values": formulaFilters
            }
          ]
        }
      ],
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey(measureComplianceCount, "complianceCount", tempData);
        tempData = this.changeKey("Formulas.formula_distinct_count", "totalCount", tempData);
        this.chartDataByCount = [
          { 'name': 'Verified', 'value': tempData[0].complianceCount },
          { 'name': 'Unverified', 'value': tempData[0].totalCount - tempData[0].complianceCount }
        ];
        this.isLoadingChartDataByCount = false;
      })
  }

  getSCPerfomanceByWeight() {
    this.isLoadingChartDataByWeight = true;
    let measureConsumption = "Formulas.brand_compliance_consumption";
    if (this.inditexCategoryControl.value
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        measureConsumption = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption"
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        measureConsumption = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption"
      }
    }
    let formulaFilters = [];
    for (let obj of this.latestFormulas) {
      formulaFilters.push(obj.toString());
    }
    const query = {
      "measures": [
        "Formulas.total_consumption",
        measureConsumption
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "filters": [
        {
          "and": formulaFilters.length == 0 ? [
            ...this.filters,

          ] : [
            ...this.filters,
            {
              "member": 'Formulas.id',
              "operator": 'equals',
              "values": formulaFilters
            }
          ]
        }
      ],
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData.forEach(obj => {
          if (obj['Formulas.brand_compliance_consumption'] == null) {
            obj['Formulas.brand_compliance_consumption'] = 0;
          }
        })
        tempData = this.changeKey(measureConsumption, "complianceConsumption", tempData);
        tempData = this.changeKey("Formulas.total_consumption", "totalConsumption", tempData);
        this.chartDataByWeight = [
          { 'name': 'Verified', 'value': tempData[0].complianceConsumption },
          { 'name': 'Unverified', 'value': tempData[0].totalConsumption - tempData[0].complianceConsumption }
        ];
        this.isLoadingChartDataByWeight = false;
      })
  }

  connectedPartnersCount: Number;
  isLoadingconnectedPartnersCount = false;
  getconnectedPartnersCount() {
    this.isLoadingconnectedPartnersCount = true;
    const query = {
      "measures": [
        "ActualConnections.total_count"
      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [],
      "filters": []
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.connectedPartnersCount = data[0]['ActualConnections.total_count']
      this.isLoadingconnectedPartnersCount = false;
    })
      .catch(error => {
        this.isLoadingconnectedPartnersCount = false;
        this.ShowErrorMessage(error.message);
      })
  }

  completedInventory: String;
  completedInventoryNumber: Number;
  isLoadingCompletedInventory = false;
  getCompletedInventory() {
    this.isLoadingCompletedInventory = true;
    // const yearMonth = '' + new Date(this.endDateString).getFullYear() + new Date(this.endDateString).getMonth();
    // const userId = this.globalsService.profile["id"];
    // this.http.post<any[]>(this.globalsService.baseHref + '/checkConfirmationOfInventories', { 'yearMonth': yearMonth, 'userId': userId }).subscribe(
    //   (response) => {
    //     if (response['inventoryStatus'] === 'true')
    //     this.completedInventory = 'Yes';
    //     else 
    //     this.completedInventory = 'No';
    //   },
    //   (err) => {
    //     this.completedInventory = 'No';
    //     console.log(err);
    //     this.isLoading = false;
    //   });
    const query = {
      "measures": [
        "Formulas.confirmation_of_completeness_cnt"
      ],
      "timeDimensions": [
      ],
      "order": {},
      "filters": [
        {
          "member": 'Formulas.is_latest_scan',
          "operator": 'equals',
          "values": ['1']

        }

      ],
      "dimensions": []
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.completedInventoryNumber = data[0]['Formulas.confirmation_of_completeness_cnt']
      if (this.completedInventoryNumber == 1)
        this.completedInventory = 'Yes'
      else this.completedInventory = 'No'
      this.isLoadingCompletedInventory = false;
    })
      .catch(error => {
        this.isLoadingCompletedInventory = false;
        this.ShowErrorMessage(error.message);
      })
  }

  lastScanDate: Number;
  isLoadingLastScanDate = false;
  getLastScanDate() {
    this.isLoadingLastScanDate = true;
    const query = {
      "measures": [

      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [
        "Formulas.last_scan_month"
      ],
      "filters": [
      ],
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.lastScanDate = data[0]['Formulas.last_scan_month']
      this.isLoadingLastScanDate = false;
    })
      .catch(error => {
        this.isLoadingLastScanDate = false;
        this.ShowErrorMessage(error.message);
      })
  }

  lastWasteWater: Number;
  isLoadingWasteWater = false;
  getWasteWater() {
    this.isLoadingWasteWater = true;
    const query = {
      "measures": [],
      "timeDimensions": [],
      "order": {},
      "dimensions": ["WasteWater.test_result"],
      "filters": [
      ],
    }
    this.cubejsService.getCubeJSData(query, null, null, true, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.lastWasteWater = data[0]['WasteWater.test_result']
      this.isLoadingWasteWater = false;
    })
      .catch(error => {
        this.isLoadingWasteWater = false;
        this.ShowErrorMessage(error.message);
      })
  }

  lastVerHigg: String;
  isLoadingLastVerfHigg = false;
  getLastVerfHigg() {
    this.isLoadingLastVerfHigg = true;
    const query = {
      "measures": ["Higg.latest_verf_total"],
      "timeDimensions": [],
      "order": {},
      "dimensions": [],
      "filters": [],
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.lastVerHigg = data[0]['Higg.latest_verf_total'] != null && data[0]['Higg.latest_verf_total'] <= 0 ? 'NA' : data[0]['Higg.latest_verf_total'] != null && data[0]['Higg.latest_verf_total'] > 0 && data[0]['Higg.latest_verf_total'] <= 25 ? 'Level 1' : data[0]['Higg.latest_verf_total'] != null && data[0]['Higg.latest_verf_total'] > 25 && data[0]['Higg.latest_verf_total'] <= 75 ? 'Level 2 ' : data[0]['Higg.latest_verf_total'] != null && data[0]['Higg.latest_verf_total'] > 75 && data[0]['Higg.latest_verf_total'] <= 100 ? 'Level 3' : '';
      this.isLoadingLastVerfHigg = false;
    })
      .catch(error => {
        this.isLoadingLastVerfHigg = false;
        this.ShowErrorMessage(error.message);
      })
  }

  lastSelfHigg: String;
  isLoadingLastSelfHigg = false;
  getSelfHigg() {
    this.isLoadingLastSelfHigg = true;
    const query = {
      "measures": ["Higg.latest_self_total"
      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [],
      "filters": [
      ],
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.lastSelfHigg = data[0]['Higg.latest_self_total'] != null && data[0]['Higg.latest_self_total'] <= 0 ? 'NA' : data[0]['Higg.latest_self_total'] != null && data[0]['Higg.latest_self_total'] > 0 && data[0]['Higg.latest_self_total'] <= 25 ? 'Level 1' : data[0]['Higg.latest_self_total'] != null && data[0]['Higg.latest_self_total'] > 25 && data[0]['Higg.latest_self_total'] <= 75 ? 'Level 2 ' : data[0]['Higg.latest_self_total'] != null && data[0]['Higg.latest_self_total'] > 75 && data[0]['Higg.latest_self_total'] <= 100 ? 'Level 3' : '';
      this.isLoadingLastSelfHigg = false;
    })
      .catch(error => {
        this.isLoadingLastSelfHigg = false;
        this.ShowErrorMessage(error.message);
      })
  }

  lastGeneratedIncheck: Number;
  isLoadingLastGeneratedIncheck = false;
  getLastGeneratedIncheck() {
    this.isLoadingLastGeneratedIncheck = true;
    const query = {
      "measures": [
        "DocumentCenter.incheck_last_month",

      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [
      ],
      "filters": [
      ],
    }



    this.cubejsService.getCubeJSData(query, null, null, true, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.lastGeneratedIncheck = data[0]['DocumentCenter.incheck_last_month']
      this.isLoadingLastGeneratedIncheck = false;
    })
      .catch(error => {
        this.isLoadingLastGeneratedIncheck = false;
        this.ShowErrorMessage(error.message);
      })
  }

  getChemicalInformation() {
    this.isLoadingChemicalInfo = true;
    let formulaFilters = [];
    for (let obj of this.latestFormulas) {
      formulaFilters.push(obj.toString());
    }
    let excludeChemicals = this.includeInditexFilter();
    const query = {
      "dimensions": [
        "Formulas.chemical_name",
        "Formulas.chemical_manufacturer",
        "Formulas.is_compliant_for_factory",
        "Formulas.factory_usage",
        // "Formulas.is_gateway_manufacturer"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "measures": [
        "Formulas.max_consumption",
        "Formulas.gateway_manufacture_count"
        // "Formulas.max_ending_stock",
      ],
      "filters": [
        {
          "and": formulaFilters.length == 0 ? [
            ...this.filters,
            ...excludeChemicals

          ] : [
            ...this.filters,
            ...excludeChemicals,
            {
              "member": 'Formulas.id',
              "operator": 'equals',
              "values": formulaFilters
            }
          ]
        }
      ],
      "order": [
        [
          "Formulas.is_compliant_for_factory",
          "asc"
        ],
        [
          "Formulas.chemical_name",
          "asc"
        ]
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.chemical_name", "chemicalName", tempData)
        tempData = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", tempData)
        tempData = this.changeKey("Formulas.max_consumption", "consumption", tempData)
        // tempData = this.changeKey("Formulas.max_ending_stock", "endingStock", tempData)
        tempData = this.changeKey("Formulas.is_compliant_for_factory", "complianceStatus", tempData)
        tempData = this.changeKey("Formulas.factory_usage", "factoryUsage", tempData)
        tempData = this.changeKey("Formulas.gateway_manufacture_count", "isGateway", tempData)
        tempData = tempData.map(obj => {
          if (obj.isGateway > 0) {
            tempData.filter(el => el.manufacturer == obj.manufacturer).forEach(i => i.isGateway = 1)
          }
          else
            obj.isGateway = 0;
          if (obj.consumption != 'NA' && obj.consumption != null)
            obj.consumption = Number(parseFloat(obj.consumption).toFixed(2));
          return obj;
        });
        this.chemicalInfoDataList.data = tempData;
        this.chemicalInfoDataList.paginator = this.chemicalInfoMatPaginator;
        this.chemicalInfoDataList.sort = this.chemicalInfoMatSort;
        this.isLoadingChemicalInfo = false;
      })

  }

  rowData = this.isFactoryZdhc ? [
    "organization_name",
    "scan_date",
    "_type",
    "chemical_name",
    "chemical_manufacturer",
    "gateway_name",
    "gateway_manufacturer",
    "gateway_level",
    "is_compliant",
    "application",
    "user_application",
    "category",
    "consumption",
    "stock_volume",
    "delivered_quantity",
    "basic_chemical",
    // "productType",
    "lot_number",
    "cas_nr",
    "notes",
    "use_of_ppe",
    "ghs_msds_available",
    "precautionary_statement",
    "hazard_statement",
    "ghs_classification",
    "stock_location",
    "storage_condition",
    "minimum_stock",
    "maximum_stock",
    "manufacture_date",
    "purchase_date",
    "expiry_date",
    "zdhc_mrsl",
    // "zdhc_grading",
    // "zdhc_mrsl_version",
    "zdhc_mrsl_self_declaration",
    "gots",
    "gots_version",
    "eco_passport",
    "bluesign",
    "bluesign_system_partner",
    "green_screen",
    "green_screen_level",
    "c2c",
    "inditex_AboveThreeYears",
    "inditex_BelowThreeYears",
    "toxfmd",
    "scivera",
    "test_report",
    "testing_facility",
    "svhc"
  ]:
  [
    "organization_name",
    "scan_date",
    "_type",
    "chemical_name",
    "chemical_manufacturer",
    "is_compliant",
    "application",
    "user_application",
    "category",
    "consumption",
    "stock_volume",
    "delivered_quantity",
    "basic_chemical",
    // "productType",
    "lot_number",
    "cas_nr",
    "notes",
    "use_of_ppe",
    "ghs_msds_available",
    "precautionary_statement",
    "hazard_statement",
    "ghs_classification",
    "stock_location",
    "storage_condition",
    "minimum_stock",
    "maximum_stock",
    "manufacture_date",
    "purchase_date",
    "expiry_date",
    "zdhc_mrsl",
    // "zdhc_grading",
    // "zdhc_mrsl_version",
    "zdhc_mrsl_self_declaration",
    "gots",
    "gots_version",
    "eco_passport",
    "bluesign",
    "bluesign_system_partner",
    "green_screen",
    "green_screen_level",
    "c2c",
    "inditex_AboveThreeYears",
    "inditex_BelowThreeYears",
    "toxfmd",
    "scivera",
    "test_report",
    "testing_facility",
    "svhc"
  ]
  rowDataInfoDataList = new MatTableDataSource<any>()
  isLoadingrowDataInfo = false;

  getRowData() {
    this.rowDataFilters =[];
    this.isLoadingrowDataInfo = true;
      let std = new Date(this.startDateActivity.value);
      let edd = new Date(this.endDateActivity.value);
      this.startDateStringActivity = new Date(std.getFullYear(), std.getMonth(), std.getDate() + 1).toISOString().slice(0, 10);
      this.endDateStringActivity = new Date(edd.getFullYear(), edd.getMonth(), edd.getDate() + 1).toISOString().slice(0, 10);
    const query = {
      "dimensions": this.isFactoryZdhc ? [
        "Formulas.organization_name",
        "Formulas.scan_date",
        "Formulas.chemical_name",
        "Formulas.chemical_manufacturer",
        "Formulas.application",
        "Formulas.user_application",
        "Formulas.category",
        "Formulas.delivered_quantity",
        "Formulas.consumption",
        "Formulas.stock_volume",
        "Formulas.basic_chemical",
        // "OrganizationProductType.productType",
        "Formulas.lot_number",
        "Formulas.cas_nr",
        "Formulas.notes",
        "Formulas.use_of_ppe",
        "Formulas.ghs_classification",
        "Formulas.precautionary_statement",
        "Formulas.ghs_msds_available",
        "Formulas.stock_location",
        "Formulas.storage_condition",
        "Formulas.minimum_stock",
        "Formulas.maximum_stock",
        "Formulas.expiry_date",
        "Formulas.manufacture_date",
        "Formulas.purchase_date",
        "Formulas.zdhc_mrsl",
        // "Formulas.zdhc_grading",
        "Formulas.zdhc_mrsl_self_declaration",
        "Formulas.gots",
        "Formulas.gots_version",
        "Formulas.eco_passport",
        "Formulas.bluesign",
        "Formulas.bluesign_system_partner",
        "Formulas.green_screen",
        "Formulas.green_screen_level",
        "Formulas.c2c",
        "Formulas.inditex_AboveThreeYears",
        "Formulas.inditex_BelowThreeYears",
        "Formulas.scivera",
        "Formulas.toxfmd",
        "Formulas.test_report",
        "Formulas.testing_facility",
        "Formulas.svhc",
        "Formulas.hazard_statement",
        "Formulas._type",
        "Formulas.is_compliant_for_factory",
        "Formulas.gateway_name",
        "Formulas.gateway_manufacturer",
        "Formulas.gateway_level",
        //  "Formulas.zdhc_mrsl_version",
      ]:
      [
        "Formulas.organization_name",
        "Formulas.scan_date",
        "Formulas.chemical_name",
        "Formulas.chemical_manufacturer",
        "Formulas.application",
        "Formulas.user_application",
        "Formulas.category",
        "Formulas.delivered_quantity",
        "Formulas.consumption",
        "Formulas.stock_volume",
        "Formulas.basic_chemical",
        // "OrganizationProductType.productType",
        "Formulas.lot_number",
        "Formulas.cas_nr",
        "Formulas.notes",
        "Formulas.use_of_ppe",
        "Formulas.ghs_classification",
        "Formulas.precautionary_statement",
        "Formulas.ghs_msds_available",
        "Formulas.stock_location",
        "Formulas.storage_condition",
        "Formulas.minimum_stock",
        "Formulas.maximum_stock",
        "Formulas.expiry_date",
        "Formulas.manufacture_date",
        "Formulas.purchase_date",
        "Formulas.zdhc_mrsl",
        // "Formulas.zdhc_grading",
        "Formulas.zdhc_mrsl_self_declaration",
        "Formulas.gots",
        "Formulas.gots_version",
        "Formulas.eco_passport",
        "Formulas.bluesign",
        "Formulas.bluesign_system_partner",
        "Formulas.green_screen",
        "Formulas.green_screen_level",
        "Formulas.c2c",
        "Formulas.inditex_AboveThreeYears",
        "Formulas.inditex_BelowThreeYears",
        "Formulas.scivera",
        "Formulas.toxfmd",
        "Formulas.test_report",
        "Formulas.testing_facility",
        "Formulas.svhc",
        "Formulas.hazard_statement",
        "Formulas._type",
        "Formulas.is_compliant_for_factory",
        //  "Formulas.zdhc_mrsl_version",
      ],
      "timeDimensions": [],
      // "order": {
      //   "SupplierIdentification.supplier_identification": "desc"
      // },
      "filters": [
        {
          "member": "Formulas.scan_date",
          "operator": "inDateRange",
          "values": [
            this.startDateStringActivity,
            this.endDateStringActivity
          ]
        }
      ],
      "measures": [
        // "SupplierIdentification.supplier_identification",
        // "UsedFor.count_used_for_brand"
      ],
    }
    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0 ) {
          this.hasNotData =false;
        tempData = this.changeKey("Formulas.organization_name", "organization_name", tempData)
        tempData = this.changeKey("Formulas.scan_date", "scan_date", tempData)
        tempData = this.changeKey("Formulas.chemical_name", "chemical_name", tempData)
        tempData = this.changeKey("Formulas.chemical_manufacturer", "chemical_manufacturer", tempData)
        tempData = this.changeKey("Formulas.application", "application", tempData)
        tempData = this.changeKey("Formulas.user_application", "user_application", tempData)
        tempData = this.changeKey("Formulas.category", "category", tempData)
        tempData = this.changeKey("Formulas.delivered_quantity", "delivered_quantity", tempData)
        tempData = this.changeKey("Formulas.consumption", "consumption", tempData)
        tempData = this.changeKey("Formulas.stock_volume", "stock_volume", tempData)
        tempData = this.changeKey("Formulas.basic_chemical", "basic_chemical", tempData)
        // tempData = this.changeKey("OrganizationProductType.productType", "productType", tempData)
        tempData = this.changeKey("Formulas.lot_number", "lot_number", tempData)
        tempData = this.changeKey("Formulas.cas_nr", "cas_nr", tempData)
        tempData = this.changeKey("Formulas.notes", "notes", tempData)
        tempData = this.changeKey("Formulas.use_of_ppe", "use_of_ppe", tempData)
        tempData = this.changeKey("Formulas.ghs_classification", "ghs_classification", tempData)
        tempData = this.changeKey("Formulas.precautionary_statement", "precautionary_statement", tempData)
        tempData = this.changeKey("Formulas.ghs_msds_available", "ghs_msds_available", tempData)
        tempData = this.changeKey("Formulas.stock_location", "stock_location", tempData)
        tempData = this.changeKey("Formulas.storage_condition", "storage_condition", tempData)
        tempData = this.changeKey("Formulas.minimum_stock", "minimum_stock", tempData)
        tempData = this.changeKey("Formulas.maximum_stock", "maximum_stock", tempData)
        tempData = this.changeKey("Formulas.expiry_date", "expiry_date", tempData)
        tempData = this.changeKey("Formulas.manufacture_date", "manufacture_date", tempData)
        tempData = this.changeKey("Formulas.purchase_date", "purchase_date", tempData)
        tempData = this.changeKey("Formulas.zdhc_mrsl", "zdhc_mrsl", tempData)
        // tempData = this.changeKey("Formulas.zdhc_grading", "zdhc_grading", tempData)
        tempData = this.changeKey("Formulas.zdhc_mrsl_self_declaration", "zdhc_mrsl_self_declaration", tempData)
        tempData = this.changeKey("Formulas.gots", "gots", tempData)
        tempData = this.changeKey("Formulas.gots_version", "gots_version", tempData)
        tempData = this.changeKey("Formulas.eco_passport", "eco_passport", tempData)
        tempData = this.changeKey("Formulas.bluesign", "bluesign", tempData)
        tempData = this.changeKey("Formulas.bluesign_system_partner", "bluesign_system_partner", tempData)
        tempData = this.changeKey("Formulas.green_screen", "green_screen", tempData)
        tempData = this.changeKey("Formulas.green_screen_level", "green_screen_level", tempData)
        tempData = this.changeKey("Formulas.c2c", "c2c", tempData)
        tempData = this.changeKey("Formulas.inditex_AboveThreeYears", "inditex_AboveThreeYears", tempData)
        tempData = this.changeKey("Formulas.inditex_BelowThreeYears", "inditex_BelowThreeYears", tempData)
        tempData = this.changeKey("Formulas.scivera", "scivera", tempData)
        tempData = this.changeKey("Formulas.toxfmd", "toxfmd", tempData)
        tempData = this.changeKey("Formulas.test_report", "test_report", tempData)
        tempData = this.changeKey("Formulas.testing_facility", "testing_facility", tempData)
        tempData = this.changeKey("Formulas.svhc", "svhc", tempData)
        tempData = this.changeKey("Formulas.hazard_statement", "hazard_statement", tempData)
        tempData = this.changeKey("Formulas._type", "_type", tempData)
        tempData = this.changeKey("Formulas.is_compliant_for_factory", "is_compliant", tempData)
        tempData = this.isFactoryZdhc ? this.changeKey("Formulas.gateway_name", "gateway_name", tempData) : tempData,
        tempData = this.isFactoryZdhc ? this.changeKey("Formulas.gateway_manufacturer", "gateway_manufacturer", tempData) : tempData,
        tempData = this.isFactoryZdhc ? this.changeKey("Formulas.gateway_level", "gateway_level", tempData) : tempData
          tempData = tempData.map(obj => {
            if (obj.scan_date)
              obj.scan_date = obj.scan_date.split('T')[0];
            if (obj.basic_chemical == 1) {
              obj.basic_chemical = 'Yes';
            }
            else {
              obj.basic_chemical = 'No';
            }
            if (obj.zdhc_mrsl == 1) {
              obj.zdhc_mrsl = 'Yes';
            }
            else {
              obj.zdhc_mrsl = 'No';
            }
            if (obj.gots == 1) {
              obj.gots = 'Yes';
            }
            else {
              obj.gots = 'No';
            }
            if (obj.eco_passport == 1) {
              obj.eco_passport = 'Yes';
            }
            else {
              obj.eco_passport = 'No';
            }
            if (obj.bluesign == 1) {
              obj.bluesign = 'Yes';
            }
            else {
              obj.bluesign = 'No';
            }
            if (obj.green_screen == 1) {
              obj.green_screen = 'Yes';
            }
            else {
              obj.green_screen = 'No';
            }
            if (obj.scivera == 1) {
              obj.scivera = 'Yes';
            }
            else {
              obj.scivera = 'No';
            }
            if (obj.toxfmd == 1) {
              obj.toxfmd = 'Yes';
            }
            else {
              obj.toxfmd = 'No';
            }
            if (obj.test_report == 1) {
              obj.test_report = 'Yes';
            }
            else {
              obj.test_report = 'No';
            }
            if (obj.bluesign_system_partner == 1) {
              obj.bluesign_system_partner = 'Yes';
            }
            else {
              obj.bluesign_system_partner = 'No';
            }
            if (obj.c2c == 1) {
              obj.c2c = 'Yes';
            }
            else {
              obj.c2c = 'No';
            }
            if (obj.zdhc_mrsl_self_declaration == 1) {
              obj.zdhc_mrsl_self_declaration = 'Yes';
            }
            else {
              obj.zdhc_mrsl_self_declaration = 'No';
            }
            if (this.isFactoryZdhc) {
              if (obj.gateway_level != '' && obj.gateway_level != null) {
                if (obj.stock_volume > 0 && obj.consumption > 0) {
                  obj.usage_delivery = 'usage, delivery';
                }
                else {
                  if (obj.stock_volume > 0 && obj.consumption <= 0) {
                    obj.usage_delivery = 'delivery';
                  }
                  else {
                    if (obj.consumption > 0 && obj.stock_volume <= 0) {
                      obj.usage_delivery = 'usage';
                    }
                    else {
                      obj.usage_delivery = '';
                    }
                  }
                }
              }
              else {
                obj.usage_delivery = '';
              }

              if (obj.gateway_name == '' && obj.gateway_manufacturer == '' && obj.gateway_level == null)
                obj.gateway_level = 'not included in InCheck';

            }
            return obj;
          })  
        }
        else this.hasNotData = true;
        this.isLoadingrowDataInfo = false;
        this.rowDataInfoDataList.data = tempData;
        this.rowDataInfoDataList.paginator = this.rawMatPaginator;
        this.rowDataInfoDataList.sort = this.rawInfoMatSort;

      })
  }


  applyFilters() {
    let updateCharts = false;
    this.filters = [];
    let std = new Date(this.startDate.value);
    let edd = new Date(this.endDate.value);
    this.startDateString = new Date(std.getFullYear(), std.getMonth(), std.getDate() + 1).toISOString().slice(0, 10);
    this.endDateString = new Date(edd.getFullYear(), edd.getMonth(), edd.getDate() + 1).toISOString().slice(0, 10);
    this.filters.push(
      {
        "member": "Formulas.scan_date",
        "operator": "inDateRange",
        "values": [
          this.startDateString,
          this.endDateString]
      }
    )
    if (this.chemicalNameControl.value != null && this.chemicalNameControl.value != "") {
      this.filters.push(
        {
          "member": "Formulas.chemical_name",
          "operator": "contains",
          "values": [this.chemicalNameControl.value]
        }
      )
      updateCharts = true;
    }
    // if (this.productTypeControl.value && this.productTypeControl.value.length !== 0) {
    //   this.filters.push(
    //     {
    //       "member": "OrganizationProductType.productType",
    //       "operator": "equals",
    //       "values": this.productTypeControl.value.map(obj => obj.name)
    //     }
    //   )
    //   updateCharts = true;
    // }

    if (this.usedForControl.value && this.usedForControl.value.length !== 0) {
      this.filters.push(
        {
          "member": "UsedFor.brand_name",
          "operator": "equals",
          "values": this.usedForControl.value.map(obj => obj.name)
        }
      )
      updateCharts = true;
    }
    if (this.factoryUsageControl.value && this.factoryUsageControl.value.length !== 0) {
      this.filters.push(
        {
          "member": "Formulas.factory_usage",
          "operator": "equals",
          "values": this.factoryUsageControl.value.map(obj => obj.name)
        }
      )
      updateCharts = true;
    }
    // if (this.activityTypeControl.value && this.activityTypeControl.value.length !== 0) {
    //   this.filters.push(
    //     {
    //       "member": "Factories.activity",
    //       "operator": "equals",
    //       "values": this.activityTypeControl.value.map(obj => obj.name)
    //     }
    //   )
    //   updateCharts = true;
    // }

    if (!this.hasScopeCommodities && this.commoditiesControl.value != undefined) {
      let commodities_value = this.commoditiesControl.value == true ? ['0', '1'] : ['0'];
      this.filters.push({
        "member": 'Formulas.basic_chemical',
        "operator": 'equals',
        "values": commodities_value,

      });
      updateCharts = true;
    }

    this.onTabChanged()
  }

  inditexLevelChange(e) {
    if (e.source.selected && e.source.value.name == 'C') {
      this.inditexLevelControl.reset()
    }
    if (e.source.selected && (e.source.value.name == 'A' || e.source.value.name == 'B') && this.inditexLevelControl.value.find(o => o.name === 'C')) {
      this.inditexLevelControl.reset()
    }
  }


  clearFilters() {
    let updateCharts = false;
    this.filters = [];
    this.startDate.reset(moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'));
    this.endDate.reset(moment().endOf('month').format('YYYY-MM-DD'));
    this.startDateActivity.reset(moment().startOf('month').format('YYYY-MM-DD'));
    this.endDateActivity.reset(moment().endOf('month').format('YYYY-MM-DD'));
    this.chemicalNameControl.reset();
    // this.productTypeControl.reset();
    this.usedForControl.reset();
    this.commoditiesControl.reset(false);
    this.inditexCategoryControl.reset();
    this.inditexLevelControl.reset();
    this.factoryUsageControl.reset();
    this.activityTypeControl.reset();
    updateCharts = true;
    this.applyFilters();
  }

  exportData(data: any, fileName: string): void {
    this.exportService.exportAsExcelFile(data, fileName);
  }

}
