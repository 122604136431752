import { ToastService } from 'app/common/toast-alert/toast.service';
import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { GlobalsService } from '../globals-services/globals.service';
import { CubejsService } from '../shared/cubejs/cubejs.service';
import { ExportDataService } from '../shared/services/export-data.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrganizationConst } from 'app/globals-classes/OrganizationConst';
import { forkJoin } from 'rxjs';
import { MessageDialogComponent } from 'app/shared/message-dialog/message-dialog.component';


export interface OttoComment {
  supplierName: string,
  supplierId: number,
  commentDetails: [
    {
      createdOn: Date,
      userName: string,
      comment: string
    }
  ]
}

@Component({
  selector: 'comment-dialog',
  templateUrl: 'comment-dialog/comment-dialog.html',
  styleUrls: ['./comment-dialog/comment-dialog.scss']
})
export class CommentDialog {
  comment: string = "";
  constructor(
    public dialogRef: MatDialogRef<CommentDialog>, private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: OttoComment, private http: HttpClient, private globalsService: GlobalsService
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  publish() {
    let model = {
      supplierId: this.data.supplierId,
      comment: this.comment
    };
    this.http.post<any>(this.globalsService.baseHref + '/saveAnalyticsComment', model)
      .subscribe((res: any) => {
        this.toastService.success("Your changes save successfully")
        this.dialogRef.close(res['comment']);
      });
  }
}

@Component({
  selector: 'app-brand-analytics',
  templateUrl: './brand-analytics.component.html',
  styleUrls: ['./brand-analytics.component.scss']
})
export class BrandAnalyticsComponent implements OnInit {

  @ViewChild('factoryScanInfoMatPaginator') factoryScanInfoMatPaginator: MatPaginator;
  @ViewChild('factoryScanInfoMatSort') factoryScanInfoMatSort: MatSort;
  @ViewChild('factoryLastScanInfoMatPaginator') factoryLastScanInfoMatPaginator: MatPaginator;
  @ViewChild('factoryLastScanInfoMatSort') factoryLastScanInfoMatSort: MatSort;
  @ViewChild('factoryRatingInfoMatPaginator') factoryRatingInfoMatPaginator: MatPaginator;
  @ViewChild('factoryRatingInfoMatSort') factoryRatingInfoMatSort: MatSort;
  @ViewChild('topChemicalsMatPaginator') topChemicalsMatPaginator: MatPaginator;
  @ViewChild('topChemicalsMatSort') topChemicalsMatSort: MatSort;
  @ViewChild('topManufacturersMatPaginator') topManufacturersMatPaginator: MatPaginator;
  @ViewChild('topManufacturersMatSort') topManufacturersMatSort: MatSort;
  @ViewChild('topUnverifiedChemicalsMatPaginator') topUnverifiedChemicalsMatPaginator: MatPaginator;
  @ViewChild('topUnverifiedChemicalsMatSort') topUnverifiedChemicalsMatSort: MatSort;
  @ViewChild('topUnverifiedManufacturersMatPaginator') topUnverifiedManufacturersMatPaginator: MatPaginator;
  @ViewChild('topUnverifiedManufacturersMatSort') topUnverifiedManufacturersMatSort: MatSort;
  @ViewChild('topVerifiedChemicalsMatPaginator') topVerifiedChemicalsMatPaginator: MatPaginator;
  @ViewChild('topVerifiedChemicalsMatSort') topVerifiedChemicalsMatSort: MatSort;

  @ViewChild('chemicalInfoMatPaginator') chemicalInfoMatPaginator: MatPaginator;
  @ViewChild('chemicalInfoMatSort') chemicalInfoMatSort: MatSort;

  @ViewChild('activityInfoMatPaginator') activityInfoMatPaginator: MatPaginator;
  @ViewChild('activityInfoMatSort') activityInfoMatSort: MatSort;

  @ViewChild('ottoActivityInfoMatPaginator') ottoActivityInfoMatPaginator: MatPaginator;
  @ViewChild('ottoActivityInfoMatSort') ottoActivityInfoMatSort: MatSort;
  @ViewChild('rawMatPaginator') rawMatPaginator: MatPaginator;
  @ViewChild('rawInfoMatSort') rawInfoMatSort: MatSort;



  // #region Properties

  isBrandZdhc = GlobalsService.isBrandZdhcMember;
  isTCP = GlobalsService.isTCP;
  errorList = [];

  startDate = new FormControl(moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'));
  endDate = new FormControl(moment().endOf('month').format('YYYY-MM-DD'));
  startDateActivity = new FormControl(moment().startOf('month').format('YYYY-MM-DD'));
  endDateActivity = new FormControl(moment().endOf('month').format('YYYY-MM-DD'));

  startDateString: string;
  endDateString: string;

  startDateStringActivity: string;
  endDateStringActivity: string;

  connectedFactories: Number

  //overall properties
  brandName: string;
  complianceName: string;
  organizationId: string; //must be a string
  organization_name: string; //must be a string
  isLoading = false;
  tabIndex: number = 0;
  view = [(window.innerWidth) - 200, (window.innerHeight * 16) / 40]
  lineView = [500, 320]
  pieView = [500, 320]
  filters: Array<Object> = [];
  rowDataFilters: Array<Object> = [];
  productTypeFilter: [];
  productTypeControl = new FormControl();
  productActivityTypeFilter: [];
  productActivityTypeControl = new FormControl();
  countryFilter: any[];
  countryControl = new FormControl();
  allCountries: any[];
  selectedCountry = [];
  usedForFilter: [];
  usedForControl = new FormControl();
  factoryFilter: any[];
  factoryControl = new FormControl();
  allFactories: any[];
  selectedFactory = [];
  countryActivityControl = new FormControl();
  allActivityCountries: any[];
  allActivityFactories: any[];
  selectedActivityCountry = [];
  selectedActivityFactory = [];
  factoryActivityFilter: any[];
  factoryActivityControl = new FormControl();
  countryActivityFilter: any[];
  factoryUsage: [];
  factoryUsageControl = new FormControl();
  activityType: [];
  // activityTypeControl = new FormControl();
  inditexCategoryList: Array<Object>;
  inditexCategoryControl = new FormControl();
  inditexLevelList: Array<Object>;
  inditexLevelControl = new FormControl();
  scopeList: Array<Object>;
  scopeCategoryControl = new FormControl();
  scopeActivityCategoryControl = new FormControl();
  walmarOrderList: Array<Object>;
  walmartOrderControl = new FormControl();
  vendorNameList: Array<Object>;
  vendorNameControl = new FormControl();
  selectedVendor = [];
  allVendors: any[];
  vendorIdList: Array<Object>;
  vendorIdControl = new FormControl();
  selectedVendorid = [];
  allVendors_id: any[];
  fabricRollIdControl = new FormControl();
  vendorTypeList: Array<Object>;
  vendorTypeControl = new FormControl();
  gatewayLevelsList: Array<Object>;
  gatewayLevelControl = new FormControl();
  gatewayVersionsList: Array<Object>;
  gatewayVersionsControl = new FormControl();
  departmentList: Array<Object>;
  departmentControl = new FormControl();
  chemicalNameControl = new FormControl();
  commoditiesControl = new FormControl(false);
  brandScopeControl = new FormControl(true);
  hasNotData: boolean = false;
  isCubejsConnected: boolean = true;
  isKontoor: Boolean = GlobalsService.isKontoor;
  isOtto: Boolean = GlobalsService.isOtto;
  organizationScope: any;
  BrandsScope: any = [];
  hasScopeCommodities: boolean = false;
  // #endregion

  onResize(event) {
    this.view = [event.target.innerWidth, (event.target.innerHeight / 5) * 2 - 14];
  }
  constructor(private cubejsService: CubejsService, private globalsService: GlobalsService,
    @Inject(LOCALE_ID) public locale: string, private toastService: ToastService, private exportService: ExportDataService, private http: HttpClient, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.organizationId = this.globalsService.profile["id"].toString();
    this.organization_name = this.globalsService.profile["name"].toString().toLowerCase();

    this.http.get<any>(this.globalsService.baseHref + '/complianceCriteria/getBrandsWithScopeAndCompliance').subscribe((response) => {
      this.BrandsScope = response['brands'];
      this.organizationScope = this.BrandsScope.find(res => res.id == this.globalsService.profile['id'])['scopeValue'];
      this.hasScopeCommodities = this.organizationScope ? this.organizationScope.includes('Bulk / Commodities') : false;
      if (!this.hasScopeCommodities) {
        this.commoditiesControl.setValue(false);
      }
      this.getOnInitFilters();
    }
    )
  }

  // get isWalmart(): boolean { 

  //   return this.globalsService.profile["id"] === OrganizationConst.WALMART
  // }

  getOnInitFilters() {
    this.getFilterData()
    this.inditexCategoryList = [{ name: "Above 3 years" }, { name: "Below 3 years" }];
    this.scopeList = [{ name: "In Scope", value: "IN_SCOPE" }, { name: "Out Scope", value: "OUT_SCOPE" }];
    this.walmarOrderList = [{ name: "Yes", value: "WALMART_ORDER" }, { name: "No", value: "" }];
    this.gatewayLevelsList = [{ name: "Registered", value: "registered" }, { name: "Level 1", value: '1' }, { name: "Level 2", value: '2' }, { name: "Level 3", value: '3' }, { name: "Expired", value: 'expired' }, { name: "Conformant", value: 'conformant' }, { name: "Not Evaluated", value: 'notEvaluated' }];
    this.gatewayVersionsList = [{ name: "ZDHC v1.1", value: 'oldZDHC' }, { name: "ZDHC v2.0", value: 'v2.0' }, { name: "ZDHC v3.1", value: 'v3.1' }];
    this.inditexLevelList = [{ name: "A" }, { name: "B" }, { name: "C" }];
    let std = new Date(this.startDate.value);
    let edd = new Date(this.endDate.value);
    this.startDateString = new Date(std.getFullYear(), std.getMonth(), std.getDate() + 1).toISOString().slice(0, 10);
    this.endDateString = new Date(edd.getFullYear(), edd.getMonth(), edd.getDate() + 1).toISOString().slice(0, 10);
    this.filters.push(
      {
        "member": "Formulas.scan_date",
        "operator": "inDateRange",
        "values": [
          this.startDateString,
          this.endDateString
        ]
      }
    )
    //if commodities filter is false then basic chemical are excluded from total number
    //else get all chemicals (basic and not-basic)
    if (!this.hasScopeCommodities && this.commoditiesControl.value != undefined) {
      let commodities_value = this.commoditiesControl.value == true ? ['0', '1'] : ['0'];
      this.filters.push({
        "member": 'Formulas.basic_chemical',
        "operator": 'equals',
        "values": commodities_value,

      });
    }
    this.onTabChanged()
  }

  getFilterData() {
    const productQuery = {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
        {
          "member": "OrganizationProductType.brand_id",
          "operator": "equals",
          "values": [this.organizationId.toString()],
        }
      ],
      "dimensions": [
        "OrganizationProductType.productType"
      ]
    }
    this.cubejsService.getCubeJSData(productQuery, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("OrganizationProductType.productType", "name", tempData)
        this.productTypeFilter = tempData;
      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })

    // const productActivityQuery = {
    //   "measures": [
    //   ],
    //   "timeDimensions": [
    //   ],
    //   "order": {
    //   },
    //   "filters": [
    //     {
    //       "member": "OrganizationProductType.brand_id",
    //       "operator": "equals",
    //       "values": [this.organizationId.toString()],
    //     }
    //   ],
    //   "dimensions": [
    //     "OrganizationProductType.productType"
    //   ]
    // }
    // this.cubejsService.getCubeJSData(productActivityQuery)
    //   .then(data => {
    //     let tempData = data['loadResponse']['results'][0]['data'];
    //     tempData = this.changeKey("OrganizationProductType.productType", "name", tempData)
    //     this.productActivityTypeFilter = tempData;
    //   })

    const millQuery = {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
      ],
      "dimensions": [
        "Factories.factory_name"
      ]
    }
    this.cubejsService.getCubeJSData(millQuery, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Factories.factory_name", "name", tempData)
        this.allFactories = tempData;
        this.factoryFilter = this.allFactories;

      })
    const millActivityQuery = {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
      ],
      "dimensions": [
        "BrandSuppliers.organization_name"
      ]
    }
    this.cubejsService.getCubeJSData(millActivityQuery, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("BrandSuppliers.organization_name", "name", tempData)
        this.allActivityFactories = tempData;
        this.factoryActivityFilter = this.allActivityFactories;

      })

    const countryQuery = {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
      ],
      "dimensions": [
        "Factories.country_name"
      ]
    }
    this.cubejsService.getCubeJSData(countryQuery, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Factories.country_name", "name", tempData)
        this.allCountries = tempData;
        this.countryFilter = this.allCountries;

      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })

    const countryActivityQuery = {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
      ],
      "dimensions": [
        "BrandSuppliers.country_name"
      ]
    }
    this.cubejsService.getCubeJSData(countryActivityQuery, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("BrandSuppliers.country_name", "name", tempData)
        this.allActivityCountries = tempData.filter(elements => elements.name != null);
        this.countryActivityFilter = this.allActivityCountries;

      })

    const usedForQuery = {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
        {
          "member": "UsedFor.used_for_brand_id",
          "operator": "equals",
          "values": [this.organizationId.toString()],
        }
      ],
      "dimensions": [
        "UsedFor.brand_name"
      ]
    }
    this.cubejsService.getCubeJSData(usedForQuery, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("UsedFor.brand_name", "name", tempData)
        this.usedForFilter = tempData;
      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })

    const factoryUsageQuery = {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
      ],
      "dimensions": [
        "Formulas.factory_usage"
      ]
    }
    this.cubejsService.getCubeJSData(factoryUsageQuery, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.factory_usage", "name", tempData)
        this.factoryUsage = tempData.filter(elements => elements.name != null);

      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })
    const activityTypeQuery = {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
      ],
      "dimensions": [
        "Factories.activity"
      ]
    }
    this.cubejsService.getCubeJSData(activityTypeQuery, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Factories.activity", "name", tempData)
        this.activityType = tempData.filter(elements => elements.name != null);

      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })
    const vendorName = {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
        // {
        //   "member": "Vendors.is_walmart",
        //   "operator": "equals",
        //   "values": ['1'],
        // }
      ],
      "dimensions": [
        "Vendors.name"
      ]
    }
    this.cubejsService.getCubeJSData(vendorName, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Vendors.name", "name", tempData)
        this.allVendors = tempData.filter(elements => elements.name != null);
        this.vendorNameList = this.allVendors;

      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })

    const vendorId = {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
        // {
        //   "member": "Vendors.is_walmart",
        //   "operator": "equals",
        //   "values": ['1'],
        // }
      ],
      "dimensions": [
        "Vendors.partner_id_value"
      ]
    }
    this.cubejsService.getCubeJSData(vendorId, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Vendors.partner_id_value", "id", tempData)
        this.allVendors_id = tempData.filter(elements => elements.id != null);
        this.vendorIdList = this.allVendors_id;

      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })

    const vendorType = {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
        // {
        //   "member": "Vendors.is_walmart",
        //   "operator": "equals",
        //   "values": ['1'],
        // }
      ],
      "dimensions": [
        "Vendors.type"
      ]
    }
    this.cubejsService.getCubeJSData(vendorType, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Vendors.type", "type", tempData)
        this.vendorTypeList = tempData.filter(elements => elements.type != null);
      })

    const department = {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
      ],
      "dimensions": [
        "Vendors.department"
      ]
    }
    this.cubejsService.getCubeJSData(department, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Vendors.department", "name", tempData)
        this.departmentList = tempData.filter(elements => elements.name != null);
      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })
  }

  onInputChange(event: any, type: string) {
    const searchInput = event.target.value.toLowerCase();
    switch (type) {
      case 'Country': {
        if (searchInput == "") {
          this.countryControl.setValue(this.selectedCountry, { emitEvent: false });
          this.countryFilter = this.allCountries;
        }
        else {
          this.countryFilter = this.allCountries.filter(({ name }) => {
            const prov = name.toLowerCase();
            return prov.includes(searchInput);
          });
        }
        break;
      }
      case 'Mill': {
        if (searchInput == "") {
          this.factoryControl.setValue(this.selectedFactory, { emitEvent: false });
          this.factoryFilter = this.allFactories;
        }
        else {
          this.factoryFilter = this.allFactories.filter(({ name }) => {
            const prov = name.toLowerCase();
            return prov.includes(searchInput);
          });
        }
        break;
      }
      case 'Country_Activity': {
        if (searchInput == "") {
          this.countryActivityControl.setValue(this.selectedActivityCountry, { emitEvent: false });
          this.countryActivityFilter = this.allActivityCountries;
        }
        else {
          this.countryActivityFilter = this.allActivityCountries.filter(({ name }) => {
            const prov = name.toLowerCase();
            return prov.includes(searchInput);
          });
        }
        break;
      }
      case 'Mill_Activity': {
        if (searchInput == "") {
          this.factoryActivityControl.setValue(this.selectedActivityFactory, { emitEvent: false });
          this.factoryActivityFilter = this.allFactories;
        }
        else {
          this.factoryActivityFilter = this.allActivityFactories.filter(({ name }) => {
            const prov = name.toLowerCase();
            return prov.includes(searchInput);
          });
        }
        break;
      }
      case 'Vendor Name': {
        if (searchInput == "") {
          this.vendorNameControl.setValue(this.selectedVendor, { emitEvent: false });
          this.vendorNameList = this.allVendors;
        }
        else {
          this.vendorNameList = this.allVendors.filter(({ name }) => {
            const prov = name.toLowerCase();
            return prov.includes(searchInput);
          });
        }
        break;
      }
      case 'Vendor ID': {
        if (searchInput == "") {
          this.vendorIdControl.setValue(this.selectedVendorid, { emitEvent: false });
          this.vendorIdList = this.allVendors_id;
        }
        else {
          this.vendorIdList = this.allVendors_id.filter(({ id }) => {
            const prov = id.toLowerCase();
            return prov.includes(searchInput);
          });
        }
        break;
      }

    }
  }
  onOpenChange(searchInput: any, type: string) {
    searchInput.value = "";
    switch (type) {
      case 'Country': {
        this.countryFilter = this.allCountries;
        this.countryControl.setValue(this.selectedCountry, { emitEvent: false });
        break;
      }
      case 'Mill': {
        this.factoryFilter = this.allFactories;
        this.factoryControl.setValue(this.selectedFactory, { emitEvent: false });
        break;
      }
      case 'Country_Activity': {
        this.countryActivityFilter = this.allActivityCountries;
        this.countryActivityControl.setValue(this.selectedActivityCountry, { emitEvent: false });
        break;
      }
      case 'Mill_Activity': {
        this.factoryActivityFilter = this.allActivityFactories;
        this.factoryActivityControl.setValue(this.selectedActivityFactory, { emitEvent: false });
        break;
      }
      case 'Vendor Name': {
        this.vendorNameList = this.allVendors;
        this.vendorNameControl.setValue(this.selectedVendor, { emitEvent: false });
        break;
      }
      case 'Vendor ID': {
        this.vendorIdList = this.allVendors_id;
        this.vendorIdControl.setValue(this.selectedVendorid, { emitEvent: false });
        break;
      }

    }
  }
  selection(event, type: string) {
    switch (type) {
      case 'Country': {
        if (event.isUserInput) {
          if (event.source._selected) {
            this.selectedCountry.push(event.source.value);
          }
          else {
            this.selectedCountry = this.selectedCountry.filter(el => { return el != event.source.value })
          }
        }
        break;
      }
      case 'Mill': {
        if (event.isUserInput) {
          if (event.source._selected) {
            this.selectedFactory.push(event.source.value);
          }
          else {
            this.selectedFactory = this.selectedFactory.filter(el => { return el != event.source.value })
          }
        }
        break;
      }
      case 'Country_Activity': {
        if (event.isUserInput) {
          if (event.source._selected) {
            this.selectedActivityCountry.push(event.source.value);
          }
          else {
            this.selectedActivityCountry = this.selectedActivityCountry.filter(el => { return el != event.source.value })
          }
        }
        break;
      }
      case 'Mill_Activity': {
        if (event.isUserInput) {
          if (event.source._selected) {
            this.selectedActivityFactory.push(event.source.value);
          }
          else {
            this.selectedActivityFactory = this.selectedActivityFactory.filter(el => { return el != event.source.value })
          }
        }
        break;
      }
      case 'Vendor Name': {
        if (event.isUserInput) {
          if (event.source._selected) {
            this.selectedVendor.push(event.source.value);
          }
          else {
            this.selectedVendor = this.selectedVendor.filter(el => { return el != event.source.value })
          }
        }
        break;
      }
      case 'Vendor ID': {
        if (event.isUserInput) {
          if (event.source._selected) {
            this.selectedVendorid.push(event.source.value);
          }
          else {
            this.selectedVendorid = this.selectedVendorid.filter(el => { return el != event.source.value })
          }
        }
        break;
      }
    }
  }

  selectedTabChanged($event) {
    this.tabIndex = $event.index;
    this.onTabChanged()
  }
  onTabChanged() {
    this.isLoading = true;
    this.toastService.info(
      `Great things take time - we’re currently processing your data.
      Please do not exit or refresh this page.`
    );
    switch (this.tabIndex) {
      case 0: {
        this.getCompliancePerformance()
        this.getConsumptionPerformance()
        this.getCategoryPerformanceCount()
        this.getCategoryPerformanceWeight()
        this.getOverallPerformanceCount()
        this.getOverallerformanceWeight()
        break;
      }
      case 1: {
        this.getFormulasFirst()
        // this.getFactoryScanInformation()
        // this.getFactoryLastScanInformation()
        // this.getFactoryRatingInfo()
        this.getComplianceSeries()
        // this.getFormulasFirst()
        break;
      }
      case 2: {
        this.getFormulasFirst1()

        break;
      }
      case 3: {
        if (this.isOtto && this.filters.length <= 2 && !this.scopeActivityCategoryControl.value) {
          this.getOttoCreatedAccountsCount()
          this.getOttoConnectedFactoriesCount()
          this.getOttoNominatedFactoriesCount()
          this.getOttoScanFactoriesCount()
          this.getOttoActivityMonitoring()
        }
        else {
          if ((this.isOtto && this.filters.length <= 2 && this.scopeActivityCategoryControl.value) || (this.isOtto && this.filters.length > 2)) {
            this.getOttoActivityMonitoring()
          }
          else {
            if (this.filters.length <= 2 && !this.scopeActivityCategoryControl.value) {
              this.getCreatedAccountsCount()
              this.getConnectedFactoriesCount()
              this.getScanFactoriesCount()
              this.getCurrentScanFactoriesCount()
              this.getIncheckChemicalCount()
              this.getWasteWaterChemicalCount()
              this.getHiggChemicalCount()
              this.getActivityMonitoring()
            }
            else {
              this.getActivityMonitoring()
            }
          }
        }
        break;
      }
      case 4: {
        this.getRowData();
        break;
      }
      default: {

      }
    }
  }
  changeKey(originalKey: string, newKey: string, arr: Array<Object>) {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      var obj = arr[i];
      obj[newKey] = (newKey == "value" && obj[originalKey] === null) ? 0 : obj[originalKey];
      delete (obj[originalKey]);
      newArr.push(obj);
    }
    return newArr;
  }
  ShowErrorMessage(msg) {
    this.isLoading = false;
    let customMSg = false;
    if (msg.includes("No id found in Security Context")) {
      msg = 'No factories are connected to this account, so no data can be displayed.\n Ask your factories to connect to you to see their data.',
        customMSg = true;
    }
    if (msg.includes("Network request failed")) {
      this.isCubejsConnected = false;
      throw new Error("CubeJS is not reachable!")
      // return false;
    }
    if (!this.errorList.includes(msg)) {
      this.errorList.push(msg);
      this.dialog.open(MessageDialogComponent, {
        hasBackdrop: true,
        width: "32rem",
        disableClose: true,
        data: {
          message: msg,
          hasLink: customMSg ? true : false,
          goToLink: customMSg ? "How to share inventories" : "",
          URL: customMSg ? "https://cloud.goblu.net/s/4LXyFHrE86Tzx2A" : ""
        },
      });
      // alert(msg);
    }
  }

  changeEndDate(event: MatDatepickerInputEvent<Date>, type: string) {
    if (event.value !== null) {
      if (type == 'Activity') {
        let std = new Date(this.startDateActivity.value);
        let edd = new Date(this.endDateActivity.value);
        this.startDateStringActivity = new Date(std.getFullYear(), std.getMonth(), std.getDate() + 1).toISOString().slice(0, 10);
        this.endDateStringActivity = new Date(edd.getFullYear(), edd.getMonth(), edd.getDate() + 1).toISOString().slice(0, 10);
      }
      else {
        let std = new Date(this.startDate.value);
        let edd = new Date(this.endDate.value);
        this.startDateString = new Date(std.getFullYear(), std.getMonth(), std.getDate() + 1).toISOString().slice(0, 10);
        this.endDateString = new Date(edd.getFullYear(), edd.getMonth(), edd.getDate() + 1).toISOString().slice(0, 10);
        this.filters = this.filters.filter((el) => { return el['member'] !== "Formulas.scan_date" })
        this.filters.push(
          {
            "member": "Formulas.scan_date",
            "operator": "inDateRange",
            "values": [
              this.startDateString,
              this.endDateString
            ]
          }
        )
      }
      this.onTabChanged()
    }
  }

  applyFilters() {
    let updateCharts = false;
    this.filters = [];
    this.filters.push(
      {
        "member": "Formulas.scan_date",
        "operator": "inDateRange",
        "values": [
          this.startDateString,
          this.endDateString]
      }
    )
    if (this.chemicalNameControl.value != null && this.chemicalNameControl.value != "") {
      this.filters.push(
        {
          "member": "Formulas.chemical_name",
          "operator": "contains",
          "values": [this.chemicalNameControl.value]
        }
      )
      updateCharts = true;
    }
    if (this.productTypeControl.value && this.productTypeControl.value.length !== 0) {
      this.filters.push(
        {
          "member": "OrganizationProductType.productType",
          "operator": "equals",
          "values": this.productTypeControl.value.map(obj => obj.name)
        }
      )
      updateCharts = true;
    }
    if (this.countryControl.value && this.countryControl.value.length !== 0) {
      this.filters.push(
        {
          "member": "Factories.country_name",
          "operator": "equals",
          "values": this.countryControl.value
        }
      )
      updateCharts = true;
    }
    if (this.factoryControl.value && this.factoryControl.value.length !== 0) {
      this.filters.push(
        {
          "member": "Factories.factory_name",
          "operator": "equals",
          "values": this.factoryControl.value
        }
      )
      updateCharts = true;
    }
    if (this.usedForControl.value && this.usedForControl.value.length !== 0) {
      this.filters.push(
        {
          "member": "UsedFor.brand_name",
          "operator": "equals",
          "values": this.usedForControl.value.map(obj => obj.name)
        }
      )
      updateCharts = true;
    }
    if (this.factoryUsageControl.value && this.factoryUsageControl.value.length !== 0) {
      this.filters.push(
        {
          "member": "Formulas.factory_usage",
          "operator": "equals",
          "values": this.factoryUsageControl.value.map(obj => obj.name)
        }
      )
      updateCharts = true;
    }
    if (this.scopeCategoryControl.value && this.scopeCategoryControl.value === "IN_SCOPE") {
      this.filters.push(
        {
          "and": [{
            "member": "SupplierIdentification.identification_name",
            "operator": "equals",
            "values": ['SCOPE']
          },
          {
            "member": "SupplierIdentification.identification_supplier_id",
            "operator": "equals",
            "values": [this.scopeCategoryControl.value]
          },
          {
            "member": "SupplierIdentification.brand_id",
            "operator": "equals",
            "values": [this.organizationId]
          }
          ]
        }
      )
      updateCharts = true;
    }
    if (this.scopeCategoryControl.value && this.scopeCategoryControl.value === "OUT_SCOPE") {
      this.filters.push(

        {
          "and": [{
            "member": "SupplierIdentification.identification_name",
            "operator": "equals",
            "values": ['SCOPE']
          },
          {
            "member": "SupplierIdentification.identification_supplier_id",
            "operator": "equals",
            "values": [this.scopeCategoryControl.value]
          },
          {
            "member": "SupplierIdentification.brand_id",
            "operator": "equals",
            "values": [this.organizationId]
          }
          ]
        }
      );
      updateCharts = true;
    }
    if (this.walmartOrderControl.value && this.walmartOrderControl.value === "WALMART_ORDER") {
      this.filters.push(
        {
          "and": [{
            "member": "SupplierIdentification.identification_name",
            "operator": "equals",
            "values": ['BRAND_SPECIFIC_ATTRIBUTE']
          },
          {
            "member": "SupplierIdentification.identification_supplier_id",
            "operator": "equals",
            "values": [this.walmartOrderControl.value]
          }]
        }
      )
      updateCharts = true;
    }
    if ((this.vendorNameControl.value && this.vendorNameControl.value.length !== 0) && (this.vendorIdControl.value && this.vendorIdControl.value.length !== 0)) {
      this.filters = this.filters.filter((el) => { return el['member'] !== "Vendors.partner_id_value" })
      this.filters = this.filters.filter((el) => { return el['member'] !== "Vendors.name" })
      this.filters.push(
        {
          "or": [{
            "member": "Vendors.partner_id_value",
            "operator": "equals",
            "values": this.vendorIdControl.value,
          },
          {
            "member": "Vendors.name",
            "operator": "equals",
            "values": this.vendorNameControl.value
          }]
        }
      )
      updateCharts = true;
    }
    if ((this.vendorNameControl.value && this.vendorNameControl.value.length !== 0) && (!this.vendorIdControl.value || this.vendorIdControl.value.length === 0)) {
      this.filters.push(
        {
          "member": "Vendors.name",
          "operator": "equals",
          "values": this.vendorNameControl.value

        }
      )
      updateCharts = true;
    }
    if ((!this.vendorNameControl.value || this.vendorNameControl.value.length === 0) && (this.vendorIdControl.value && this.vendorIdControl.value.length !== 0)) {
      this.filters.push(
        {
          "member": "Vendors.partner_id_value",
          "operator": "equals",
          "values": this.vendorIdControl.value,

        }
      )
      updateCharts = true;
    }
    if (this.vendorTypeControl.value && this.vendorTypeControl.value.length !== 0) {
      this.filters.push(
        {
          // "and":[{
          //   "member": "Vendors.is_walmart",
          //   "operator": "equals",
          //   "values": ['1'],
          // },
          // {
          "member": "Vendors.type",
          "operator": "equals",
          "values": [this.vendorTypeControl.value]
          // }]
        }
      )
      updateCharts = true;
    }

    if (this.departmentControl.value && this.departmentControl.value.length !== 0) {
      this.filters.push(
        {
          "member": "Vendors.department",
          "operator": "equals",
          "values": [this.departmentControl.value]
        }
      )
      updateCharts = true;
    }

    if (this.fabricRollIdControl.value && this.fabricRollIdControl.value.length !== 0) {
      this.filters.push(
        {
          "member": "Formulas.used_for_id_value",
          "operator": "contains",
          "values": [this.fabricRollIdControl.value]
        }
      )
      updateCharts = true;
    }

    // if (this.activityTypeControl.value && this.activityTypeControl.value.length !== 0) {
    //   this.filters.push(
    //     {
    //       "member": "Factories.activity",
    //       "operator": "equals",
    //       "values": this.activityTypeControl.value.map(obj => obj.name)
    //     }
    //   )
    //   updateCharts = true;
    // }
    if (this.gatewayLevelControl.value && this.gatewayLevelControl.value === "registered") {
      this.filters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": ['Registered']
        }
      )
      updateCharts = true;
    }
    if (this.gatewayLevelControl.value && this.gatewayLevelControl.value === "expired") {
      this.filters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": ['expired']
        }
      )
      updateCharts = true;
    }
    if (this.gatewayLevelControl.value && this.gatewayLevelControl.value === "1") {
      this.filters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": ['Level 1']
        }
      )
      updateCharts = true;
    }
    if (this.gatewayLevelControl.value && this.gatewayLevelControl.value === "2") {
      this.filters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": ['Level 2']
        }
      )
      updateCharts = true;
    }
    if (this.gatewayLevelControl.value && this.gatewayLevelControl.value === "3") {
      this.filters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": ['Level 3']
        }
      )
      updateCharts = true;
    }
    if (this.gatewayLevelControl.value && this.gatewayLevelControl.value === "notEvaluated") {
      this.filters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "notContains",
          "values": ['expired', 'Level 1', 'Level 2', 'Level 3', 'Registered']
        }
      )
      updateCharts = true;
    }
    if (this.gatewayLevelControl.value && this.gatewayLevelControl.value === "conformant") {
      this.filters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": ['expired', 'Level 1', 'Level 2', 'Level 3', 'Registered']
        }
      )
      updateCharts = true;
    }
    if (this.gatewayVersionsControl.value && this.gatewayVersionsControl.value === "oldZDHC") {
      this.filters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": [this.gatewayVersionsControl.value]
        }
      )
      updateCharts = true;
    }
    if (this.gatewayVersionsControl.value && this.gatewayVersionsControl.value === "v2.0") {
      this.filters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": [this.gatewayVersionsControl.value]
        }
      )
      updateCharts = true;
    }
    if (this.gatewayVersionsControl.value && this.gatewayVersionsControl.value === "v3.1") {
      this.filters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": [this.gatewayVersionsControl.value]
        }
      )
      updateCharts = true;
    }


    if (!this.hasScopeCommodities && this.commoditiesControl.value != undefined) {
      let commodities_value = this.commoditiesControl.value == true ? ['0', '1'] : ['0'];
      this.filters.push({
        "member": 'Formulas.basic_chemical',
        "operator": 'equals',
        "values": commodities_value,

      });
      updateCharts = true;
    }

    this.onTabChanged()
  }

  clearFilters() {
    let updateCharts = false;
    this.filters = [];
    this.startDate.reset(moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'));
    this.endDate.reset(moment().endOf('month').format('YYYY-MM-DD'));
    this.startDateActivity.reset(moment().startOf('month').format('YYYY-MM-DD'));
    this.endDateActivity.reset(moment().endOf('month').format('YYYY-MM-DD'));
    this.chemicalNameControl.reset();
    this.productTypeControl.reset();
    this.productActivityTypeControl.reset();
    this.countryControl.reset();
    this.selectedCountry = [];
    this.factoryControl.reset();
    this.selectedFactory = [];
    this.selectedActivityFactory = [];
    this.selectedActivityCountry = [];
    this.factoryActivityControl.reset();
    this.countryActivityControl.reset();
    this.usedForControl.reset();
    this.selectedVendor = [];
    this.vendorNameControl.reset();
    this.selectedVendorid = [];
    this.vendorIdControl.reset();
    this.vendorTypeControl.reset();
    this.departmentControl.reset();
    this.fabricRollIdControl.reset();
    this.inditexCategoryControl.reset();
    this.scopeCategoryControl.reset();
    this.walmartOrderControl.reset();
    this.gatewayLevelControl.reset();
    this.gatewayVersionsControl.reset();
    this.inditexLevelControl.reset();
    // this.scopeCategoryControl.reset();
    this.scopeActivityCategoryControl.reset();
    this.factoryUsageControl.reset();
    // this.activityTypeControl = new FormControl();
    updateCharts = true;
    this.ngOnInit();
  }

  inditexLevelChange(e) {
    if (e.source.selected && e.source.value.name == 'C') {
      this.inditexLevelControl.reset()
    }
    if (e.source.selected && (e.source.value.name == 'A' || e.source.value.name == 'B') && this.inditexLevelControl.value.find(o => o.name === 'C')) {
      this.inditexLevelControl.reset()
    }
  }

  // #region Supply Chain
  timeSeriesCompliance: Array<Object>;
  rawDataSeriesByCount: Array<Object>;
  isLoadingTimeSeriesCompliance = false;
  timeSeriesConsumption: Array<Object>
  rawDataSeriesByWeight: Array<Object>;
  isLoadingTimeSeriesConsumption = false;
  timeSeriesOverallByCount: Array<Object>
  rawDataOverallByCount: Array<Object>;
  isLoadingTimeSeriesOverallByCount = false;
  timeSeriesOverallByWeight: Array<Object>
  rawDataOverallByWeight: Array<Object>;
  isLoadingTimeSeriesOverallByWeight = false;

  getCompliancePerformance() {
    this.isLoadingTimeSeriesCompliance = true;
    let measure = "Formulas.brand_compliant_pct";
    if ((this.inditexCategoryControl.value && this.inditexCategoryControl.value.length !== 0)
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        measure = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_pct"
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        measure = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_pct"
      }
    }
    const query = {
      "measures": [
        measure
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date",
          "granularity": "month",
          "dateRange": [
            this.startDateString,
            this.endDateString
          ]
        }
      ],
      "order": {
      },
      "filters": this.filters,
      "dimensions": [
        "Formulas.year_month_scan_date"
      ]
    }
    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.year_month_scan_date", "name", tempData)
        tempData = this.changeKey(measure, "value", tempData)
        this.rawDataSeriesByCount = tempData.map(obj => {
          return { date: obj.name, compliance: obj.value };
        })
        this.timeSeriesCompliance = tempData;
        this.timeSeriesCompliance = [{ "name": "", "series": this.timeSeriesCompliance }];
        this.isLoadingTimeSeriesCompliance = false;
      })
      .catch(error => {
        this.isLoadingTimeSeriesCompliance = false;
        this.ShowErrorMessage(error.message);
      })
  }

  getConsumptionPerformance() {
    this.isLoadingTimeSeriesConsumption = true;
    let measure = "Formulas.brand_compliant_consumption_pct";
    if ((this.inditexCategoryControl.value && this.inditexCategoryControl.value.length !== 0)
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        measure = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption_pct"
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        measure = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption_pct"
      }
    }
    const query = {
      "measures": [
        measure
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date",
          "granularity": "month",
          "dateRange": [
            this.startDateString,
            this.endDateString
          ]
        }
      ],
      "order": {
      },
      "filters": this.filters,
      "dimensions": [
        "Formulas.year_month_scan_date"
      ]
    }
    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.year_month_scan_date", "name", tempData)
        tempData = this.changeKey(measure, "value", tempData)
        this.rawDataSeriesByWeight = tempData.map(obj => {
          return { date: obj.name, compliance: obj.value };
        })
        this.timeSeriesConsumption = tempData;
        this.timeSeriesConsumption = [{ "name": "", "series": this.timeSeriesConsumption }];
        this.isLoadingTimeSeriesConsumption = false;
      })
      .catch(error => {
        this.isLoadingTimeSeriesConsumption = false;
        this.ShowErrorMessage(error.message);
      })
  }
  timeSeriesCategoryByCount: Array<Object> = [];
  rawDataCategoryByCount: Array<Object>;
  isLoadingTimeSeriesCategoryByCount = false;
  getCategoryPerformanceCount() {
    this.isLoadingTimeSeriesCategoryByCount = true;
    let measure = "Formulas.brand_compliant_pct";
    if ((this.inditexCategoryControl.value && this.inditexCategoryControl.value.length !== 0)
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        measure = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_pct"
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        measure = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_pct"
      }
    }
    const query = {
      "measures": [
        measure
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date",
          "granularity": "month",
          "dateRange": [
            this.startDateString,
            this.endDateString
          ]
        }
      ],
      "order": [
        [
          "OrganizationProductType.productType",
          "asc"
        ]
      ],
      "segments": [
        "OrganizationProductType.only_brand"
      ],
      "filters": this.filters,
      "dimensions": [
        "Formulas.year_month_scan_date",
        "OrganizationProductType.productType"
      ]
    }
    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then((data) => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.year_month_scan_date", "name", tempData)
        tempData = this.changeKey(measure, "value", tempData)
        tempData = this.changeKey("OrganizationProductType.productType", "productType", tempData)
        this.rawDataCategoryByCount = tempData.map(obj => {
          return { productType: obj.productType, date: obj.name, compliance: obj.value };
        })
        let test: Array<Object> = [];
        let uniqueObjArray = [
          ...new Map(tempData.map((item) => [item["productType"], item])).values(),
        ];
        uniqueObjArray.forEach((el) => {
          test.push({
            "name": el["productType"] === null || el["productType"] === "" ? "None specified" : el["productType"],
            "series": tempData.filter((s) => s["productType"] === el["productType"])
          })

        })
        this.timeSeriesCategoryByCount = test;
        this.isLoadingTimeSeriesCategoryByCount = false;
      })
      .catch(error => {
        this.isLoadingTimeSeriesCategoryByCount = false;
        this.ShowErrorMessage(error.message);
      })
  }

  timeSeriesCategoryByWeight: Array<Object> = [];
  rawDataCategoryByWeight: Array<Object>;
  isLoadingTimeSeriesCategoryByWeight = false;
  getCategoryPerformanceWeight() {
    this.isLoadingTimeSeriesCategoryByWeight = true;
    let measure = "Formulas.brand_compliant_consumption_pct";
    if ((this.inditexCategoryControl.value && this.inditexCategoryControl.value.length !== 0)
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        measure = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption_pct"
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        measure = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption_pct"
      }
    }
    const query = {
      "measures": [
        measure
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date",
          "granularity": "month",
          "dateRange": [
            this.startDateString,
            this.endDateString
          ]
        }
      ],
      "order": [
        [
          "OrganizationProductType.productType",
          "asc"
        ]
      ],
      "segments": [
        "OrganizationProductType.only_brand"
      ],
      "filters": this.filters,
      "dimensions": [
        "Formulas.year_month_scan_date",
        "OrganizationProductType.productType"
      ]
    }
    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then((data) => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.year_month_scan_date", "name", tempData)
        tempData = this.changeKey(measure, "value", tempData)
        tempData = this.changeKey("OrganizationProductType.productType", "productType", tempData)
        this.rawDataCategoryByWeight = tempData.map(obj => {
          return { productType: obj.productType, date: obj.name, compliance: obj.value };
        })
        let newSeries: Array<Object> = [];
        let uniqueObjArray = [
          ...new Map(tempData.map((item) => [item["productType"], item])).values(),
        ];
        uniqueObjArray.forEach((el) => {
          newSeries.push({
            "name": el["productType"] === null || el["productType"] === "" ? "None specified" : el["productType"],
            "series": tempData.filter((s) => s["productType"] === el["productType"])
          })

        })
        this.timeSeriesCategoryByWeight = newSeries;
        this.isLoadingTimeSeriesCategoryByWeight = false;
      })
      .catch(error => {
        this.isLoadingTimeSeriesCategoryByWeight = false;
        this.ShowErrorMessage(error.message);
      })
  }

  getOverallPerformanceCount() {
    this.isLoadingTimeSeriesOverallByCount = true;
    let measure = "Formulas.brand_compliant_pct";
    if ((this.inditexCategoryControl.value && this.inditexCategoryControl.value.length !== 0)
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        measure = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_pct"
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        measure = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_pct"
      }
    }
    const query = {
      "measures": [
        measure
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date",
          "granularity": "month",
          "dateRange": [
            this.startDateString,
            this.endDateString
          ]
        }
      ],
      "order": [
        [
          "Formulas.country",
          "asc"
        ]
      ],
      "filters": this.filters,
      "dimensions": [
        "Formulas.year_month_scan_date",
        "Formulas.country"
      ]
    }
    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then((data) => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.year_month_scan_date", "name", tempData)
        tempData = this.changeKey(measure, "value", tempData)
        tempData = this.changeKey("Formulas.country", "country", tempData)
        this.rawDataOverallByCount = tempData.map(obj => {
          return { country: obj.country, date: obj.name, compliance: obj.value };
        })
        let test: Array<Object> = [];
        let uniqueObjArray = [
          ...new Map(tempData.map((item) => [item["country"], item])).values(),
        ];
        uniqueObjArray.forEach((el) => {
          test.push({
            "name": el["country"] === null || el["country"] === "" ? "None specified" : el["country"],
            "series": tempData.filter((s) => s["country"] === el["country"])
          })

        })
        this.timeSeriesOverallByCount = test;
        this.isLoadingTimeSeriesOverallByCount = false;
      })
      .catch(error => {
        this.isLoadingTimeSeriesOverallByCount = false;
        this.ShowErrorMessage(error.message);
      })
  }

  getOverallerformanceWeight() {
    this.isLoadingTimeSeriesOverallByWeight = true;
    let measure = "Formulas.brand_compliant_consumption_pct";
    if ((this.inditexCategoryControl.value && this.inditexCategoryControl.value.length !== 0)
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        measure = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption_pct"
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        measure = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption_pct"
      }
    }
    const query = {
      "measures": [
        measure
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date",
          "granularity": "month",
          "dateRange": [
            this.startDateString,
            this.endDateString
          ]
        }
      ],
      "order": [
        [
          "Formulas.country",
          "asc"
        ]
      ],
      "filters": this.filters,
      "dimensions": [
        "Formulas.year_month_scan_date",
        "Formulas.country"
      ]
    }
    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then((data) => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.year_month_scan_date", "name", tempData)
        tempData = this.changeKey(measure, "value", tempData)
        tempData = this.changeKey("Formulas.country", "country", tempData)
        this.rawDataOverallByWeight = tempData.map(obj => {
          return { country: obj.country, date: obj.name, compliance: obj.value };
        })
        let test: Array<Object> = [];
        let uniqueObjArray = [
          ...new Map(tempData.map((item) => [item["country"], item])).values(),
        ];
        uniqueObjArray.forEach((el) => {
          test.push({
            "name": el["country"] === null || el["country"] === "" ? "None specified" : el["country"],
            "series": tempData.filter((s) => s["country"] === el["country"])
          })

        })
        this.timeSeriesOverallByWeight = test;
        this.isLoadingTimeSeriesOverallByWeight = false;
      })
      .catch(error => {
        this.isLoadingTimeSeriesOverallByWeight = false;
        this.ShowErrorMessage(error.message);
      })
  }

  // #endregion

  // #region Chemical Information
  factoryScanInfo = ["zdhc_aid", "factoryName", "country", "firstScanDate", "lastScanMonth", "verifiedCount", "unverifiedCount", "totalCount", "complianceByCount", "complianceByWeight"]
  factoryScanInfoDataList = new MatTableDataSource<any>()
  isLoadingFactoryScanInfo = false;

  factoryLastScanInfo = ["zdhc_aid", "factoryName", "country", "lastScanMonth", "verifiedCount", "unverifiedCount", "totalCount", "complianceByCount", "complianceByWeight"]
  factoryLastInfoDataList = new MatTableDataSource<any>()
  isLoadingFactoryLastScanInfo = false;

  factoryRatingInfo = ["zdhc_aid", "factoryName", "complianceByCount", "Higg", "higgLastUploadDate", "wasteWaterResult1", "wasteWaterResult2"]
  factoryRatingInfoDataList = new MatTableDataSource<any>()
  isLoadingFactoryRatingInfo = false;

  topChemicals = ["chemicalName", "manufacturer", "usage", "factoriesCount"]
  topChemicalsDataList = new MatTableDataSource<any>()
  isLoadingTopChemicals = false;

  topVerifiedChemicals = ["chemicalName", "manufacturer", "usage", "factoriesCount"]
  topVerifiedChemicalsDataList = new MatTableDataSource<any>()
  isLoadingTopVerifiedChemicals = false;

  topManufacturers = ["manufacturer", "chemicalCount", "usage"]
  topManufacturersDataList = new MatTableDataSource<any>()
  isLoadingTopManufacturers = false;

  topUnverifiedChemicals = ["chemicalName", "manufacturer", "usage", "factoriesCount"]
  topUnverifiedChemicalsDataList = new MatTableDataSource<any>()
  isLoadingTopUnverifiedChemicals = false;

  topUnverifiedManufacturers = ["manufacturer", "usage", "factoriesCount"]
  topUnverifiedManufacturersDataList = new MatTableDataSource<any>()
  isLoadingTopUnverifiedManufacturers = false;

  compliancePerformance: Array<Object> = [];
  rawDataCompliancePerformance: Array<Object> = [];
  isLoadingCompliancePerformance = false;
  chartDataByCount: Array<Object> = [];
  isLoadingChartDataByCount = false;
  chartDataByWeight: Array<Object> = [];
  isLoadingChartDataByWeight = false;
  getFactoryScanInformation() {
    let formulaFilters = [];
    for (let obj of this.latestFormulas) {
      formulaFilters.push(obj.toString());
    }
    let measureCompliancePct = "Formulas.factory_compliant_pct";
    let measureComplianceCount = "Formulas.factory_compliance_count";
    let measureConsumption = "Formulas.brand_compliant_consumption_pct";
    if (this.inditexCategoryControl.value
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        measureCompliancePct = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_pct"
        measureComplianceCount = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_total_cnt"
        measureConsumption = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption_pct"
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        measureCompliancePct = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_pct"
        measureComplianceCount = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_total_cnt"
        measureConsumption = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption_pct"
      }
    }
    const query = {
      "dimensions": [
        "Formulas.organization_name",
        "Formulas.country",
        "Formulas.first_scan_date",
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date",

        }
      ],
      "measures": [
        measureComplianceCount,
        "Formulas.formula_distinct_count",
        "SupplierIdentification.zdhc_aid",
        measureCompliancePct,
        measureConsumption,
        "Formulas.latestScanMonth"

      ],
      "filters": [
        {
          "and": formulaFilters.length == 0 ? [
            ...this.filters,

          ] : [
            ...this.filters,
            {
              "member": 'Formulas.id',
              "operator": 'equals',
              "values": formulaFilters
            }

          ]
        }
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("SupplierIdentification.zdhc_aid", "zdhc_aid", tempData)
        tempData = this.changeKey("Formulas.organization_name", "factoryName", tempData)
        tempData = this.changeKey("Formulas.country", "country", tempData)
        tempData = this.changeKey("Formulas.first_scan_date", "firstScanDate", tempData)
        tempData = this.changeKey("Formulas.latestScanMonth", "lastScanMonth", tempData)
        tempData = this.changeKey(measureComplianceCount, "verifiedCount", tempData)
        tempData = this.changeKey("Formulas.formula_distinct_count", "totalCount", tempData)
        tempData = this.changeKey(measureCompliancePct, "complianceByCount", tempData)
        tempData = this.changeKey(measureConsumption, "complianceByWeight", tempData)
        tempData.forEach(obj => {
          obj['unverifiedCount'] = obj['totalCount'] - obj['verifiedCount'];
        })
        this.factoryScanInfoDataList.data = tempData;
        this.factoryScanInfoDataList.paginator = this.factoryScanInfoMatPaginator;
        this.factoryScanInfoDataList.sort = this.factoryScanInfoMatSort;
        this.isLoadingFactoryScanInfo = false;
      })

  }
  getFactoryLastScanInformation() {
    let formulaFilters = [];
    for (let obj of this.latestFormulas) {
      formulaFilters.push(obj.toString());
    }
    let lastScanFilters = this.filters.filter(el => el['member'] != "Formulas.scan_date")
    let measureCompliancePct = "Formulas.last_scan_month_compliant_cnt_pct";
    let measureComplianceCount = "Formulas.last_scan_month_compliant_count";
    let measureConsumption = "Formulas.last_scan_month_compliant_consumption_pct";
    if (this.inditexCategoryControl.value
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        measureCompliancePct = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_pct"
        measureComplianceCount = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_total_cnt"
        measureConsumption = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption_pct"
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        measureCompliancePct = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_pct"
        measureComplianceCount = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_total_cnt"
        measureConsumption = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption_pct"
      }
    }
    const query = {
      "dimensions": [
        "Formulas.organization_name",
        "Formulas.country",
        "Formulas.first_scan_date",
        "Formulas.last_scan_month"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "measures": [
        measureComplianceCount,
        measureCompliancePct,
        measureConsumption,
        "Formulas.last_scan_month_formula_count",
        "SupplierIdentification.zdhc_aid",
      ],
      "filters": [
        {
          "and": formulaFilters.length == 0 ? [
            ...this.filters,

          ] : [
            ...this.filters,
            {
              "member": 'Formulas.id',
              "operator": 'equals',
              "values": formulaFilters
            }

          ]
        }
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("SupplierIdentification.zdhc_aid", "zdhc_aid", tempData)
        tempData = this.changeKey("Formulas.organization_name", "factoryName", tempData)
        tempData = this.changeKey("Formulas.country", "country", tempData)
        tempData = this.changeKey("Formulas.last_scan_month", "lastScanMonth", tempData)
        tempData = this.changeKey(measureComplianceCount, "verifiedCount", tempData)
        tempData = this.changeKey("Formulas.last_scan_month_formula_count", "totalCount", tempData)
        tempData = this.changeKey(measureCompliancePct, "complianceByCount", tempData)
        tempData = this.changeKey(measureConsumption, "complianceByWeight", tempData)
        tempData.forEach(obj => {
          obj['unverifiedCount'] = obj['totalCount'] - obj['verifiedCount'];
        })
        this.factoryLastInfoDataList.data = tempData;
        this.factoryLastInfoDataList.paginator = this.factoryLastScanInfoMatPaginator;
        this.factoryLastInfoDataList.sort = this.factoryLastScanInfoMatSort;
        this.isLoadingFactoryLastScanInfo = false;
      })

  }
  latestFormulas = []
  getFormulasFirst() {
    this.isLoadingFactoryScanInfo = true;
    this.isLoadingFactoryLastScanInfo = true;
    this.isLoadingFactoryRatingInfo = true;
    let query = {
      "dimensions": [
        "Formulas.organization_id",
        "Formulas.chemical_identifier"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "order": [
        [
          "Formulas.chemical_identifier",
          "asc"
        ]
      ],
      "filters": [
        this.factoryControl.value == null ?
          {
            "member": "Formulas.scan_date",
            "operator": "inDateRange",
            "values": [
              this.startDateString,
              this.endDateString]
          } :
          {
            "and":
              [
                {

                  "member": "Formulas.scan_date",
                  "operator": "inDateRange",
                  "values": [
                    this.startDateString,
                    this.endDateString]
                },
                {
                  "member": "Formulas.organization_name",
                  "operator": "equals",
                  "values":
                    this.factoryControl.value

                }
              ]
          }

      ],
      "measures": [
        "Formulas.latest_formula_in_range"
      ]
    };
    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.latest_formula_in_range", "latest_formula", tempData);

        this.latestFormulas = tempData.map(function (el) { return el.latest_formula; });
        this.getFactoryScanInformation()
        this.getFactoryLastScanInformation()
        this.getFactoryRatingInfo()
        this.getSCPerfomanceByCount()
        this.getSCPerfomanceByWeight()

      })
  }

  getFormulasFirst1() {
    this.isLoadingChemicalInfo = true;
    this.isLoadingTopChemicals = true;
    this.isLoadingTopManufacturers = true;
    this.isLoadingTopUnverifiedChemicals = true;
    this.isLoadingTopUnverifiedManufacturers = true;
    this.isLoadingTopVerifiedChemicals = true;

    let query = {
      "dimensions": [
        "Formulas.organization_id",
        "Formulas.chemical_identifier"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "order": [
        [
          "Formulas.chemical_identifier",
          "asc"
        ]
      ],
      "filters": [
        this.factoryControl.value == null ?
          {
            "member": "Formulas.scan_date",
            "operator": "inDateRange",
            "values": [
              this.startDateString,
              this.endDateString]
          } :
          {
            "and":
              [
                {

                  "member": "Formulas.scan_date",
                  "operator": "inDateRange",
                  "values": [
                    this.startDateString,
                    this.endDateString]
                },
                {
                  "member": "Formulas.organization_name",
                  "operator": "equals",
                  "values":
                    this.factoryControl.value

                }
              ]
          }

      ],
      "measures": [
        "Formulas.latest_formula_in_range"
      ]
    };
    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.latest_formula_in_range", "latest_formula", tempData);

        this.latestFormulas = tempData.map(function (el) { return el.latest_formula; });
        this.getChemicalInformation()
        this.getTopChemicals()
        this.getTopManufacturers()
        this.getTopUnverifiedChemicals()
        this.getTopUnverifiedManufacturers()
        this.getTopVerifiedChemicals()

      })
  }

  getFactoryRatingInfo() {//compliance, higg and wastewater result
    let formulaFilters = [];
    for (let obj of this.latestFormulas) {
      formulaFilters.push(obj.toString());
    }
    // let std = new Date(this.startDate.value);
    // let edd = new Date(this.endDate.value);
    // this.startDateString = new Date(std.getFullYear(), std.getMonth(), std.getDate() + 1).toISOString().slice(0, 10);
    // this.endDateString = new Date(edd.getFullYear(), edd.getMonth(), edd.getDate() + 1).toISOString().slice(0, 10);
    const query = {
      "dimensions": [
        "Formulas.organization_name",
        "WasteWater.test_result",
        "Higg.latest",
        "Higg.id"
      ],
      "measures": [
        "SupplierIdentification.zdhc_aid",
        "Formulas.factory_compliant_pct",
        "Higg.higg_last_upload_date",
        "WasteWater_21.latest_test_report"
        // "Higg.latest_verf_total",
        // "Higg.latest_self_total",

      ],
      "filters": [
        {
          "or": [
            // {
            //   "member": "Formulas.scan_date",
            //   "operator": "inDateRange",
            //   "values": [
            //     this.startDateString,
            //     this.endDateString
            //   ]

            // },
            // {
            //   "member": "Higg.self_assessment_date",
            //   "operator": "inDateRange",
            //   "values": [
            //     this.startDateString,
            //     this.endDateString
            //   ]

            // },
            // {
            //   "member": "WasteWater.document_date",
            //   "operator": "inDateRange",
            //   "values": [
            //     this.startDateString,
            //     this.endDateString
            //   ]

            // },
            // {
            //   "member": "Higg.veri_assessment_date",
            //   "operator": "inDateRange",
            //   "values": [
            //     this.startDateString,
            //     this.endDateString
            //   ]
            // },
            {
              "member": "Higg.latest",
              "operator": "notEquals",
              "values": [
                '0'
              ]
            }

          ]
        },
        {
          "and": formulaFilters.length == 0 ? [
            ...this.filters,

          ] : [
            ...this.filters,
            {
              "member": 'Formulas.id',
              "operator": 'equals',
              "values": formulaFilters
            }

          ]
        }
      ]
    }
    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then(data => {
        let result = data['loadResponse']['results'][0]['data'];
        result = this.changeKey("SupplierIdentification.zdhc_aid", "zdhc_aid", result)
        result = this.changeKey("Formulas.organization_name", "factoryName", result)
        // result = this.changeKey("Factories.country_name", "country", result)
        result = this.changeKey("Formulas.factory_compliant_pct", "complianceByCount", result)
        // result = this.changeKey("Higg.latest_verf_total", "higgVerifiedPerformance", result)
        // result = this.changeKey("Higg.latest_self_total", "higgSelfPerformance", result)
        result = this.changeKey("WasteWater.test_result", "wasteWaterResult1", result)
        result = this.changeKey("WasteWater_21.latest_test_report", "wasteWaterResult2", result)
        result = this.changeKey("Higg.latest", "Higg", result)
        result = this.changeKey("Higg.higg_last_upload_date", "higgLastUploadDate", result)
        result = this.changeKey("Higg.id", "higgID", result)

        result = result.map(obj => {
          if (obj.wasteWaterResult2 != null)
            obj.wasteWaterResult2 = obj.wasteWaterResult2.split(' ')[0];
          // obj.HiggSelfLevel = obj.higgSelfPerformance != null && obj.higgSelfPerformance <= 0 ? 'NA' : obj.higgSelfPerformance != null && obj.higgSelfPerformance > 0 && obj.higgSelfPerformance <= 25 ? 'Level 1' : obj.higgSelfPerformance != null && obj.higgSelfPerformance > 25 && obj.higgSelfPerformance <= 75 ? 'Level 2 ' : obj.higgSelfPerformance != null && obj.higgSelfPerformance > 75 && obj.higgSelfPerformance <= 100 ? 'Level 3' : '';
          // obj.HiggVerfLevel = obj.higgVerifiedPerformance != null && obj.higgVerifiedPerformance <= 0 ? 'NA' : obj.higgVerifiedPerformance != null && obj.higgVerifiedPerformance > 0 && obj.higgVerifiedPerformance <= 25 ? 'Level 1' : obj.higgVerifiedPerformance != null && obj.higgVerifiedPerformance > 25 && obj.higgVerifiedPerformance <= 75 ? 'Level 2 ' : obj.higgVerifiedPerformance != null && obj.higgVerifiedPerformance > 75 && obj.higgVerifiedPerformance <= 100 ? 'Level 3' : '';
          if (obj.wasteWaterResult1 == 1)
            obj.wasteWaterResult1 = 'Passed';
          else if (obj.wasteWaterResult1 == 0)
            obj.wasteWaterResult1 = 'Failed';
          if (obj.Higg == 1)
            obj.Higg = 'Yes';
          else
            obj.Higg = 'No';

          return obj;
        })
        this.factoryRatingInfoDataList.data = result;
        this.isLoadingFactoryRatingInfo = false;
        // this.isLoading = false;
        this.factoryRatingInfoDataList.paginator = this.factoryRatingInfoMatPaginator;
        this.factoryRatingInfoDataList.sort = this.factoryRatingInfoMatSort;
      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })

  }

  getComplianceSeries() {
    this.isLoadingCompliancePerformance = true;
    let measureCompliancePct = "Formulas.brand_compliant_pct";
    let measureConsumption = "Formulas.brand_compliant_consumption_pct";
    if (this.inditexCategoryControl.value
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        measureCompliancePct = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_pct"
        measureConsumption = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption_pct"
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        measureCompliancePct = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_pct"
        measureConsumption = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption_pct"
      }
    }
    const query = {
      "measures": [
        measureCompliancePct,
        measureConsumption
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date",
          "granularity": "month",
          "dateRange": [
            this.startDateString,
            this.endDateString
          ]
        }
      ],
      "order": {
      },
      "filters": this.filters,
      "dimensions": [
        "Formulas.year_month_scan_date"
      ]
    }
    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData.forEach(obj => {
          if (obj['Formulas.brand_compliant_consumption_pct'] == null) {
            obj['Formulas.brand_compliant_consumption_pct'] = 0;
          }
        })
        tempData = this.changeKey("Formulas.year_month_scan_date", "name", tempData)
        tempData = this.changeKey(measureCompliancePct, "complianceByCount", tempData)
        tempData = this.changeKey(measureConsumption, "complianceByWeight", tempData)
        this.rawDataCompliancePerformance = tempData.map(obj => {
          return { date: obj.name, complianceByCount: obj.complianceByCount, complianceByWeight: obj.complianceByWeight };
        })
        let countSeries = {
          "name": "Compliance By Count",
          "series": []
        };
        let weightSeries = {
          "name": "Compliance By Weight",
          "series": []
        };
        tempData.forEach(obj => {
          countSeries["series"].push({
            "name": obj["name"],
            "value": obj["complianceByCount"]
          });
          weightSeries["series"].push({
            "name": obj["name"],
            "value": obj["complianceByWeight"]
          });
        })
        this.compliancePerformance = [countSeries, weightSeries];
        this.isLoadingCompliancePerformance = false;
      })
  }
  nonInditexFilter() {
    let inditexFilters: Array<Object> = [];
    if (this.inditexCategoryControl.value
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        inditexFilters.push(
          {
            "member": "Formulas.inditex_AboveThreeYears",
            "operator": "notContains",
            "values": this.inditexLevelControl.value.map(obj => obj.name)
          }
        )
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        inditexFilters.push(
          {
            "member": "Formulas.inditex_BelowThreeYears",
            "operator": "notContains",
            "values": this.inditexLevelControl.value.map(obj => obj.name)
          }
        )
      }
    }
    return inditexFilters;
  }
  getTopChemicals() {

    let formulaFilters = [];
    for (let obj of this.latestFormulas) {
      formulaFilters.push(obj.toString());
    }
    const query = {
      "dimensions": [
        "Formulas.chemical_identifier",
        "Formulas.chemical_name",
        "Formulas.chemical_manufacturer",
        "Formulas.is_gateway_manufacturer"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "measures": [
        "Formulas.total_consumption",
        "Formulas.count_factories_using",
      ],
      "filters": [
        {
          "and": formulaFilters.length == 0 ? [
            ...this.filters,

          ] : [
            ...this.filters,
            {
              "member": 'Formulas.id',
              "operator": 'equals',
              "values": formulaFilters
            }


          ]
        }
      ],

      "order": [
        [
          "Formulas.total_consumption",
          "desc"
        ],
        [
          "Formulas.count_factories_using",
          "desc"
        ]
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.chemical_name", "chemicalName", tempData)
        tempData = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", tempData)
        tempData = this.changeKey("Formulas.total_consumption", "usage", tempData)
        tempData = this.changeKey("Formulas.count_factories_using", "factoriesCount", tempData)
        tempData = this.changeKey("Formulas.is_gateway_manufacturer", "isGateway", tempData)
        tempData = tempData.map(obj => {
          if (obj.isGateway > 0) {
            tempData.filter(el => el.manufacturer == obj.manufacturer).forEach(i => i.isGateway = 1)
          }
          else
            obj.isGateway = 0;

          return obj;
        });
        tempData = tempData.filter(obj => {
          delete obj["Formulas.chemical_identifier"];
          return true;
        })
        this.topChemicalsDataList.data = tempData;
        this.topChemicalsDataList.paginator = this.topChemicalsMatPaginator;
        this.topChemicalsDataList.sort = this.topChemicalsMatSort;
        this.isLoadingTopChemicals = false;
      })
  }
  getTopManufacturers() {

    const query = {
      "dimensions": [
        "Formulas.chemical_manufacturer",
        "Formulas.is_gateway_manufacturer"
        // "Formulas.zdhc_manufacturer_partner"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "measures": [
        "Formulas.formula_distinct_count",
        "Formulas.total_consumption",
      ],
      "filters": [...this.filters,
        // ...this.inditexFilter()
      ],
      "order": [
        [
          "Formulas.formula_distinct_count",
          "desc"
        ]
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", tempData)
        // tempData = this.changeKey("Formulas.zdhc_manufacturer_partner", "partner", tempData)
        tempData = this.changeKey("Formulas.formula_distinct_count", "chemicalCount", tempData)
        tempData = this.changeKey("Formulas.total_consumption", "usage", tempData)
        tempData = this.changeKey("Formulas.is_gateway_manufacturer", "isGateway", tempData)
        tempData = tempData.map(obj => {
          if (obj.isGateway > 0) {
            tempData.filter(el => el.manufacturer == obj.manufacturer).forEach(i => i.isGateway = 1)
          }
          else
            obj.isGateway = 0;

          return obj;
        });
        this.topManufacturersDataList.data = tempData;
        this.topManufacturersDataList.paginator = this.topManufacturersMatPaginator;
        this.topManufacturersDataList.sort = this.topManufacturersMatSort;
        this.isLoadingTopManufacturers = false;
      })
  }
  getTopUnverifiedChemicals() {

    let formulaFilters = [];
    for (let obj of this.latestFormulas) {
      formulaFilters.push(obj.toString());
    }
    let segment = [];
    let excludeChemicals = this.nonInditexFilter();
    if (excludeChemicals.length === 0)
      segment = ["Formulas.non_compliant_with_commodities_cr"];
    const query = {
      "dimensions": [
        "Formulas.chemical_identifier",
        "Formulas.chemical_name",
        "Formulas.chemical_manufacturer",
        "Formulas.is_gateway_manufacturer"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "measures": [
        "Formulas.total_consumption",
        "Formulas.count_factories_using",
      ],
      "segments":
        segment
      ,
      "filters": [
        {
          "and": formulaFilters.length == 0 ? [
            ...this.filters,
            ...excludeChemicals

          ] : [
            ...this.filters,
            ...excludeChemicals,
            {
              "member": 'Formulas.id',
              "operator": 'equals',
              "values": formulaFilters
            }
          ]
        }
      ],
      "order": [
        [
          "Formulas.total_consumption",
          "desc"
        ],
        [
          "Formulas.count_factories_using",
          "desc"
        ]
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.chemical_name", "chemicalName", tempData)
        tempData = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", tempData)
        tempData = this.changeKey("Formulas.total_consumption", "usage", tempData)
        tempData = this.changeKey("Formulas.count_factories_using", "factoriesCount", tempData)
        tempData = this.changeKey("Formulas.is_gateway_manufacturer", "isGateway", tempData)
        tempData = tempData.map(obj => {
          if (obj.isGateway > 0) {
            tempData.filter(el => el.manufacturer == obj.manufacturer).forEach(i => i.isGateway = 1)
          }
          else
            obj.isGateway = 0;

          return obj;
        });
        tempData = tempData.filter(obj => {
          delete obj["Formulas.chemical_identifier"];
          return true;
        })
        this.topUnverifiedChemicalsDataList.data = tempData;
        this.topUnverifiedChemicalsDataList.paginator = this.topUnverifiedChemicalsMatPaginator;
        this.topUnverifiedChemicalsDataList.sort = this.topUnverifiedChemicalsMatSort;
        this.isLoadingTopUnverifiedChemicals = false;
      })
  }
  getTopUnverifiedManufacturers() {

    let segment = [];
    let excludeChemicals = this.nonInditexFilter();
    if (excludeChemicals.length === 0)
      segment = ["Formulas.non_compliant_with_commodities_cr"];
    const query = {
      "dimensions": [
        "Formulas.chemical_manufacturer",
        // "Formulas.zdhc_manufacturer_partner",
        "Formulas.manufacturer_identifier",
        "Formulas.is_gateway_manufacturer"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "measures": [
        "Formulas.count_factories_using",
        "Formulas.total_consumption",
      ],
      "segments":
        segment
      ,
      "filters": [...this.filters,
      ...excludeChemicals],
      "order": [
        [
          "Formulas.total_consumption",
          "desc"
        ],
        [
          "Formulas.count_factories_using",
          "desc"
        ]
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", tempData)
        // tempData = this.changeKey("Formulas.zdhc_manufacturer_partner", "partner", tempData)
        tempData = this.changeKey("Formulas.count_factories_using", "factoriesCount", tempData)
        tempData = this.changeKey("Formulas.total_consumption", "usage", tempData)
        tempData = this.changeKey("Formulas.is_gateway_manufacturer", "isGateway", tempData)
        tempData = tempData.map(obj => {
          if (obj.isGateway > 0) {
            tempData.filter(el => el.manufacturer == obj.manufacturer).forEach(i => i.isGateway = 1)
          }
          else
            obj.isGateway = 0;

          return obj;
        });
        this.topUnverifiedManufacturersDataList.data = tempData;
        this.topUnverifiedManufacturersDataList.paginator = this.topUnverifiedManufacturersMatPaginator;
        this.topUnverifiedManufacturersDataList.sort = this.topUnverifiedManufacturersMatSort;
        this.isLoadingTopUnverifiedManufacturers = false;
      })
  }
  getSCPerfomanceByCount() {
    this.isLoadingChartDataByCount = true;
    let formulaFilters = [];
    for (let obj of this.latestFormulas) {
      formulaFilters.push(obj.toString());
    }
    let measureComplianceCount = "Formulas.factory_compliance_count";
    if (this.inditexCategoryControl.value
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        measureComplianceCount = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_total_cnt"
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        measureComplianceCount = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_total_cnt"
      }
    }
    const query = {
      "measures": [
        "Formulas.formula_distinct_count",
        measureComplianceCount
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "filters": [
        {
          "and": formulaFilters.length == 0 ? [
            ...this.filters,
            // {
            //   "member": 'Formulas.basic_chemical',
            //   "operator": 'equals',
            //   "values": ['0'],
            // }
          ] : [
            ...this.filters,
            {
              "member": 'Formulas.id',
              "operator": 'equals',
              "values": formulaFilters
            },
            // {
            //   "member": 'Formulas.basic_chemical',
            //   "operator": 'equals',
            //   "values": ['0'],
            // }
          ]
        }
      ],
    }
    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey(measureComplianceCount, "complianceCount", tempData);
        tempData = this.changeKey("Formulas.formula_distinct_count", "totalCount", tempData);
        this.chartDataByCount = [
          { 'name': 'Verified', 'value': tempData[0].complianceCount },
          { 'name': 'Unverified', 'value': tempData[0].totalCount - tempData[0].complianceCount }
        ];
        this.isLoadingChartDataByCount = false;
      })
  }
  getSCPerfomanceByWeight() {
    this.isLoadingChartDataByWeight = true;
    let measureConsumption = "Formulas.brand_compliance_consumption";
    if (this.inditexCategoryControl.value
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        measureConsumption = "Formulas.inditexAboveThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption"
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        measureConsumption = "Formulas.inditexBelowThreeYears_" + this.inditexLevelControl.value.map(o => o.name).join('') + "_consumption"
      }
    }
    let formulaFilters = [];
    for (let obj of this.latestFormulas) {
      formulaFilters.push(obj.toString());
    }
    const query = {
      "measures": [
        "Formulas.total_consumption",
        measureConsumption
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "filters": [
        {
          "and": formulaFilters.length == 0 ? [
            ...this.filters,
            // {
            //   "member": 'Formulas.basic_chemical',
            //   "operator": 'equals',
            //   "values": ['0'],
            // }

          ] : [
            ...this.filters,
            // {
            //   "member": 'Formulas.basic_chemical',
            //   "operator": 'equals',
            //   "values": ['0'],
            // },
            {
              "member": 'Formulas.id',
              "operator": 'equals',
              "values": formulaFilters
            }
          ]
        }
      ],
    }
    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData.forEach(obj => {
          if (obj['Formulas.brand_compliance_consumption'] == null) {
            obj['Formulas.brand_compliance_consumption'] = 0;
          }
        })
        tempData = this.changeKey(measureConsumption, "complianceConsumption", tempData);
        tempData = this.changeKey("Formulas.total_consumption", "totalConsumption", tempData);
        this.chartDataByWeight = [
          { 'name': 'Verified', 'value': tempData[0].complianceConsumption },
          { 'name': 'Unverified', 'value': tempData[0].totalConsumption - tempData[0].complianceConsumption }
        ];
        this.isLoadingChartDataByWeight = false;
      })
  }

  getTopVerifiedChemicals() {

    let formulaFilters = [];
    for (let obj of this.latestFormulas) {
      formulaFilters.push(obj.toString());
    }
    let segment = [];
    let excludeChemicals = this.nonInditexFilter();
    if (excludeChemicals.length === 0)
      segment = ["Formulas.compliant_cr"];
    const query = {
      "dimensions": [
        "Formulas.chemical_identifier",
        "Formulas.chemical_name",
        "Formulas.chemical_manufacturer",
        "Formulas.is_gateway_manufacturer"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "measures": [
        "Formulas.total_consumption",
        "Formulas.count_factories_using",
      ],
      "segments":
        segment
      ,
      "filters": [
        {
          "and": formulaFilters.length == 0 ? [
            ...this.filters,
            ...excludeChemicals

          ] : [
            ...this.filters,
            ...excludeChemicals,
            {
              "member": 'Formulas.id',
              "operator": 'equals',
              "values": formulaFilters
            }
          ]
        }
      ],
      "order": [
        [
          "Formulas.total_consumption",
          "desc"
        ],
        [
          "Formulas.count_factories_using",
          "desc"
        ]
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.chemical_name", "chemicalName", tempData)
        tempData = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", tempData)
        tempData = this.changeKey("Formulas.total_consumption", "usage", tempData)
        tempData = this.changeKey("Formulas.count_factories_using", "factoriesCount", tempData)
        tempData = this.changeKey("Formulas.is_gateway_manufacturer", "isGateway", tempData)
        tempData = tempData.map(obj => {
          if (obj.isGateway > 0) {
            tempData.filter(el => el.manufacturer == obj.manufacturer).forEach(i => i.isGateway = 1)
          }
          else
            obj.isGateway = 0;

          return obj;
        });
        tempData = tempData.filter(obj => {
          delete obj["Formulas.chemical_identifier"];
          return true;
        })
        this.topVerifiedChemicalsDataList.data = tempData;
        this.topVerifiedChemicalsDataList.paginator = this.topVerifiedChemicalsMatPaginator;
        this.topVerifiedChemicalsDataList.sort = this.topVerifiedChemicalsMatSort;
        this.isLoadingTopVerifiedChemicals = false;
      })
  }
  // #endregion

  // #region Factory Performance
  chemicalInfo = ["id", "factoryName", "chemicalName", "manufacturer", "consumption", "complianceStatus", "factoryUsage"]
  chemicalInfoDataList = new MatTableDataSource<any>()
  isLoadingChemicalInfo = false;
  includeInditexFilter() {
    let inditexFilters: Array<Object> = [];
    if (this.inditexCategoryControl.value
      && (this.inditexLevelControl.value && this.inditexLevelControl.value.length !== 0)) {
      if (this.inditexCategoryControl.value.name.toLowerCase() == "above 3 years") {
        inditexFilters.push(
          {
            "member": "Formulas.inditex_AboveThreeYears",
            "operator": "contains",
            "values": this.inditexLevelControl.value.map(obj => obj.name)
          }
        )
      }
      if (this.inditexCategoryControl.value.name.toLowerCase() == "below 3 years") {
        inditexFilters.push(
          {
            "member": "Formulas.inditex_BelowThreeYears",
            "operator": "contains",
            "values": this.inditexLevelControl.value.map(obj => obj.name)
          }
        )
      }
    }
    return inditexFilters;
  }
  getChemicalInformation() {

    let formulaFilters = [];
    for (let obj of this.latestFormulas) {
      formulaFilters.push(obj.toString());
    }
    let excludeChemicals = this.includeInditexFilter();
    const query = {
      "dimensions": [
        "Formulas.organization_name",
        "Formulas.chemical_name",
        "Formulas.chemical_manufacturer",
        "Formulas.is_compliant",
        "Formulas.factory_usage",
        "Formulas.is_gateway_manufacturer",
        "Formulas.id",
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "measures": [
        "Formulas.max_consumption",
        // "Formulas.max_ending_stock",
      ],
      "filters": [
        {
          "and": formulaFilters.length == 0 ? [
            ...this.filters,
            ...excludeChemicals,
          ] :
            [
              ...this.filters,
              ...excludeChemicals,
              {
                "member": 'Formulas.id',
                "operator": 'equals',
                "values": formulaFilters
              }

            ]
        }
      ],
      "order": [
        [
          "Formulas.is_compliant",
          "asc"
        ],
        [
          "Formulas.chemical_name",
          "asc"
        ]
      ]
    }
    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, this.commoditiesControl.value ? true : false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Formulas.id", "id", tempData)
        tempData = this.changeKey("Formulas.organization_name", "factoryName", tempData)
        tempData = this.changeKey("Formulas.chemical_name", "chemicalName", tempData)
        tempData = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", tempData)
        tempData = this.changeKey("Formulas.max_consumption", "consumption", tempData)
        // tempData = this.changeKey("Formulas.max_ending_stock", "endingStock", tempData)
        tempData = this.changeKey("Formulas.is_compliant", "complianceStatus", tempData)
        tempData = this.changeKey("Formulas.factory_usage", "factoryUsage", tempData)
        tempData = this.changeKey("Formulas.is_gateway_manufacturer", "isGateway", tempData)
        tempData = tempData.map(obj => {
          if (obj.isGateway > 0) {
            tempData.filter(el => el.manufacturer == obj.manufacturer).forEach(i => i.isGateway = 1)
          }       
          else
            obj.isGateway = 0;
          if (obj.consumption != 'NA' && obj.consumption != null)
            obj.consumption = Number(parseFloat(obj.consumption).toFixed(2));
          return obj;
        });
        this.chemicalInfoDataList.data = tempData;
        this.chemicalInfoDataList.paginator = this.chemicalInfoMatPaginator;
        this.chemicalInfoDataList.sort = this.chemicalInfoMatSort;
        this.isLoadingChemicalInfo = false;
      })

  }
  // #endregion

  // #region Activity 
  createdAccountsCount: Number;
  isLoadingCreatedAccountsCount = false;
  getCreatedAccountsCount() {
    this.isLoadingCreatedAccountsCount = true;
    const query = {
      "measures": [
        "BrandSuppliers.total_count"
      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [],
      "filters": [
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.createdAccountsCount = data[0]['BrandSuppliers.total_count']
      this.isLoadingCreatedAccountsCount = false;
    })
      .catch(error => {
        this.isLoadingCreatedAccountsCount = false;
        this.ShowErrorMessage(error.message);
      })
  }
  isLoadingConnectedFactories = false;
  getConnectedFactoriesCount() {
    this.isLoadingConnectedFactories = true;
    const query = {
      "measures": [
        "BrandSuppliers.connected_count"
      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [],
      "filters": [
        // {
        //   "member": "Formulas.scan_date",
        //   "operator": "inDateRange",
        //   "values": [
        //     this.startDateString,
        //     this.endDateString
        //   ]
        // },
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.connectedFactories = data[0]['BrandSuppliers.connected_count']
      this.isLoadingConnectedFactories = false;
    })
      .catch(error => {
        this.isLoadingConnectedFactories = false;
        this.ShowErrorMessage(error.message);
      })
  }
  scanFactoriesCount: Number;
  isLoadingScanFactoriesCount = false;
  getScanFactoriesCount() {
    this.isLoadingScanFactoriesCount = true;
    const query = {
      "measures": [
        "Activity.scanned_factories_count"
      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [
        "BrandSuppliers.brand_id"
      ],
      "filters": [
      ],
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, false).then(data => {
      let result = data['loadResponse']['results'][0]['data']
      this.scanFactoriesCount = result.length !== 0 ? result[0]['Activity.scanned_factories_count'] : 0;
      this.isLoadingScanFactoriesCount = false;
    })
      .catch(error => {
        this.isLoadingScanFactoriesCount = false;
        this.ShowErrorMessage(error.message);
      })
  }

  currentScanFactoriesCount: Number;
  isLoadingCurrentScanFactoriesCount = false;
  getCurrentScanFactoriesCount() {
    this.isLoadingCurrentScanFactoriesCount = true;
    let sdt = moment().startOf('month').format('YYYY-MM-DD');
    let edt = moment().endOf('month').format('YYYY-MM-DD');
    const query = {
      "measures": [
        "Activity.current_month_scanned_factories_count"
      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [
        "BrandSuppliers.brand_id"
      ],
      "filters": [
        // {
        //   "member": "Activity.scan_date",
        //   "operator": "inDateRange",
        //   "values": [
        //     sdt,
        //     edt
        //   ]
        // }
      ],
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, false).then(data => {
      let result = data['loadResponse']['results'][0]['data'];
      this.currentScanFactoriesCount = result.length !== 0 ? result[0]['Activity.current_month_scanned_factories_count'] : 0;
      this.isLoadingCurrentScanFactoriesCount = false;
    })
      .catch(error => {
        this.isLoadingCurrentScanFactoriesCount = false;
        this.ShowErrorMessage(error.message);
      })
  }

  incheckFactoriesCount: Number;
  isLoadingIncheckFactoriesCount = false;
  getIncheckChemicalCount() {
    this.isLoadingIncheckFactoriesCount = true;
    const query = {
      "measures": [
        "DocumentCenter.incheck_factory_count"
      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [
        "BrandSuppliers.brand_id"
      ],
      "filters": [
        // {
        //   "member": "Formulas.scan_date",
        //   "operator": "inDateRange",
        //   "values": [
        //     this.startDateString,
        //     this.endDateString
        //   ]
        // },
        // {
        //   "member": "BrandSuppliers.brand_id",
        //   "operator": "equals",
        //   "values": [this.organizationId.toString()],
        // }
      ],
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.incheckFactoriesCount = data[0]['DocumentCenter.incheck_factory_count']
      this.isLoadingIncheckFactoriesCount = false;
    })
      .catch(error => {
        this.isLoadingIncheckFactoriesCount = false;
        this.ShowErrorMessage(error.message);
      })
  }
  wasteWaterFactoriesCount: Number;
  isLoadingWasteWaterFactoriesCount = false;
  getWasteWaterChemicalCount() {
    this.isLoadingWasteWaterFactoriesCount = true;
    const query = {
      "measures": [
        "DocumentCenter.waste_water_factory_count"
      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [
        "BrandSuppliers.brand_id"
      ],
      "filters": [
        // {
        //   "member": "Formulas.scan_date",
        //   "operator": "inDateRange",
        //   "values": [
        //     this.startDateString,
        //     this.endDateString
        //   ]
        // },
        // {
        //   "member": "BrandSuppliers.brand_id",
        //   "operator": "equals",
        //   "values": [this.organizationId.toString()],
        // }
      ],
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.wasteWaterFactoriesCount = data[0]['DocumentCenter.waste_water_factory_count']
      this.isLoadingWasteWaterFactoriesCount = false;
    })
      .catch(error => {
        this.ShowErrorMessage(error.message);
        this.isLoadingWasteWaterFactoriesCount = false;
      })
  }
  higgFactoriesCount: Number;
  isLoadingHiggFactoriesCount = false;
  getHiggChemicalCount() {
    this.isLoadingHiggFactoriesCount = true;
    const query = {
      "measures": [
        "Higg.higg_factory_count"
      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [
        "BrandSuppliers.brand_id"
      ],
      "filters": [
        // {
        //   "member": "Formulas.scan_date",
        //   "operator": "inDateRange",
        //   "values": [
        //     this.startDateString,
        //     this.endDateString
        //   ]
        // },
        // {
        //   "member": "BrandSuppliers.brand_id",
        //   "operator": "equals",
        //   "values": [this.organizationId.toString()],
        // }
      ],
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.higgFactoriesCount = data[0]['Higg.higg_factory_count']
      this.isLoadingHiggFactoriesCount = false;
    })
      .catch(error => {
        this.isLoadingHiggFactoriesCount = false;
        this.ShowErrorMessage(error.message);

      })
  }
  activityInfo = [
    "supplierID", "zdhc_AID", "in_out_scope", "factoryName", "email", "country", "organizationType", "factoryActivity",
    "connectionType", "lastScanDate", "lastScanMonthChemCount", "completedScan",
    "gatewaySubscription", "incheckTotalCount", "incheckLatestMonth", "wasteWaterCount1", "wasteWaterCount2", "higgTotalCount"
  ]
  activityInfoDataList = new MatTableDataSource<any>()
  isLoadingActivityInfo = false;

  getActivityMonitoring() {
    this.isLoadingActivityInfo = true;
    let activity_monitoring_filters: Array<Object> = [];
    // if (this.productActivityTypeControl.value && this.productActivityTypeControl.value.length !== 0) {
    //   activity_monitoring_filters.push(
    //     {
    //       "member": "OrganizationProductType.productType",
    //       "operator": "equals",
    //       "values": this.productActivityTypeControl.value.map(obj => obj.name)
    //     }
    //   )
    // }
    if (this.countryActivityControl.value && this.countryActivityControl.value.length !== 0) {
      activity_monitoring_filters.push(
        {
          "member": "BrandSuppliers.country_name",
          "operator": "equals",
          "values": this.countryActivityControl.value
        }
      )
    }
    if (this.factoryActivityControl.value && this.factoryActivityControl.value.length !== 0) {
      activity_monitoring_filters.push(
        {
          "member": "BrandSuppliers.organization_name",
          "operator": "equals",
          "values": this.factoryActivityControl.value
        }
      )
    }
    // if (this.scopeActivityCategoryControl.value && this.scopeActivityCategoryControl.value === "IN_SCOPE") {
    //   activity_monitoring_filters.push(
    //     {
    //         "member": "SupplierIdentification.in_out_scope",
    //         "operator": "equals",
    //         "values": ['IN_SCOPE']
    //       }
    //   )
    // }
    // if (this.scopeActivityCategoryControl.value && this.scopeActivityCategoryControl.value === "OUT_SCOPE") {
    //   activity_monitoring_filters.push(
    //     {
    //         "member": "SupplierIdentification.in_out_scope",
    //         "operator": "equals",
    //         "values": [null]
    //       }
    //   )
    // }
    activity_monitoring_filters.push(
      {
        "member": "BrandSuppliers.brand_id",
        "operator": "equals",
        "values": [this.organizationId.toString()]
      })
    const query = {
      "dimensions": [
        "Activity.organization_id",
        "BrandSuppliers.organization_name",
        "BrandSuppliers.country_name",
        "Activity.email",
        "Activity.organization_type",
        "Activity.activity",
        "Activity.confirmation_of_completeness",
        "Activity.last_scan_date",
        "Activity.inventory_formula_count",
        "Activity.gateway_subscription_status",
        "Activity.waste_water_result_hide",
        "Activity.zdhc_aid",
        "Activity.in_out_scope",
        "Activity.supplier_identification",
        "Activity.incheck_count",
        "Activity.incheck_last_date",
        "Activity.waste_water_count",
        "Activity.waste_water_2_1",
        "Activity.has_higg",
        "BrandSuppliers.connection_type"
      ],
      "timeDimensions": [
      ],
      "measures": [
      ],
      "filters": activity_monitoring_filters,
      "order": [
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("Activity.organization_id", "bhiveID", tempData)
        tempData = this.changeKey("Activity.supplier_identification", "supplierID", tempData)
        tempData = this.changeKey("Activity.zdhc_aid", "zdhc_AID", tempData)
        tempData = this.changeKey("Activity.in_out_scope", "in_out_scope", tempData)
        tempData = this.changeKey("BrandSuppliers.organization_name", "factoryName", tempData)
        tempData = this.changeKey("Activity.email", "email", tempData)
        tempData = this.changeKey("BrandSuppliers.country_name", "country", tempData)
        tempData = this.changeKey("Activity.last_scan_date", "lastScanDate", tempData)
        tempData = this.changeKey("Activity.gateway_subscription_status", "gatewaySubscription", tempData)
        tempData = this.changeKey("Activity.inventory_formula_count", "lastScanMonthChemCount", tempData)
        tempData = this.changeKey("Activity.confirmation_of_completeness", "completedScan", tempData)
        tempData = this.changeKey("Activity.organization_type", "organizationType", tempData)
        tempData = this.changeKey("Activity.activity", "factoryActivity", tempData)
        tempData = this.changeKey("BrandSuppliers.connection_type", "connectionType", tempData)
        tempData = this.changeKey("Activity.incheck_last_date", "incheckLatestMonth", tempData)
        tempData = this.changeKey("Activity.incheck_count", "incheckTotalCount", tempData)
        tempData = this.changeKey("Activity.waste_water_count", "wasteWaterCount1", tempData)
        tempData = this.changeKey("Activity.waste_water_2_1", "wasteWaterCount2", tempData)
        tempData = this.changeKey("Activity.has_higg", "higgTotalCount", tempData)
        tempData = this.changeKey("Activity.waste_water_result_hide", "wasteWaterResultHide", tempData)
        var seen = {};//merge factories that are nomianted and connected at the same time
        let dblData = tempData.filter(function (entry) {
          var previous;

          // Have we seen this label before?
          if (seen.hasOwnProperty(entry.bhiveID)) {
            // Yes, grab it and add this data to it
            previous = seen[entry.bhiveID];
            previous.connectionType = previous.connectionType.concat('/', entry.connectionType);

            // Don't keep this entry, we've merged it into the previous one
            return false;
          }

          // Remember that we've seen it
          seen[entry.bhiveID] = entry;

          // Keep this one, we'll merge any others that match into it
          return true;
        });
        dblData = dblData.map(obj => {
          if (obj.gatewaySubscription == 2)
            obj.gatewaySubscription = 'Yes';
          else
            obj.gatewaySubscription = 'No';

          if (obj.completedScan == 1)
            obj.completedScan = 'Complete';
          else
            obj.completedScan = 'Incomplete';

          if (obj.organizationType == 2)
            obj.organizationType = 'Supplier';
          else if (obj.organizationType == 4)
            obj.organizationType = 'Association';
          else
            obj.organizationType = 'Monitoring';

          // if (obj.wasteWaterResultHide == 1)
          //   obj.wasteWaterCount1 = "NA"
          // else
          obj.wasteWaterCount1 = (obj.wasteWaterCount1 == 0 || obj.wasteWaterCount1 == null) ? obj.wasteWaterResultHide == 1 ? "NA" : "No" : "Yes";
          if (obj.in_out_scope != "IN_SCOPE")
            obj.in_out_scope = "Out Scope"
          else
            obj.in_out_scope = "In Scope";

          return obj;
        })
        if (this.scopeActivityCategoryControl.value && this.scopeActivityCategoryControl.value === "IN_SCOPE")
          dblData = dblData.filter(obj => obj.in_out_scope === 'In Scope');
        else {
          if (this.scopeActivityCategoryControl.value && this.scopeActivityCategoryControl.value === "OUT_SCOPE")
            dblData = dblData.filter(obj => obj.in_out_scope === 'Out Scope');
        }
        this.activityInfoDataList.data = dblData;
        this.activityInfoDataList.paginator = this.activityInfoMatPaginator;
        this.activityInfoDataList.sort = this.activityInfoMatSort;
        this.isLoadingActivityInfo = false;
      })

  }

  swapPropertyPositions(objects, property1, property2) {

    // Find the index of the properties
    const index1 = objects[0][property1] !== undefined ? Object.keys(objects[0]).indexOf(property1) : -1;
    const index2 = objects[0][property2] !== undefined ? Object.keys(objects[0]).indexOf(property2) : -1;

    // If one or both properties are not found, return the original array
    if (index1 < 0 || index2 < 0) {
      return objects;
    }

    // Swap the positions of the properties in each object
    objects.forEach(obj => {
      const temp = obj[property1];
      obj[property1] = obj[property2];
      obj[property2] = temp;
    });

    // Reorder the properties in each object
    objects.forEach(obj => {
      const reorderedObj = {};
      Object.keys(obj).forEach((key, index) => {
        if (index === index1) {
          reorderedObj[property2] = obj[property2];
        } else if (index === index2) {
          reorderedObj[property1] = obj[property1];
        } else {
          reorderedObj[key] = obj[key];
        }
      });
      Object.assign(obj, reorderedObj);
    });

    return objects;
  }


  rowData = this.isBrandZdhc || this.isTCP ? [
    "zdhc_aid",
    "higg_id",
    "organization_name",
    "scan_date",
    "_type",
    "is_compliant",
    "chemical_name",
    "chemical_manufacturer",
    "gateway_name",
    "gateway_manufacturer",
    "gateway_level",
    "application",
    "user_application",
    "category",
    "consumption",
    "stock_volume",
    "delivered_quantity",
    "basic_chemical",
    "productType",
    "lot_number",
    "cas_nr",
    "notes",
    "use_of_ppe",
    "ghs_msds_available",
    "precautionary_statement",
    "hazard_statement",
    "ghs_classification",
    "stock_location",
    "storage_condition",
    "minimum_stock",
    "maximum_stock",
    "manufacture_date",
    "purchase_date",
    "expiry_date",
    "count_used_for_brand",
    "zdhc_mrsl",
    // "zdhc_grading",
    "zdhc_mrsl_version",
    "zdhc_mrsl_self_declaration",
    "gots",
    "gots_version",
    "eco_passport",
    "bluesign",
    "bluesign_system_partner",
    "green_screen",
    "green_screen_level",
    "c2c",
    "inditex_AboveThreeYears",
    "inditex_BelowThreeYears",
    "toxfmd",
    "scivera",
    "test_report",
    "testing_facility",
    "svhc",
  ]:
    [
      "zdhc_aid",
      "higg_id",
      "organization_name",
      "scan_date",
      "_type",
      "is_compliant",
      "chemical_name",
      "chemical_manufacturer",
      "application",
      "user_application",
      "category",
      "consumption",
      "stock_volume",
      "delivered_quantity",
      "basic_chemical",
    "productType",
      "lot_number",
      "cas_nr",
      "notes",
      "use_of_ppe",
      "ghs_msds_available",
      "precautionary_statement",
      "hazard_statement",
      "ghs_classification",
      "stock_location",
      "storage_condition",
      "minimum_stock",
      "maximum_stock",
      "manufacture_date",
      "purchase_date",
      "expiry_date",
      "count_used_for_brand",
      "zdhc_mrsl",
    // "zdhc_grading",
    "zdhc_mrsl_version",
    "zdhc_mrsl_self_declaration",
      "gots",
      "gots_version",
      "eco_passport",
      "bluesign",
      "bluesign_system_partner",
      "green_screen",
      "green_screen_level",
      "c2c",
      "inditex_AboveThreeYears",
      "inditex_BelowThreeYears",
      "toxfmd",
      "scivera",
      "test_report",
      "testing_facility",
      "svhc",
    ]
  rowDataInfoDataList = new MatTableDataSource<any>()
  isLoadingrowDataInfo = false;

  getRowData() {
    this.rowDataFilters = [];
    this.isLoadingrowDataInfo = true;
    let std = new Date(this.startDateActivity.value);
    let edd = new Date(this.endDateActivity.value);
    this.startDateStringActivity = new Date(std.getFullYear(), std.getMonth(), std.getDate() + 1).toISOString().slice(0, 10);
    this.endDateStringActivity = new Date(edd.getFullYear(), edd.getMonth(), edd.getDate() + 1).toISOString().slice(0, 10);
    if (this.gatewayLevelControl.value && this.gatewayLevelControl.value === "registered") {
      this.rowDataFilters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": ['Registered']
        }
      )
    }
    if (this.gatewayLevelControl.value && this.gatewayLevelControl.value === "expired") {
      this.rowDataFilters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": ['expired']
        }
      )
    }
    if (this.gatewayLevelControl.value && this.gatewayLevelControl.value === "1") {
      this.rowDataFilters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": ['Level 1']
        }
      )
    }
    if (this.gatewayLevelControl.value && this.gatewayLevelControl.value === "2") {
      this.rowDataFilters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": ['Level 2']
        }
      )
    }
    if (this.gatewayLevelControl.value && this.gatewayLevelControl.value === "3") {
      this.rowDataFilters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": ['Level 3']
        }
      )
    }
    if (this.gatewayLevelControl.value && this.gatewayLevelControl.value === "conformant") {
      this.rowDataFilters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": ['expired', 'Level 1', 'Level 2', 'Level 3', 'Registered']
        }
      )
    }
    if (this.gatewayLevelControl.value && this.gatewayLevelControl.value === "notEvaluated") {
      this.rowDataFilters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "notContains",
          "values": ['expired', 'Level 1', 'Level 2', 'Level 3', 'Registered']

        }
      )
    }
    if (this.gatewayVersionsControl.value && this.gatewayVersionsControl.value === "oldZDHC") {
      this.rowDataFilters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": [this.gatewayVersionsControl.value]
        }
      )
    }
    if (this.gatewayVersionsControl.value && this.gatewayVersionsControl.value === "v2.0") {
      this.rowDataFilters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": [this.gatewayVersionsControl.value]
        }
      )
    }
    if (this.gatewayVersionsControl.value && this.gatewayVersionsControl.value === "v3.1") {
      this.rowDataFilters.push(
        {
          "member": "Formulas.gateway_level",
          "operator": "contains",
          "values": [this.gatewayVersionsControl.value]
        }
      )
    }
    const query = {
      "dimensions": (this.isBrandZdhc || this.isTCP) ? [
        "Formulas.organization_name",
        "Formulas.scan_date",
        "Formulas._type",
        "Formulas.is_compliant",
        "Formulas.chemical_name",
        "Formulas.chemical_manufacturer",
        "Formulas.gateway_name",
        "Formulas.gateway_manufacturer",
        "Formulas.gateway_level",
        "Formulas.application",
        "Formulas.user_application",
        "Formulas.category",
        "Formulas.delivered_quantity",
        "Formulas.consumption",
        "Formulas.stock_volume",
        "Formulas.basic_chemical",
        "OrganizationProductType.productType",
        "Formulas.lot_number",
        "Formulas.cas_nr",
        "Formulas.notes",
        "Formulas.use_of_ppe",
        "Formulas.ghs_classification",
        "Formulas.precautionary_statement",
        "Formulas.ghs_msds_available",
        "Formulas.stock_location",
        "Formulas.storage_condition",
        "Formulas.minimum_stock",
        "Formulas.maximum_stock",
        "Formulas.manufacture_date",
        "Formulas.purchase_date",
        "Formulas.expiry_date",
        "Formulas.zdhc_mrsl",
        // "Formulas.zdhc_grading",
        "Formulas.zdhc_mrsl_version",
        "Formulas.zdhc_mrsl_self_declaration",
        "Formulas.gots",
        "Formulas.gots_version",
        "Formulas.eco_passport",
        "Formulas.bluesign",
        "Formulas.bluesign_system_partner",
        "Formulas.green_screen",
        "Formulas.green_screen_level",
        "Formulas.c2c",
        "Formulas.inditex_AboveThreeYears",
        "Formulas.inditex_BelowThreeYears",
        "Formulas.scivera",
        "Formulas.toxfmd",
        "Formulas.test_report",
        "Formulas.testing_facility",
        "Formulas.svhc",
        "Formulas.hazard_statement",
      ]:
        [
          "Formulas.organization_name",
          "Formulas.scan_date",
          "Formulas._type",
          "Formulas.is_compliant",
          "Formulas.chemical_name",
          "Formulas.chemical_manufacturer",
          "Formulas.application",
          "Formulas.user_application",
          "Formulas.category",
          "Formulas.delivered_quantity",
          "Formulas.consumption",
          "Formulas.stock_volume",
          "Formulas.basic_chemical",
        "OrganizationProductType.productType",
          "Formulas.lot_number",
          "Formulas.cas_nr",
          "Formulas.notes",
          "Formulas.use_of_ppe",
          "Formulas.ghs_classification",
          "Formulas.precautionary_statement",
          "Formulas.ghs_msds_available",
          "Formulas.stock_location",
          "Formulas.storage_condition",
          "Formulas.minimum_stock",
          "Formulas.maximum_stock",
          "Formulas.manufacture_date",
          "Formulas.purchase_date",
          "Formulas.expiry_date",
          "Formulas.zdhc_mrsl",
        // "Formulas.zdhc_grading",
        "Formulas.zdhc_mrsl_version",
        "Formulas.zdhc_mrsl_self_declaration",
          "Formulas.gots",
          "Formulas.gots_version",
          "Formulas.eco_passport",
          "Formulas.bluesign",
          "Formulas.bluesign_system_partner",
          "Formulas.green_screen",
          "Formulas.green_screen_level",
          "Formulas.c2c",
          "Formulas.inditex_AboveThreeYears",
          "Formulas.inditex_BelowThreeYears",
          "Formulas.scivera",
          "Formulas.toxfmd",
          "Formulas.test_report",
          "Formulas.testing_facility",
          "Formulas.svhc",
          "Formulas.hazard_statement",
          //  "Formulas.zdhc_mrsl_version",
        ]
      ,
      "timeDimensions": [],
      "order": {
        "SupplierIdentification.supplier_identification": "desc"
      },
      "filters": [
        {
          "member": "Formulas.scan_date",
          "operator": "inDateRange",
          "values": [
            this.startDateStringActivity,
            this.endDateStringActivity
          ]
        },
        ...this.rowDataFilters
      ],
      "measures": [
        "SupplierIdentification.supplier_identification",
        "SupplierIdentification.higg_id",
        "UsedFor.count_used_for_brand",
        "SupplierIdentification.zdhc_aid",
      ],
      "limit": 30000,
    }
    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0) {
          this.hasNotData = false;
          tempData = this.changeKey("SupplierIdentification.zdhc_aid", "zdhc_aid", tempData)
          tempData = this.changeKey("SupplierIdentification.higg_id", "higg_id", tempData)
          tempData = this.changeKey("SupplierIdentification.supplier_identification", "supplier_identification", tempData)
          tempData = this.changeKey("Formulas.organization_name", "organization_name", tempData)
          tempData = this.changeKey("Formulas.scan_date", "scan_date", tempData)
          tempData = this.changeKey("Formulas.chemical_name", "chemical_name", tempData)
          tempData = this.changeKey("Formulas.chemical_manufacturer", "chemical_manufacturer", tempData)
          tempData = this.changeKey("Formulas.application", "application", tempData)
          tempData = this.changeKey("Formulas.user_application", "user_application", tempData)
          tempData = this.changeKey("Formulas.category", "category", tempData)
          tempData = this.changeKey("Formulas.delivered_quantity", "delivered_quantity", tempData)
          tempData = this.changeKey("Formulas.consumption", "consumption", tempData)
          tempData = this.changeKey("Formulas.stock_volume", "stock_volume", tempData)
          tempData = this.changeKey("Formulas.basic_chemical", "basic_chemical", tempData)
          tempData = this.changeKey("OrganizationProductType.productType", "productType", tempData)
          tempData = this.changeKey("Formulas.lot_number", "lot_number", tempData)
          tempData = this.changeKey("Formulas.cas_nr", "cas_nr", tempData)
          tempData = this.changeKey("Formulas.notes", "notes", tempData)
          tempData = this.changeKey("Formulas.use_of_ppe", "use_of_ppe", tempData)
          tempData = this.changeKey("Formulas.ghs_classification", "ghs_classification", tempData)
          tempData = this.changeKey("Formulas.precautionary_statement", "precautionary_statement", tempData)
          tempData = this.changeKey("Formulas.ghs_msds_available", "ghs_msds_available", tempData)
          tempData = this.changeKey("Formulas.stock_location", "stock_location", tempData)
          tempData = this.changeKey("Formulas.storage_condition", "storage_condition", tempData)
          tempData = this.changeKey("Formulas.minimum_stock", "minimum_stock", tempData)
          tempData = this.changeKey("Formulas.maximum_stock", "maximum_stock", tempData)
          tempData = this.changeKey("Formulas.manufacture_date", "manufacture_date", tempData)
          tempData = this.changeKey("Formulas.purchase_date", "purchase_date", tempData)
          tempData = this.changeKey("Formulas.expiry_date", "expiry_date", tempData)
          tempData = this.changeKey("Formulas.zdhc_mrsl", "zdhc_mrsl", tempData)
          // tempData = this.changeKey("Formulas.zdhc_grading", "zdhc_grading", tempData)
          tempData = this.changeKey("Formulas.zdhc_mrsl_version", "zdhc_mrsl_version", tempData)
          tempData = this.changeKey("Formulas.zdhc_mrsl_self_declaration", "zdhc_mrsl_self_declaration", tempData)
          tempData = this.changeKey("Formulas.gots", "gots", tempData)
          tempData = this.changeKey("Formulas.gots_version", "gots_version", tempData)
          tempData = this.changeKey("Formulas.eco_passport", "eco_passport", tempData)
          tempData = this.changeKey("Formulas.bluesign", "bluesign", tempData)
          tempData = this.changeKey("Formulas.bluesign_system_partner", "bluesign_system_partner", tempData)
          tempData = this.changeKey("Formulas.green_screen", "green_screen", tempData)
          tempData = this.changeKey("Formulas.green_screen_level", "green_screen_level", tempData)
          tempData = this.changeKey("Formulas.c2c", "c2c", tempData)
          tempData = this.changeKey("Formulas.inditex_AboveThreeYears", "inditex_AboveThreeYears", tempData)
          tempData = this.changeKey("Formulas.inditex_BelowThreeYears", "inditex_BelowThreeYears", tempData)
          tempData = this.changeKey("Formulas.scivera", "scivera", tempData)
          tempData = this.changeKey("Formulas.toxfmd", "toxfmd", tempData)
          tempData = this.changeKey("Formulas.test_report", "test_report", tempData)
          tempData = this.changeKey("Formulas.testing_facility", "testing_facility", tempData)
          tempData = this.changeKey("Formulas.svhc", "svhc", tempData)
          tempData = this.changeKey("Formulas.hazard_statement", "hazard_statement", tempData)
          tempData = this.changeKey("Formulas._type", "_type", tempData)
          tempData = this.changeKey("Formulas.is_compliant", "is_compliant", tempData)
          tempData = (this.isBrandZdhc || this.isTCP) ? this.changeKey("Formulas.gateway_name", "gateway_name", tempData) : tempData,
            tempData = (this.isBrandZdhc || this.isTCP) ? this.changeKey("Formulas.gateway_manufacturer", "gateway_manufacturer", tempData) : tempData,
            tempData = (this.isBrandZdhc || this.isTCP) ? this.changeKey("Formulas.gateway_level", "gateway_level", tempData) : tempData,
            tempData = this.changeKey("UsedFor.count_used_for_brand", "count_used_for_brand", tempData)
          tempData = tempData.map(obj => {
            if (obj.scan_date)
              obj.scan_date = obj.scan_date.split('T')[0];
            if (obj.count_used_for_brand > 0)
              obj.count_used_for_brand = 'Yes';
            else obj.count_used_for_brand = 'No';
            if (obj.basic_chemical == 1) {
              obj.basic_chemical = 'Yes';
            }
            else {
              obj.basic_chemical = 'No';
            }
            if (obj.zdhc_mrsl == 1) {
              obj.zdhc_mrsl = 'Yes';
            }
            else {
              obj.zdhc_mrsl = 'No';
            }
            if (obj.gots == 1) {
              obj.gots = 'Yes';
            }
            else {
              obj.gots = 'No';
            }
            if (obj.eco_passport == 1) {
              obj.eco_passport = 'Yes';
            }
            else {
              obj.eco_passport = 'No';
            }
            if (obj.bluesign == 1) {
              obj.bluesign = 'Yes';
            }
            else {
              obj.bluesign = 'No';
            }
            if (obj.green_screen == 1) {
              obj.green_screen = 'Yes';
            }
            else {
              obj.green_screen = 'No';
            }
            if (obj.scivera == 1) {
              obj.scivera = 'Yes';
            }
            else {
              obj.scivera = 'No';
            }
            if (obj.toxfmd == 1) {
              obj.toxfmd = 'Yes';
            }
            else {
              obj.toxfmd = 'No';
            }
            if (obj.test_report == 1) {
              obj.test_report = 'Yes';
            }
            else {
              obj.test_report = 'No';
            }
            if (obj.bluesign_system_partner == 1) {
              obj.bluesign_system_partner = 'Yes';
            }
            else {
              obj.bluesign_system_partner = 'No';
            }
            if (obj.c2c == 1) {
              obj.c2c = 'Yes';
            }
            else {
              obj.c2c = 'No';
            }
            if (obj.zdhc_mrsl_self_declaration == 1) {
              obj.zdhc_mrsl_self_declaration = 'Yes';
            }
            else {
              obj.zdhc_mrsl_self_declaration = 'No';
            }
            if (this.isBrandZdhc || this.isTCP) {
              if (obj.gateway_level != '' && obj.gateway_level != null) {
                if (obj.stock_volume > 0 && obj.consumption > 0) {
                  obj.usage_delivery = 'usage, delivery';
                }
                else {
                  if (obj.stock_volume > 0 && obj.consumption <= 0) {
                    obj.usage_delivery = 'delivery';
                  }
                  else {
                    if (obj.consumption > 0 && obj.stock_volume <= 0) {
                      obj.usage_delivery = 'usage';
                    }
                    else {
                      obj.usage_delivery = '';
                    }
                  }
                }
              }
              else {
                obj.usage_delivery = '';
              }

              if (obj.gateway_name == '' && obj.gateway_manufacturer == '' && obj.gateway_level == null)
                obj.gateway_level = 'not included in InCheck';

            }
            if (this.isBrandZdhc || this.isTCP)
              return {
                'zdhc_aid': obj.zdhc_aid,
                'higg_id': obj.higg_id,
                'supplier_identification': obj.supplier_identification,
                'organization_name': obj.organization_name,
                'scan_date': obj.scan_date,
                '_type': obj._type,
                'is_compliant': obj.is_compliant,
                'chemical_name': obj.chemical_name,
              'chemical_manufacturer': obj.chemical_manufacturer,
                'gateway_name': obj.gateway_name,
                'gateway_manufacturer': obj.gateway_manufacturer,
                'gateway_level': obj.gateway_level,
                'application': obj.application,
                'user_application': obj.user_application,
                'category': obj.category,
                'consumption': obj.consumption,
                'stock_volume': obj.stock_volume,
                'delivered_quantity': obj.delivered_quantity,
                'basic_chemical': obj.basic_chemical,
                'productType': obj.productType,
                'lot_number': obj.lot_number,
                'cas_nr': obj.cas_nr,
                'notes': obj.notes,
                'use_of_ppe': obj.use_of_ppe,
                'ghs_msds_available': obj.ghs_msds_available,
                'precautionary_statement': obj.precautionary_statement,
              'hazard_statement': obj.hazard_statement,
                'ghs_classification': obj.ghs_classification,
                'stock_location': obj.stock_location,
                'storage_condition': obj.storage_condition,
                'minimum_stock': obj.minimum_stock,
                'maximum_stock': obj.maximum_stock,
                'manufacture_date': obj.manufacture_date,
                'purchase_date': obj.purchase_date,
                'expiry_date': obj.expiry_date,
                'count_used_for_brand': obj.count_used_for_brand,
                'zdhc_mrsl': obj.zdhc_mrsl,
                // 'zdhc_grading': obj.zdhc_grading,
                'zdhc_mrsl_version': obj.zdhc_mrsl_version,
                'zdhc_mrsl_self_declaration': obj.zdhc_mrsl_self_declaration,
                'gots': obj.gots,
                'gots_version': obj.gots_version,
                'eco_passport': obj.eco_passport,
                'bluesign': obj.bluesign,
                'bluesign_system_partner': obj.bluesign_system_partner,
                'green_screen': obj.green_screen,
                'green_screen_level': obj.green_screen_level,
                'c2c': obj.c2c,
                'inditex_AboveThreeYears': obj.inditex_AboveThreeYears,
                'inditex_BelowThreeYears': obj.inditex_BelowThreeYears,
                'toxfmd': obj.toxfmd,
                'scivera': obj.scivera,
                'test_report': obj.test_report,
                'testing_facility': obj.testing_facility,
                'svhc': obj.svhc,
                'usage_delivery': obj.usage_delivery,
              };
            else
              return {
                'zdhc_aid': obj.zdhc_aid,
                'higg_id': obj.higg_id,
                'supplier_identification': obj.supplier_identification,
                'organization_name': obj.organization_name,
                'scan_date': obj.scan_date,
                '_type': obj._type,
                'is_compliant': obj.is_compliant,
                'chemical_name': obj.chemical_name,
                'chemical_manufacturer': obj.chemical_manufacturer,
                'application': obj.application,
                'user_application': obj.user_application,
                'category': obj.category,
                'consumption': obj.consumption,
                'stock_volume': obj.stock_volume,
                'delivered_quantity': obj.delivered_quantity,
                'basic_chemical': obj.basic_chemical,
                'productType': obj.productType,
                'lot_number': obj.lot_number,
                'cas_nr': obj.cas_nr,
                'notes': obj.notes,
                'use_of_ppe': obj.use_of_ppe,
                'ghs_msds_available': obj.ghs_msds_available,
                'precautionary_statement': obj.precautionary_statement,
                'hazard_statement': obj.hazard_statement,
                'ghs_classification': obj.ghs_classification,
                'stock_location': obj.stock_location,
                'storage_condition': obj.storage_condition,
                'minimum_stock': obj.minimum_stock,
                'maximum_stock': obj.maximum_stock,
                'manufacture_date': obj.manufacture_date,
                'purchase_date': obj.purchase_date,
                'expiry_date': obj.expiry_date,
                'count_used_for_brand': obj.count_used_for_brand,
                'zdhc_mrsl': obj.zdhc_mrsl,
                // 'zdhc_grading': obj.zdhc_grading,
                'zdhc_mrsl_version': obj.zdhc_mrsl_version,
                'zdhc_mrsl_self_declaration': obj.zdhc_mrsl_self_declaration,
                'gots': obj.gots,
                'gots_version': obj.gots_version,
                'eco_passport': obj.eco_passport,
                'bluesign': obj.bluesign,
                'bluesign_system_partner': obj.bluesign_system_partner,
                'green_screen': obj.green_screen,
                'green_screen_level': obj.green_screen_level,
                'c2c': obj.c2c,
                'inditex_AboveThreeYears': obj.inditex_AboveThreeYears,
                'inditex_BelowThreeYears': obj.inditex_BelowThreeYears,
                'toxfmd': obj.toxfmd,
                'scivera': obj.scivera,
                'test_report': obj.test_report,
                'testing_facility': obj.testing_facility,
                'svhc': obj.svhc,
              };
          })
          // var newObjects = this.swapPropertyPositions(tempData, 'count_used_for_brand', 'usage_delivery');
          var newObjects = tempData

        }
        else {
          this.hasNotData = true;
          var newObjects = tempData
        }
        this.isLoadingrowDataInfo = false;
        this.rowDataInfoDataList.data = newObjects;
        this.rowDataInfoDataList.paginator = this.rawMatPaginator;
        this.rowDataInfoDataList.sort = this.rawInfoMatSort;

      })
  }

  // #endregion

  // #region OttoActivity
  ottoCreatedAccountsCount: Number;
  isLoadingOttoCreatedAccountsCount = false;
  getOttoCreatedAccountsCount() {
    this.isLoadingOttoCreatedAccountsCount = true;
    const query = {
      "measures": [
        "BrandSuppliers.total_count"
      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [],
      "filters": [
        // {
        //   "member": "BrandSuppliers.created_date",
        //   "operator": "inDateRange",
        //   "values": [
        //     this.startDateString,
        //     this.endDateString
        //   ]
        // }
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.ottoCreatedAccountsCount = data[0]['BrandSuppliers.total_count']
      this.isLoadingOttoCreatedAccountsCount = false;
    })
      .catch(error => {
        this.isLoadingOttoCreatedAccountsCount = false;
        this.ShowErrorMessage(error.message);
      })
  }
  ottoConnectedFactories: Number = 0;
  isLoadingOttoConnectedFactories = false;
  getOttoConnectedFactoriesCount() {
    this.isLoadingOttoConnectedFactories = true;
    const query = {
      "measures": [
        "BrandSuppliers.connected_count"
      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [],
      "filters": [
        // {
        //   "member": "BrandSuppliers.connected_date",
        //   "operator": "inDateRange",
        //   "values": [
        //     this.startDateString,
        //     this.endDateString
        //   ]
        // }
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.ottoConnectedFactories = data[0]['BrandSuppliers.connected_count']
      this.isLoadingOttoConnectedFactories = false;
    })
      .catch(error => {
        this.isLoadingOttoConnectedFactories = false;
        this.ShowErrorMessage(error.message);
      })
  }
  ottoNominatedFactories: Number = 0;
  isLoadingOttoNominatedFactories = false;
  getOttoNominatedFactoriesCount() {
    this.isLoadingOttoNominatedFactories = true;
    const query = {
      "measures": [
        "BrandSuppliers.nominated_count"
      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [],
      "filters": [
        // {
        //   "member": "BrandSuppliers.nominated_date",
        //   "operator": "inDateRange",
        //   "values": [
        //     this.startDateString,
        //     this.endDateString
        //   ]
        // }
      ]
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.ottoNominatedFactories = data[0]['BrandSuppliers.nominated_count']
      this.isLoadingOttoNominatedFactories = false;
    })
      .catch(error => {
        this.isLoadingOttoNominatedFactories = false;
        this.ShowErrorMessage(error.message);
      })
  }
  ottoScanFactoriesCount: Number;
  isLoadingOttoScanFactoriesCount = false;
  getOttoScanFactoriesCount() {
    this.isLoadingOttoScanFactoriesCount = true;
    const query = {
      "measures": [
        "BrandActivityWithCompliance.scanned_factories_count"
      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [
      ],
      "filters": [
        // {
        //   "member": "Activity.scan_date",
        //   "operator": "inDateRange",
        //   "values": [
        //     this.startDateString,
        //     this.endDateString
        //   ]
        // }
      ],
    }

    this.cubejsService.getCubeJSData(query, null, null, this.brandScopeControl.value, false).then(data => {
      let result = data['loadResponse']['results'][0]['data'];
      this.ottoScanFactoriesCount = result.length !== 0 ? result[0]['BrandActivityWithCompliance.scanned_factories_count'] : 0;
      this.isLoadingOttoScanFactoriesCount = false;
    })
      .catch(error => {
        this.isLoadingOttoScanFactoriesCount = false;
        this.ShowErrorMessage(error.message);
      })
  }

  ottoActivityInfo = [
    "facilityID", "factoryName", "comment", "country", "ottoScope", "lastScanDate", "isActive",
    "connected", "year", "q1", "q2", "q3", "q4"
  ]
  ottoActivityInfoDataList = new MatTableDataSource<any>()
  isLoadingOttoActivityInfo = false;

  getOttoActivityMonitoring() {
    this.isLoadingOttoActivityInfo = true;
    // let years;
    let ottofilters: Array<Object> = [];
    let std = new Date(this.startDate.value);
    let edd = new Date(this.endDate.value);
    let years = [];
    for (let year = std.getFullYear(); year <= edd.getFullYear(); year++) {
      years.push(year.toString());
    }
    // if (this.productActivityTypeControl.value && this.productActivityTypeControl.value.length !== 0) {
    //   ottofilters.push(
    //     {
    //       "member": "OrganizationProductType.productType",
    //       "operator": "equals",
    //       "values": this.productActivityTypeControl.value.map(obj => obj.name)
    //     }
    //   )
    // }
    if (this.countryActivityControl.value && this.countryActivityControl.value.length !== 0) {
      ottofilters.push(
        {
          "member": "BrandActivityWithCompliance.country",
          "operator": "equals",
          "values": this.countryActivityControl.value
        }
      )
    }
    if (this.factoryActivityControl.value && this.factoryActivityControl.value.length !== 0) {
      ottofilters.push(
        {
          "member": "BrandActivityWithCompliance.name",
          "operator": "equals",
          "values": this.factoryActivityControl.value
        }
      )
    }
    // if (this.scopeActivityCategoryControl.value && this.scopeActivityCategoryControl.value === "IN_SCOPE") {
    //   ottofilters.push(
    //     {
    //         "member": "BrandActivityWithCompliance.brand_scope",
    //         "operator": "equals",
    //         "values": ['1']
    //       }
    //   )
    // }
    // if (this.scopeActivityCategoryControl.value && this.scopeActivityCategoryControl.value === "OUT_SCOPE") {
    //   ottofilters.push(
    //     {
    //         "member": "BrandActivityWithCompliance.brand_scope",
    //         "operator": "equals",
    //         "values": ['0']
    //       }
    //   )
    // }
    ottofilters.push(
      {
        "member": "BrandActivityWithCompliance.year",
        "operator": "equals",
        "values": years
      })
    const query = {
      "dimensions": [
        "BrandActivityWithCompliance.facility_id",
        "BrandActivityWithCompliance.supplier_name",
        "BrandActivityWithCompliance.supplier_id",
        "BrandActivityWithCompliance.last_scan_date",
        "BrandActivityWithCompliance.brand_scope",
        "BrandActivityWithCompliance.country",
        "BrandActivityWithCompliance.year",
        "BrandActivityWithCompliance.active",
        "BrandActivityWithCompliance.connected",
        "BrandActivityWithCompliance.q1",
        "BrandActivityWithCompliance.q2",
        "BrandActivityWithCompliance.q3",
        "BrandActivityWithCompliance.q4"

      ],
      "timeDimensions": [],
      "order": {
      },
      "measures": [
      ],
      "filters": ottofilters
    }
    const allObservables = forkJoin([
      this.cubejsService.getCubeJSData(query, null),
      this.http.get<any>(this.globalsService.baseHref + '/getAnalyticsInternalComments')
    ]);

    allObservables.subscribe(responses => {
      let data = responses[0]
      let tempData = data['loadResponse']['results'][0]['data'];
      tempData = this.changeKey("BrandActivityWithCompliance.facility_id", "facilityID", tempData)
      tempData = this.changeKey("BrandActivityWithCompliance.supplier_name", "factoryName", tempData)
      tempData = this.changeKey("BrandActivityWithCompliance.supplier_id", "factoryID", tempData)
      tempData = this.changeKey("BrandActivityWithCompliance.country", "country", tempData)
      tempData = this.changeKey("BrandActivityWithCompliance.brand_scope", "ottoScope", tempData)
      tempData = this.changeKey("BrandActivityWithCompliance.last_scan_date", "lastScanDate", tempData)
      tempData = this.changeKey("BrandActivityWithCompliance.active", "isActive", tempData)
      tempData = this.changeKey("BrandActivityWithCompliance.connected", "connected", tempData)
      tempData = this.changeKey("BrandActivityWithCompliance.year", "year", tempData)
      tempData = this.changeKey("BrandActivityWithCompliance.q1", "q1", tempData)
      tempData = this.changeKey("BrandActivityWithCompliance.q2", "q2", tempData)
      tempData = this.changeKey("BrandActivityWithCompliance.q3", "q3", tempData)
      tempData = this.changeKey("BrandActivityWithCompliance.q4", "q4", tempData)
      let comments = responses[1];//get comments for factories
      tempData = tempData.map(obj => {//adjust some of the fields
        obj.hasComment = false//by default there is no comment for factories, later will be updated
        obj.comment = {
          supplierId: obj.factoryID,
          supplierName: obj.factoryName,
          commentDetails: []
        }
        return obj;
      })

      if (comments.length !== 0) {//if there are comments
        comments.forEach(comm => {
          //find index of the factory that has the comment
          let a = tempData.filter(el => el.factoryID == comm.supplierId)
          a.forEach(el => {
            el.hasComment = true;//set flag hasComment to true, used to color the icon

            el.comment['supplierName'] = comm['supplierName'];
            el.comment['supplierId'] = comm['supplierId'];
            el.comment['commentDetails'].push({//set comment history in an array
              createdOn: comm['createdOn'],
              userName: comm['userName'],
              comment: comm['comment']
            });
          })
          //  let i = tempData.findIndex(r => r.factoryID == comm.supplierId);
          //  if ((i !== null || i !== undefined) && i !==-1) {
          //    tempData[i].hasComment = true;//set flag hasComment to true, used to color the icon

          //    tempData[i].comment['supplierName'] = comm['supplierName'];
          //    tempData[i].comment['supplierId'] = comm['supplierId'];
          //    tempData[i].comment['commentDetails'].push({//set comment history in an array
          //      createdOn: comm['createdOn'],
          //      userName: comm['userName'],
          //      comment: comm['comment']
          //    });
          //  }
        })
      }

      if (this.scopeActivityCategoryControl.value && this.scopeActivityCategoryControl.value === "IN_SCOPE")
        tempData = tempData.filter(obj => obj.ottoScope === 'Yes');
      else {
        if (this.scopeActivityCategoryControl.value && this.scopeActivityCategoryControl.value === "OUT_SCOPE")
          tempData = tempData.filter(obj => obj.ottoScope === 'No');
      }

      this.ottoActivityInfoDataList.data = tempData;
      this.ottoActivityInfoDataList.paginator = this.ottoActivityInfoMatPaginator;
      this.ottoActivityInfoDataList.sort = this.ottoActivityInfoMatSort;
      this.isLoadingOttoActivityInfo = false;


    })

  }

  openDialog(comment): void {
    const dialogRef = this.dialog.open(CommentDialog, {
      width: '70%',
      data: comment,
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        let factoryList = this.ottoActivityInfoDataList.data.filter(r => r.factoryID == result.supplierId);
        if (factoryList) {
          factoryList.forEach(el => {
            el.hasComment = true;//set flag hasComment to true, used to color the icon
            el.comment['supplierName'] = result['supplierName'];
            el.comment['supplierId'] = result['supplierId'];
            el.comment['commentDetails'].push({//set comment history in an array
              createdOn: result['createdOn'],
              userName: result['userName'],
              comment: result['comment']
            });
          })
        }
      }
    });
  }
  // #endregion

  exportData(data: any, fileName: string): void {
    this.exportService.exportAsExcelFile(data, fileName);
  }

  exportOttoActivity(data: any, fileName: string): void {
    let excelData = [];
    excelData = data.map(obj => {
      let commentDetails = obj.comment.commentDetails.map(comment => {
        const formattedDate = moment(comment.createdOn).format('YYYY-MM-DD');
        return `${formattedDate} - ${comment.userName}: ${comment.comment}`;
      });
      return {
        facilityID: obj.facilityID,
        factoryName: obj.factoryName,
        factoryID: obj.factoryID,
        country: obj.country,
        ottoScope: obj.ottoScope,
        lastScanDate: obj.lastScanDate,
        isActive: obj.isActive,
        connected: obj.connected,
        year: obj.year,
        q1: obj.q1,
        q2: obj.q2,
        q3: obj.q3,
        q4: obj.q4,
        hasComment: obj.hasComment,
        comment: commentDetails.join(', ') // Combine comment details into a string
      };
    });
    this.exportService.exportAsExcelFile(excelData, fileName);
  }




  exportRawData(data: any, isBrandZdhc: boolean): void {
    this.exportService.exportRawDataAsExcelFile(data, this.globalsService.profile["name"].toString(), isBrandZdhc);
  }
}