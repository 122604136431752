<div *ngIf="!successPage"
	class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0">
	<div class=" w-1/2 ">

		<div class="max-h-screen overflow-auto content w-full">
			<div style="padding-right: 10px; float:right;">
				<mat-form-field appearance="standard"
					style="width: 75px; margin-top: -10px; height: 50px; text-align: center;" class="">
					<mat-select matNativeControl [(ngModel)]="selectedLanguageCode"
						(selectionChange)="changeLanguage()">
						<mat-option *ngFor="let language of languageService['availableLanguages']"
							[value]="language['languageCode']">
							{{language["name"]}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<br>

			<mat-vertical-stepper [linear]="isLinear" #stepper>
				<mat-step [stepControl]="personalInfoGroup">
					<form [formGroup]="personalInfoGroup">
						<ng-template matStepLabel>{{ "USER_SIGNUP_COMPONENT.PERSONAL_INFO.TITLE" | translate
							}}</ng-template>
						<mat-form-field class="question">
							<mat-label>{{ "USER_SIGNUP_COMPONENT.PERSONAL_INFO.NAME" | translate }}</mat-label>
							<input matInput placeholder="{{ 'USER_SIGNUP_COMPONENT.PERSONAL_INFO.NAME' | translate }}"
								formControlName="nameControl" required>
						</mat-form-field>
						<br> <br>
						<mat-form-field class="question">
							<mat-label>{{ "USER_SIGNUP_COMPONENT.PERSONAL_INFO.EMAIL.LABEL" | translate }}</mat-label>
							<input matInput
								placeholder="{{ 'USER_SIGNUP_COMPONENT.PERSONAL_INFO.EMAIL.LABEL' | translate }}"
								formControlName="emailControl" (blur)="validateEmail()" required>
							<mat-error
								*ngIf="this.personalInfoGroup.controls['emailControl'].invalid">{{getErrorMessage()}}
							</mat-error>
							<mat-error *ngIf="this.personalInfoGroup.controls['emailControl'].errors?.emailTaken">{{
								"USER_SIGNUP_COMPONENT.PERSONAL_INFO.EMAIL.ERROR" | translate }}</mat-error>
						</mat-form-field>
						<br> <br>
						<mat-form-field class="question">
							<mat-label>{{ "USER_SIGNUP_COMPONENT.PERSONAL_INFO.PHONE.LABEL" | translate }}</mat-label>
							<input matInput
								placeholder="{{ 'USER_SIGNUP_COMPONENT.PERSONAL_INFO.PHONE.LABEL' | translate }}"
								formControlName="phoneControl" required (keypress)="numberOnly($event)"
								(paste)="(false)">
							<mat-error
								*ngIf="this.personalInfoGroup.controls['phoneControl'].invalid">{{getErrorMessage()}}
							</mat-error>
							<!-- <mat-error *ngIf="this.personalInfoGroup.controls['phoneControl'].errors?.required">{{ "USER_SIGNUP_COMPONENT.PERSONAL_INFO.PHONE.ERROR" | translate }}</mat-error> -->
						</mat-form-field>
						<br> <br>
						<mat-form-field class="question">
							<mat-label>{{ "USER_SIGNUP_COMPONENT.PERSONAL_INFO.POSITION" | translate }}</mat-label>
							<input matInput
								placeholder="{{ 'USER_SIGNUP_COMPONENT.PERSONAL_INFO.POSITION' | translate }}"
								formControlName="positionControl" required>
						</mat-form-field>
					</form>
				</mat-step>
				<mat-step [stepControl]="companyInfoGroup">
					<form [formGroup]="companyInfoGroup">
						<ng-template matStepLabel>{{ "USER_SIGNUP_COMPONENT.COMPANY_INFO.TITLE" | translate
							}}</ng-template>
						<mat-form-field class="question">
							<mat-label>{{ "USER_SIGNUP_COMPONENT.COMPANY_INFO.COUNTRY" | translate }}</mat-label>
							<mat-select formControlName="countryControl" required>
								<mat-option>--</mat-option>
								<mat-option *ngFor="let country of countries" [value]="country.name">{{country.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<br><br>
						<mat-form-field class="question">
							<mat-label>{{ "USER_SIGNUP_COMPONENT.COMPANY_INFO.COMPANY_NAME" | translate }}</mat-label>
							<input matInput
								placeholder="{{ 'USER_SIGNUP_COMPONENT.COMPANY_INFO.COMPANY_NAME' | translate }}"
								formControlName="companyNameControl" required>
						</mat-form-field>
						<br><br>
						<mat-form-field class="question">
							<mat-label>{{ "USER_SIGNUP_COMPONENT.COMPANY_INFO.COMPANY_ADDRESS" | translate
								}}</mat-label>
							<textarea formControlName="companyAddressControl" matInput style="border: none !important;"
								placeholder="{{ 'USER_SIGNUP_COMPONENT.COMPANY_INFO.COMPANY_ADDRESS' | translate }}"
								required></textarea>
						</mat-form-field>
						<br><br>
						<mat-form-field class="question">
							<mat-label>{{ "USER_SIGNUP_COMPONENT.COMPANY_INFO.HIGG_ID" | translate }}</mat-label>
							<input matInput formControlName="higgIDControl"
								placeholder="{{ 'USER_SIGNUP_COMPONENT.COMPANY_INFO.HIGG_ID' | translate }}">
						</mat-form-field>
						<br><br>
						<mat-form-field class="question">
							<mat-label>{{ "USER_SIGNUP_COMPONENT.COMPANY_INFO.OAR_ID" | translate }}</mat-label>
							<input matInput formControlName="oarIDControl"
								placeholder="{{ 'USER_SIGNUP_COMPONENT.COMPANY_INFO.OAR_ID' | translate }}">
						</mat-form-field>
						<br><br>
						<mat-form-field class="question">
							<mat-label>{{ "USER_SIGNUP_COMPONENT.COMPANY_INFO.FCC_ID" | translate }}</mat-label>
							<input matInput formControlName="fccIDControl"
								placeholder="{{ 'USER_SIGNUP_COMPONENT.COMPANY_INFO.FCC_ID' | translate }}">
						</mat-form-field>
						<br><br>
						<mat-form-field class="question">
							<mat-label>{{ "USER_SIGNUP_COMPONENT.COMPANY_INFO.WALMART_ID" | translate }}</mat-label>
							<input matInput formControlName="walmartIDControl"
								placeholder="{{'USER_SIGNUP_COMPONENT.COMPANY_INFO.WALMART_ID' | translate }}">
						</mat-form-field>
						<br><br>


						<mat-form-field class="question">

							<mat-label>{{ "USER_SIGNUP_COMPONENT.COMPANY_INFO.COMPANY_TYPE" | translate }}</mat-label>
							<mat-select formControlName="companyTypeControl" (selectionChange)="isFactoryType()"
								required>
								<mat-option *ngFor="let type of companyTypes"
									[value]="type">{{type.translation}}</mat-option>
							</mat-select>
						</mat-form-field>


						<div *ngIf='isFactory'>
							<br><br>
							<mat-form-field class="question" *ngIf='isFactory'>
								<mat-label>{{ "USER_SIGNUP_COMPONENT.COMPANY_INFO.FACTORY_ADDRESS" | translate
									}}</mat-label>
								<textarea rows="8" formControlName="factoryAddressControl" matInput
									style="border: none !important;"
									placeholder="{{ 'USER_SIGNUP_COMPONENT.COMPANY_INFO.FACTORY_ADDRESS' | translate }}"
									required></textarea>
								<mat-error
									*ngIf="this.companyInfoGroup.controls['factoryAddressControl'].errors?.incomplete">{{
									"USER_SIGNUP_COMPONENT.COMPANY_INFO.ERRORS.ENTER_ERROR" | translate }}</mat-error>
							</mat-form-field>
							<br><br>
							<mat-form-field class="question">
								<mat-label>{{ "USER_SIGNUP_COMPONENT.COMPANY_INFO.FACTORY_TYPE" | translate
									}}</mat-label>
								<mat-select formControlName="factoryTypeControl" multiple required>
									<mat-option *ngFor="let type of factoryTypes" [value]="type['english']">
										{{type["translation"]}}</mat-option>
								</mat-select>
								<mat-error
									*ngIf="this.companyInfoGroup.controls['factoryTypeControl'].errors?.incomplete">{{
									"USER_SIGNUP_COMPONENT.COMPANY_INFO.ERRORS.ENTER_ERROR" | translate }}</mat-error>
							</mat-form-field>
							<br><br>
							<mat-form-field class="question">
								<mat-label>{{ "USER_SIGNUP_COMPONENT.COMPANY_INFO.SUBSCRIPTION.HEADER" | translate }}
								</mat-label>
								<mat-select formControlName="subscriptionPlanControl" required>
									<!-- <mat-option value="Full Bhive">{{
								"USER_SIGNUP_COMPONENT.COMPANY_INFO.SUBSCRIPTION.FULL_BHIVE" | translate }}</mat-option>
							<mat-option value="Data Mode">{{ "USER_SIGNUP_COMPONENT.COMPANY_INFO.SUBSCRIPTION.DATA_MODE"
								| translate }}</mat-option> -->

									<mat-option value="Full Bhive">{{
										"SIGN_UP_PAGE_NEW_LABEL.FULL_BHIVE" | translate }}</mat-option>
									<mat-option value="Full Bhive InCheck">{{
										"SIGN_UP_PAGE_NEW_LABEL.FULL_BHIVE_INC_CHECK"
										| translate }}</mat-option>
									<mat-option value="Data Mode">{{
										"SIGN_UP_PAGE_NEW_LABEL.DATA_MODE_LIMITED_ACCESS"
										| translate }}</mat-option>
								</mat-select>
							</mat-form-field>

						</div>
						<br><br>
						<mat-form-field class="question" *ngIf='isFactory'>
							<mat-chip-list formControlName="topCustomer" #chipList>
								<mat-chip [selectable]="false" [removable]="false"
									[ngStyle]="{'background-color': '#EBF1F6'}">
									{{ "USER_SIGNUP_COMPONENT.COMPANY_INFO.TOP_CUSTOMERS" | translate }}:</mat-chip>
								<mat-chip *ngFor="let customer of customers" [selectable]="selectable"
									[removable]="removable" (removed)="removeCustomer(customer)">
									{{customer}}
									<mat-icon matChipRemove>cancel</mat-icon>
								</mat-chip>
								<input
									placeholder="{{'USER_SIGNUP_COMPONENT.COMPANY_INFO.CUSTOMERS_PLACEHOLDER' | translate }}"
									#customerInput formControlName="topCustomer" [matAutocomplete]="auto"
									[matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
									(matChipInputTokenEnd)="addCustomer($event)">
							</mat-chip-list>
						</mat-form-field>

					</form>
				</mat-step>
				<mat-step [stepControl]="hearAboutUsGroup">
					<form [formGroup]="hearAboutUsGroup">
						<ng-template matStepLabel>{{ "USER_SIGNUP_COMPONENT.INVITATION_INFO.TITLE" | translate
							}}</ng-template>
						<mat-form-field class="question">
							<mat-label>{{ "USER_SIGNUP_COMPONENT.INVITATION_INFO.INVITED_BY" | translate }}</mat-label>
							<input formControlName="invitedByControl" matInput
								placeholder="{{ 'USER_SIGNUP_COMPONENT.INVITATION_INFO.INVITED_BY' | translate }}"
								required>
						</mat-form-field>
					</form>
				</mat-step>
			</mat-vertical-stepper>
			<div>

			</div>

			<div style="display: block; padding: 12px  12px;">
				<h5 [innerHTML]=" 'USER_SIGNUP_COMPONENT.SLIDER_MESSAGE' | translate "></h5>
				<mat-slider min="0" max="50" step="0.5" value="0" [color]="sliderColor" (input)="sliderControl($event)"
					#slider>
				</mat-slider>
			</div>
			<div style="display: block; padding: 12px  12px;">
				<button (click)="submitForm()" class="btn btn-lg btn-primary btn-block signup-button"
					[disabled]="formIsInValid()">{{ "USER_SIGNUP_COMPONENT.SIGNUP_BUTTON" | translate }}</button>
				<br>
				<p>
					This site is protected by reCAPTCHA and the Google
					<a target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and
					<a target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.
				</p>
			</div>

			<div class="border-box w-full">
				<h4>Information: ZDHC Performance InCheck</h4>
				<span>
					The BHive is an official ZDHC Approved Solution Provider for the Performance InCheck.
					Please note that if you want to create Performance InCheck reports using The BHive,
					you need to be registered with ZDHC Gateway. If you don't have an account on the Gateway yet,
					you can go
					<a target="_blank" rel="noopener noreferrer"
						href="https://knowledge-base.roadmaptozero.com/hc/en-gb/articles/4404775473553-Getting-a-ZDHC-Gateway-account-Supplier-">here</a>
					to get more information.
				</span>
			</div>

			<ngx-loading [show]="isLoading"></ngx-loading>
		</div>
	</div>



	<!-- <div class="md:flex flex-auto items-center w-1/2 h-full p-16 lg:px-28 overflow-hidden"
		style="background-image: url(assets/images/background.jpeg);  background-size: 100% auto;">
		<div class="z-10 relative w-full max-w-2xl">
			<div class="text-7xl font-bold leading-none text-gray-100 flex justify-center">
				<img src="assets/images/logo/logo.png" alt="The Bhive logo" class="w-1/2 h-auto">
			</div> 
		</div>
	</div> -->
	<div
		class="relative hidden md:flex flex-auto items-center justify-center w-1/2 h-full p-16 lg:px-28 overflow-hidden bg-gray-800 dark:border-l">

		<svg class="absolute inset-0 pointer-events-none" viewBox="0 0 960 540" width="100%" height="100%"
			preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
			<g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
				<circle r="234" cx="196" cy="23"></circle>
				<circle r="234" cx="790" cy="491"></circle>
			</g>
		</svg>

		<svg class="absolute -top-16 -right-16 text-gray-700" viewBox="0 0 220 192" width="220" height="192"
			fill="none">
			<defs>
				<pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
					patternUnits="userSpaceOnUse">
					<rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
				</pattern>
			</defs>
			<rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
		</svg>

		<div class="z-10 relative w-full max-w-2xl">
			<div class="text-7xl font-bold leading-none text-gray-100 flex justify-center">
				<img src="assets/images/logo/logo.png" alt="The Bhive logo" class="w-1/2 h-auto">
			</div>

		</div>
	</div>
</div>




<div *ngIf="successPage" class="content" style="height: 100vh;">
	<img src="assets/icons/success.svg" style="width: 20%;" class="successPageCenter">
	<br><br>
	<h4 class="text-3xl font-semibold successPageCenter"> {{ "USER_SIGNUP_COMPONENT.SUCCESS_MESSAGE" | translate }}
		<i>{{refid}} </i>.
	</h4>
</div>